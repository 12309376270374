// import React from 'react';

// const InputBox = ({ value, onChange, placeholder }) => {
//   return (
//     <input
//       type="text"
//       placeholder={placeholder}
//       value={value}
//       onChange={onChange}
//       className="w-full p-2 mb-5 border rounded-lg bg-[#F2F3F3]"
//     />
//   );
// };

// export default InputBox;
// import React from 'react';

// const InputBox = ({ value, onChange, placeholder, type = "text" }) => {
//   return (
//     <input
//       type={type}
//       placeholder={placeholder}
//       value={value}
//       onChange={onChange}
//       className="w-full p-2 mb-5 border rounded-lg bg-[#F2F3F3]"
//     />
//   );
// };

// export default InputBox;
import React from 'react';

const InputBox = ({
  label,
  value,
  onChange,
  placeholder,
  type = "text",
  borderRadius = "4px",
  backgroundColor = "#FFFFFF",
  padding = "12px",
  marginBottom = "auto",
  fontSize = "14px",
  borderColor = "#DBDBDB",
  name
}) => {
  return (
    <div className="mb-4">
      {label && <label className="block text-gray-700 text-sm font-semibold mb-2">{label}</label>}
      <style>
        {`
          .input-placeholder::placeholder {
            color: #737373;
            font-family: 'Poppins', sans-serif;
        
            font-size: ${fontSize};
          }
          .input-placeholder {
            color: #737373;
            font-family: 'Poppins', sans-serif;
        
            font-size: ${fontSize};
          }
        `}
      </style>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        className={`w-full input-placeholder`}
        onChange={onChange}
        style={{
          borderRadius,
          backgroundColor,
          padding,
          marginBottom,
          outline: 'none',
          width: '100%',
          fontFamily: 'Poppins, sans-serif',
       
          fontSize,
          border: '1px solid',
          borderColor
        }}
      />
    </div>
  );
};

export default InputBox;
