// import axios from "axios";
// import { showToast } from "../View/Components/toast";
// import { BASE_URL } from "./urls";
// import { ACCESS_TOKEN, REFRESH_TOKEN } from "./constants/constants";

// const api = axios.create({
//   baseURL: BASE_URL,
// });

// // Intercept outgoing requests
// api.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem(ACCESS_TOKEN);
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // Intercept incoming responses
// api.interceptors.response.use(
//   (response) => {
//     if (response.status >= 200 && response.status <= 205) {
//       if (['post', 'put', 'delete'].includes(response.config.method)) {
//         // Show success toast for POST, PUT, DELETE requests
//         showToast(response.data.message || "Success", "success");
//       }
//     }
//     return response;
//   },
//   (error) => {
//     if (error.response) {
//       const status = error.response.status;
//       const method = error.response.config.method;
      
//       if (status >= 400 && status <= 405 && ['post', 'put', 'delete'].includes(method)) {
//         // Show error toast for POST, PUT, DELETE requests
//         showToast(error.response.data.message || "An error occurred", "error");
//       }
//     } else {
//       // Network error or no response
//       showToast("Network error or server is unreachable", "error");
//     }
//     return Promise.reject(error);
//   }
// );

// // Refresh token instance
// const refreshapi = axios.create({
//   baseURL: BASE_URL,
// });

// // Intercept outgoing requests
// refreshapi.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem(REFRESH_TOKEN);
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // Intercept incoming responses
// refreshapi.interceptors.response.use(
//   (response) => {
//     if (response.status >= 200 && response.status <= 205) {
//       if (['post', 'put', 'delete'].includes(response.config.method)) {
//         // Show success toast for POST, PUT, DELETE requests
//         showToast(response.data.message || "Success", "success");
//       }
//     }
//     return response;
//   },
//   (error) => {
//     if (error.response) {
//       const status = error.response.status;
//       const method = error.response.config.method;
      
//       if (status >= 400 && status <= 405 && ['post', 'put', 'delete'].includes(method)) {
//         // Show error toast for POST, PUT, DELETE requests
//         showToast(error.response.data.message || "An error occurred", "error");
//       }
//     } else {
//       // Network error or no response
//       showToast("Network error or server is unreachable", "error");
//     }
//     return Promise.reject(error);
//   }
// );

// export { api, refreshapi };

import axios from "axios";
import { showToast } from "../View/Components/toast";
import { BASE_URL } from "./urls";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "./constants/constants";

const api = axios.create({
  baseURL: BASE_URL,
});

// Intercept outgoing requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Intercept incoming responses
api.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status <= 205) {
      if (['post', 'put', 'delete'].includes(response.config.method)) {
        showToast(response.data.message || "Success", "success");
      }
    }
    return response;
  },
  (error) => {
    if (error.response) {
      const status = error.response.status;
      const method = error.response.config.method;

      if (status === 401) {
        // ✅ Redirect to login page if unauthorized (token expired)
        window.location.href = "/";
      }

      if (status >= 400 && status <= 405 && ['post', 'put', 'delete'].includes(method)) {
        showToast(error.response.data.message || "An error occurred", "error");
      }
    } else {
      showToast("Network error or server is unreachable", "error");
    }
    return Promise.reject(error);
  }
);

// Refresh token instance
const refreshapi = axios.create({
  baseURL: BASE_URL,
});

// Intercept outgoing requests
refreshapi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(REFRESH_TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Intercept incoming responses
refreshapi.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status <= 205) {
      if (['post', 'put', 'delete'].includes(response.config.method)) {
        showToast(response.data.message || "Success", "success");
      }
    }
    return response;
  },
  (error) => {
    if (error.response) {
      const status = error.response.status;
      const method = error.response.config.method;

      if (status === 401) {
        // ✅ Redirect to login page if unauthorized (token expired)
        window.location.href = "/";
      }

      if (status >= 400 && status <= 405 && ['post', 'put', 'delete'].includes(method)) {
        showToast(error.response.data.message || "An error occurred", "error");
      }
    } else {
      showToast("Network error or server is unreachable", "error");
    }
    return Promise.reject(error);
  }
);

export { api, refreshapi };
