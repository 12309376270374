import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../Components/sidebar';
import Header from '../Components/header';
import { FaSpinner } from 'react-icons/fa';
const UserProfilePage = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userData = {
      name: 'John Doe',
      position: 'Senior Developer',
      profileImage: 'https://via.placeholder.com/150',
      email: 'john.doe@example.com',
      bio: 'John has over 10 years of experience in web development specializing in React and Node.js.',
      skills: ['React', 'Node.js', 'JavaScript', 'SQL'],
      companyName: 'Tech Solutions Ltd',
      companyLocation: 'San Francisco, CA',
      webLinks: ['https://example.com/profile/johndoe']
    };

    setUser(userData);
  }, [userId]);

  if (!user) {
    <div className="flex items-center justify-center h-screen">
        <FaSpinner className="animate-spin text-4xl text-gray-600" />
      </div>
  }

  return (
    <>
      <Header />
      <div className="flex h-full w-full bg-[#F3F3F3] font-poppins ">
        <Sidebar />
        <div className="flex-1 flex flex-col p-8">
          <h1 className="text-3xl font-bold text-[#545454] mb-6">USER PROFILE</h1>
          <div className="grid grid-cols-2 gap-10">
            <div>
              {/* Left Column: Contact, Bio, Web Links, Skills */}
              <div className="bg-white shadow rounded-lg p-6 mb-6">
                <h2 className="text-2xl font-bold text-gray-800">Contact</h2>
                <p className="text-gray-800">{user.email}</p>
              </div>
              <div className="bg-white shadow rounded-lg p-6 mb-6">
                <h2 className="text-2xl font-bold text-gray-800">Bio</h2>
                <p className="text-gray-800">{user.bio}</p>
              </div>
              <div className="bg-white shadow rounded-lg p-6 mb-6">
                <h2 className="text-2xl font-bold text-gray-800">Web Links</h2>
                <ul>
                  {user.webLinks.map((link, index) => (
                    <li key={index} className="text-blue-600 hover:underline"><a href={link}>{link}</a></li>
                  ))}
                </ul>
              </div>
              <div className="bg-white shadow rounded-lg p-6">
                <h2 className="text-2xl font-bold text-gray-800">Skills</h2>
                <ul>
                  {user.skills.map((skill, index) => (
                    <li key={index} className="text-gray-800">{skill}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              {/* Right Column: Company Details */}
              <div className="bg-white shadow rounded-lg p-6">
                <h2 className="text-2xl font-bold text-gray-800">Company Details</h2>
                <p className="text-gray-800"><strong>Company Name:</strong> {user.companyName}</p>
                <p className="text-gray-800"><strong>Location:</strong> {user.companyLocation}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfilePage;
