import { api } from "./api";
import { GET_DETAILED_COMPANY_VIEW } from "./urls";
import { GET_COMPANY_LIST } from "./urls";
 const fetchcompanies = async ({ skip, limit, search_key = '', category = '' }) => {
    try {
      const response = await api.get(GET_COMPANY_LIST, {
        params: {
          skip,
          limit,
          search_key,  // Ensure this is included
          category     
        },
      });
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      console.error('Error fetching companies:', error);
      throw error;
    }
  };
  const getDetailedCompanyView = async (cid) => {
    try {
        console.log(`Fetching details for company ID (cid): ${cid}`);
        const res = await api.get(GET_DETAILED_COMPANY_VIEW, {
            params: { cid } 
        });
        return res.data.data; 
    } catch (error) {
        console.error('Error fetching company details:', error);
        throw error;
    }
};

export {fetchcompanies,getDetailedCompanyView}