import { api } from "./api";
import { GET_MAD_LOVE_VERIFICATION_LIST } from "./urls";
import { GET_VERIFICATION_VIEW_BY_UID } from "./urls";
import { UPDATE_VERIFICATION_APPROVAL_STATUS} from "./urls";


const fetchVerifiedCompanies = async ({ skip, limit, gender = '', gender_preference = '', occupation = '' }) => {
    try {
      const response = await api.get(GET_MAD_LOVE_VERIFICATION_LIST, {
        params: {
          skip,               
          limit,
          gender,             
          gender_preference,  
          occupation          
        },
      });
      
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      console.error('Error fetching companies:', error);
      throw error;
    }
  };


  const getVerificationViewByUid = async (uid) => {
    try {
      console.log("Fetching Verification View by UID:", GET_VERIFICATION_VIEW_BY_UID(uid));
  
      const res = await api.get(GET_VERIFICATION_VIEW_BY_UID(uid));
      console.log("Verification View Response:", res.data);
  
      return res.data;
    } catch (error) {
      console.error("Error fetching verification view by UID:", error);
      throw error;
    }
  };


  const updateVerificationApprovalStatus = async (uid, payload) => {
    try {
        console.log("Updating Verification Approval Status for UID:", uid, payload);

        const response = await api.put(UPDATE_VERIFICATION_APPROVAL_STATUS(uid), payload);
        console.log("Update Verification Response:", response.data);

        return response.data; // Adjust this based on your API response structure
    } catch (error) {
        console.error("Error updating verification approval status:", error);
        throw error;
    }
};

export {fetchVerifiedCompanies,getVerificationViewByUid,updateVerificationApprovalStatus}