import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  setDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { BiChevronLeft } from "react-icons/bi";
import { firebaseConfig } from "../firebaseConfig";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);




const Enquiry = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;

  const [formData, setFormData] = useState({
    name: "",
    whatsappNumber: "",
    address:   "",
    dateOfEvent: "",
    typeOfWedding: "",
    budget: "",
    message: "",
    customerId: "", // Will be fetched
    uid: localStorage.getItem("customerUid") || "", // Retrieve customerUid from localStorage
    companyDocId: state?.companyDocId || "",
    companyName: state?.companyName || "",
    companyCategory: state?.categoryType || "",
    email: "", // Will be fetched
    profileUrl: "", // Will be fetched
    userName: "", // Will be fetched
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch customer details based on customerUid
  useEffect(() => {
    const fetchCustomerDetails = async () => {
      const customerUid = localStorage.getItem("customerUid");
      if (!customerUid) {
        console.error("No customerUid found in localStorage");
        return;
      }

      try {
        const customerQuery = query(
          collection(db, "customer"),
          where("customerUid", "==", customerUid)
        );
        const querySnapshot = await getDocs(customerQuery);

        if (!querySnapshot.empty) {
          const customerDoc = querySnapshot.docs[0];
          const customerData = customerDoc.data();

          setFormData((prev) => ({
            ...prev,
            customerId: customerDoc.id,
            userName: customerData.name || "",
            email: customerData.email || "",
            profileUrl: customerData.profileUrl || "",
          }));
        } else {
          console.error("No customer found for the provided customerUid");
        }
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    };

    fetchCustomerDetails();
  }, []);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set loading state to true
  
    // Convert budget to a number
    const formattedBudget = Number(formData.budget);
  
    // Validate if the budget is a number
    if (isNaN(formattedBudget) || formattedBudget <= 0) {
      alert("Please enter a valid numeric budget.");
      setIsSubmitting(false);
      return;
    }
  
    try {
      // Create a new object with budget converted to a number
      const formattedData = {
        ...formData,
        budget: formattedBudget, // Ensure budget is stored as a number
        enquiryCreatedTime: serverTimestamp(),
        isRead: false,
      };
  
      // Add a new document to the customerEnquiry collection
      const docRef = await addDoc(collection(db, "customerEnquiry"), formattedData);
  
      // Update the document with the document ID
      await setDoc(docRef, { docId: docRef.id }, { merge: true });
  
      console.log("Enquiry submitted successfully with ID:", docRef.id);
      alert("Enquiry submitted successfully!");
  
      // Navigate back to the detail page
      if (state?.previousPath) {
        navigate(state.previousPath);
      } else {
        navigate("/"); // Default fallback
      }
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setIsSubmitting(false); // Set loading state to false
    }
  };
  

  return (
    <div className="min-h-screen  flex flex-col font-inter font-thin">
      {/* Fixed Header */}
      <div className="sticky top-0   z-10 h-16">
  <div className="max-w-screen-lg mx-auto flex items-center px-4 py-3">
    <button
      onClick={() =>
        navigate(state?.previousPath || "/", { state: { ...state } })
      }
      className="text-black font-inter text-xl mr-2"
    >
      <i className="fa-solid fa-arrow-left"></i>
    </button>

    <button
            onClick={() => navigate("/detailpage")}
            className="absolute left-4 text-black font-inter text-xl"
          >
            <BiChevronLeft className="text-3xl md:text-4xl" />
          </button>
    <h2 className="flex-grow text-center text-xl font-inter font-medium">
      Enquire
    </h2>
  </div>
</div>


      {/* Form Section */}
      <div className="flex-grow max-w-screen-lg mx-auto px-4 sm:px-8 lg:px-12 pt-2 w-full">
      <form
  onSubmit={handleSubmit}
  className=" p-4 sm:p-6 md:p-8 lg:p-12 space-y-6"
>
  {/* Input Fields */}
  <div className="flex flex-col space-y-2">
    <label
      htmlFor="name"
      className="text-gray-600 font-medium 
      font-inter text-sm sm:text-base lg:text-lg"
    >
      Name
    </label>
    <input
      id="name"
      name="name"
      type="text"
      placeholder="Full Name"
      value={formData.name}
      onChange={handleInputChange}
      className="p-3 sm:p-4 bg-customGray text-black placeholder-black font-inter rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm sm:text-base lg:text-lg w-full"
    />
  </div>
  <div className="flex flex-col space-y-2">
    <label
      htmlFor="whatsappNumber"
      className="text-gray-600 font-medium font-inter text-sm sm:text-base lg:text-lg"
    >
      Whatsapp Number
    </label>
    <input
      id="whatsappNumber"
      name="whatsappNumber"
      type="text"
      placeholder="Whatsapp Number"
      value={formData.whatsappNumber}
      onChange={handleInputChange}
      className="p-3 bg-customGray text-black placeholder-black font-inter rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm sm:text-base lg:text-lg w-full"
    />
  </div>
  <div className="flex flex-col space-y-2">
    <label
      htmlFor="address"
      className="text-gray-600 font-inter font-medium text-sm sm:text-base lg:text-lg"
    >
      Address
    </label>
    <input
      id="address"
      name="address"
      type="text"
      placeholder="Your Address"
      value={formData.address}
      onChange={handleInputChange}
      className="p-3 bg-customGray text-black placeholder-black font-inter rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm sm:text-base lg:text-lg w-full"
    />
  </div>
  <div className="flex flex-col space-y-2">
    <label
      htmlFor="dateOfEvent"
      className="text-gray-600 font-inter font-medium text-sm sm:text-base lg:text-lg"
    >
      Date of Event
    </label>
    <input
      id="dateOfEvent"
      name="dateOfEvent"
      type="date"
      value={formData.dateOfEvent}
      onChange={handleInputChange}
      className="p-3 bg-customGray text-black placeholder-black font-inter rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm sm:text-base lg:text-lg w-full"
    />
  </div>
  <div className="flex flex-col space-y-2">
    <label
      htmlFor="typeOfWedding"
      className="text-black font-inter font-medium text-sm sm:text-base lg:text-lg"
    >
      Wedding Type
    </label>
    <select
      id="typeOfWedding"
      name="typeOfWedding"
      value={formData.typeOfWedding}
      onChange={handleInputChange}
      className="p-3 bg-customGray text-black placeholder-black font-inter rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm sm:text-base lg:text-lg w-full"
    >
      <option value="" disabled>
        Select an event type
      </option>
      <option value="Christian Wedding">Christian Wedding</option>
      <option value="Hindu Wedding">Hindu Wedding</option>
      <option value="Muslim Wedding">Muslim Wedding</option>
    </select>
  </div>
  <div className="flex flex-col space-y-2">
    <label
      htmlFor="budget"
      className="text-black font-medium font-inter  text-sm sm:text-base lg:text-lg"
    >
      Budget
    </label>
    <input
      id="budget"
      name="budget"
      type="text"
      placeholder="Price range"
      value={formData.budget}
      onChange={handleInputChange}
      className="p-3 bg-customGray text-black placeholder-black font-inter rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm sm:text-base lg:text-lg w-full"
    />
  </div>
  <div className="flex flex-col space-y-2">
    <label
      htmlFor="message"
      className="text-gray-600 font-medium font-inter text-sm sm:text-base lg:text-lg"
    >
      Message
    </label>
    <textarea
      id="message"
      name="message"
      placeholder="Write your thoughts"
      value={formData.message}
      onChange={handleInputChange}
      className="p-3 bg-customGray text-black placeholder-black font-inter rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm sm:text-base lg:text-lg w-full"
    />
  </div>

  {/* Submit Button */}
  <button
    type="submit"
    className={`w-full py-3 sm:py-4 text-white font-inter rounded-md text-sm sm:text-base lg:text-lg flex justify-center items-center ${
      isSubmitting
        ? "bg-gray-400 cursor-not-allowed"
        : "bg-black hover:bg-gray-800"
    }`}
    disabled={isSubmitting}
  >
    {isSubmitting ? (
      <svg
        className="animate-spin h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8v8H4z"
        ></path>
      </svg>
    ) : (
      "Submit"
    )}
  </button>
</form>

      </div>
    </div>
  );
};

export default Enquiry;


