import { GET_USER_DATA ,GET_USER_LIST} from "./urls";
import { GET_REVENUE } from "./urls";
import { GET_CUSTOMER_LIST } from "./urls";
import { GET_CUSTOMER_BY_ID } from "./urls";
import { EDIT_CUSTOMER_BY_ID } from "./urls";
import { DELETE_CUSTOMER_BY_ID } from "./urls";

import { api } from "./api";

// API to fetch user data
const getUserData = async () => {
  try {
    const res = await api.get(GET_USER_DATA);
    return res.data; // Adjust based on the API response structure
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

const fetchUserList = async ({ skip, limit, gender, gender_preference, occupation }) => {
    try {
      const params = {
        skip,
        limit,
        gender,
        gender_preference,
        occupation,
      };
  
      const res = await api.get(GET_USER_LIST, { params }); // Pass params to the API
      console.log("User List API Response:", res.data);
      return res.data; // Adjust based on the response structure
    } catch (error) {
      console.error("Error fetching user list:", error);
      throw error;
    }
  };


  const fetchRevenue = async () => {
    try {
      const res = await api.get(GET_REVENUE);
      return res.data; // Adjust based on the API response structure
    } catch (error) {
      console.error("Error fetching total revenue:", error);
      throw error;
    }
  };

  const fetchCustomerList = async ({ skip, limit, query }) => {
    try {
      const params = {
        skip,
        limit,
        query,
      };
  
      const res = await api.get(GET_CUSTOMER_LIST, { params }); // Pass params to the API
      console.log("Customer List API Response:", res.data);
      return res.data; // Adjust based on the response structure
    } catch (error) {
      console.error("Error fetching customer list:", error);
      throw error;
    }
  };

  const fetchCustomerById = async (id) => {
    try {
      const res = await api.get(GET_CUSTOMER_BY_ID(id));
      console.log("Customer by ID API Response:", res.data);
      return res.data; // Adjust based on the API response structure
    } catch (error) {
      console.error(`Error fetching customer with ID ${id}:`, error);
      throw error;
    }
  };

  const editCustomerById = async (id, customerData) => {
    try {
      const res = await api.put(EDIT_CUSTOMER_BY_ID(id), customerData);
      console.log("Edit Customer by ID API Response:", res.data);
      return res.data; // Adjust based on the API response structure
    } catch (error) {
      console.error(`Error editing customer with ID ${id}:`, error);
      throw error;
    }
  };

  const deleteCustomerById = async (id) => {
    try {
      const res = await api.delete(DELETE_CUSTOMER_BY_ID(id));
      console.log(`Customer with ID ${id} deleted successfully.`, res.data);
      return res.data; // Adjust based on the API response structure
    } catch (error) {
      console.error(`Error deleting customer with ID ${id}:`, error);
      throw error;
    }
  };
  

export { getUserData , fetchUserList , fetchRevenue, fetchCustomerList,fetchCustomerById,editCustomerById,deleteCustomerById};
