


// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { FaArrowUp, FaSpinner } from 'react-icons/fa';
// import Header from '../Components/header';
// import Sidebar from '../Components/sidebar';
// import Card from '../Components/card';
// import TotalRevenue from '../Components/total_revenue';
// import GenderFilterComponent from '../Components/gender_filter';
// import CategoryFilterComponent from '../Components/category_filter';
// import UserProfileModal from '../Components/detailed_view_cv_modal';
// import GenderPreferenceFilterComponent from '../Components/gender_preference';
// import { getUserData, fetchUserList } from '../../controller/madlove_home';

// const Home = () => {
//   const [users, setUsers] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isLoadingMore, setIsLoadingMore] = useState(false);
//   const [selectedGender, setSelectedGender] = useState('');
//   const [genderPreference, setGenderPreference] = useState('');
//   const [skip, setSkip] = useState(0);
//   const [limit] = useState(10);
//   const [hasMore, setHasMore] = useState(true);
//   const [registeredUsersCount, setRegisteredUsersCount] = useState(0);
//   const [verifiedUsersCount, setVerifiedUsersCount] = useState(0);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [occupation, setOccupation] = useState('');
//   const mainContentRef = useRef(null);
//   const [showScrollTopButton, setShowScrollTopButton] = useState(false);
//   const [showUserProfileModal, setShowUserProfileModal] = useState(false);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [subscribedUsersCount, setSubscribedUsersCount] = useState(0);
//   const [ActiveUsersCount, setActiveUsersCount] = useState(0);


//   // Fetch card data
//   useEffect(() => {
//     const fetchRegisteredUsers = async () => {
//       try {
//         const response = await getUserData();
//         const usersCount = response?.data?.users || 0;
//         setRegisteredUsersCount(usersCount);
//         const verifiedCount = response?.data?.verified || 0;
//         setVerifiedUsersCount(verifiedCount);
//         const subscribedUsersCount = response?.data?.subscribed_users || 0;
//         setSubscribedUsersCount(subscribedUsersCount);
//         const ActiveUsersCount = response?.data?.active_users || 0;
//         setActiveUsersCount(ActiveUsersCount);
        
//       } catch (error) {
//         console.error('Error fetching Users onboarded data:', error);
//       }
//     };

//     fetchRegisteredUsers();
//   }, []);

//   // Fetch users for table
//   // Fetch users for table
// const fetchUsersTableData = useCallback(
//   async (loadMore = false) => {
//     try {
//       if (!hasMore) {
//         // Stop fetching if no more data
//         return;
//       }

//       if (!loadMore) {
//         setIsLoading(true);
//         setSkip(0); // Reset skip for fresh fetch
//       } else {
//         setIsLoadingMore(true);
//       }

//       const params = {
//         skip: loadMore ? skip + limit : 0,
//         limit,
//         gender: selectedGender,
//         gender_preference: genderPreference,
//         occupation,
//         search: searchTerm,
//       };

//       const response = await fetchUserList(params);
//       const fetchedProfiles = response?.data?.profiles || []; // Extract profiles from API response
//       const isMoreData = response?.data?.is_more_data || false; // Check if more data is available

//       if (fetchedProfiles.length) {
//         if (loadMore) {
//           setUsers((prevUsers) => [...prevUsers, ...fetchedProfiles]);
//           setSkip((prevSkip) => prevSkip + limit);
//         } else {
//           setUsers(fetchedProfiles);
//         }
//       }

//       setHasMore(isMoreData); // Update hasMore based on is_more_data from API
//     } catch (error) {
//       console.error('Error fetching users for table:', error);
//     } finally {
//       setIsLoading(false);
//       setIsLoadingMore(false);
//     }
//   },
//   [skip, limit, selectedGender, genderPreference, searchTerm, occupation, hasMore]
// );

// // Handle "Show More" button click
// const handleShowMore = () => {
//   if (hasMore) {
//     fetchUsersTableData(true);
//   }
// };


//   // Initial fetch or filters update
//   useEffect(() => {
//     fetchUsersTableData(false);
//   }, [selectedGender, genderPreference, searchTerm, occupation]);

//   // Scroll handling
//   const handleScroll = () => {
//     if (mainContentRef.current) {
//       const { scrollTop } = mainContentRef.current;
//       setShowScrollTopButton(scrollTop > 300);
//     }
//   };

//   const scrollToTop = () => {
//     if (mainContentRef.current) {
//       mainContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
//     }
//   };

  

//   const handleViewUser = (userId) => {
//     if (!userId) {
//       console.error('Invalid userId:', userId);
//       return;
//     }
//     setSelectedUser(userId);
//     setShowUserProfileModal(true);
//   };

//   const formatDate = (dateString) => {
//     if (!dateString) return 'N/A';
//     return new Date(dateString).toLocaleString('en-US', {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//       hour: '2-digit',
//       minute: '2-digit',
//       hour12: true,
//     });
//   };

//   return (
//     <>
//       <Header />
//       <div className="flex h-screen bg-[#F3F3F3]">
//         <Sidebar />
//         <div className="flex-1 flex flex-col">
//           <main
//             ref={mainContentRef}
//             className="flex-1 overflow-x-hidden overflow-y-auto p-6"
//             onScroll={handleScroll}
//           >
//             <div className="grid grid-cols-1 md:grid-cols-12 gap-6 w-full">
//               <div className="grid grid-cols-1 md:col-span-8 md:grid-cols-2 gap-6">
//                 <Card
//                   title="Users onboarded"
//                   count={registeredUsersCount}
//                   bgColor="bg-white"
//                   icon="/assets/user-add.svg"
//                   loading={isLoading}
//                 />
//                 <Card
//                   title="Verified Users"
//                   count={verifiedUsersCount}
//                   bgColor="bg-white"
//                   icon="/assets/people.svg"
//                   loading={isLoading}
//                 />
//                 <Card
//                   title="Subscribed Users"
//                   count={subscribedUsersCount}
//                   bgColor="bg-white"
//                   icon="/assets/people.svg"
//                   loading={isLoading}
//                 />

// <Card
//                   title="Active Users"
//                   count={ActiveUsersCount}
//                   bgColor="bg-white"
//                   icon="/assets/people.svg"
//                   loading={isLoading}
//                 />
//               </div>
//               <div className="col-span-1 md:col-span-4">
//                 <TotalRevenue />
//               </div>
//             </div>

//             <div className="mt-6">
//               <div className="flex justify-between items-center mb-3">
//                 <h1 className="text-xl font-semibold text-[#545454]">Users</h1>
//               </div>
//               <div id="users-table" className="bg-white rounded-lg shadow-md p-6 relative font-poppins text-[#232323] mt-5">
//                 <div className="overflow-y-auto">
//                   <table className="w-full">
//                   <thead className="sticky top-0 bg-white">
//   <tr className="text-center text-sm font-poppins">
//     <th className="pb-3 font-semibold">Sl No</th>
//     <th className="pb-3 font-semibold">Profile</th>
//     <th className="pb-3 font-semibold  ">Email</th> 
//     <th className="pb-3 font-semibold hidden sm:table-cell">Joining Time</th>
//     <th className="pb-3 font-semibold hidden sm:table-cell">
//       <GenderFilterComponent
//         placeholder="Select Gender"
//         selectedGender={selectedGender}
//         onGenderChange={(value) => {
//           setSelectedGender(value);
//           fetchUsersTableData(false);
//         }}
//       />
//     </th>
//     <th className="pb-3 font-semibold hidden sm:table-cell">Action</th>
//   </tr>
// </thead>
// <tbody>
//   {users.map((user, index) => (
//     <tr
//       key={user.user || index}
//       className="text-center align-middle cursor-pointer"
//       onClick={() => handleViewUser(user.user)}
//     >
//       <td className="py-3">{index + 1}</td>
//       <td className="py-3 text-left flex items-center text-sm truncate max-w-[150px]">
//         <img
//           src={user.profile_image || '/default-profile.png'}
//           alt="Profile"
//           className="w-8 h-8 rounded-full bg-gray-200 mr-2"
//         />
//         <span className="truncate">{user.name || 'N/A'}</span>
//       </td>
//       <td className="py-3 text-left truncate max-w-[150px] ">
//         {user.email || 'N/A'} {/* Display the email */}
//       </td>
//       <td className="py-3 hidden sm:table-cell">{formatDate(user.created_on)}</td>
//       <td className="py-3 hidden sm:table-cell">{user.gender || 'N/A'}</td>
//       <td className="py-3 hidden sm:table-cell">
//         <button
//           className="px-3 py-1 bg-[#D8393F] rounded-md text-sm text-[#ffffff]"
//           onClick={() => handleViewUser(user.user)}
//         >
//           View
//         </button>
//       </td>
//     </tr>
//   ))}
// </tbody>

//                   </table>
//                 </div>
//                 {hasMore && (
//                   <div className="flex justify-center mt-4">
//                     <button
//                       onClick={handleShowMore}
//                       className="px-2 py-2 bg-[#D8393F] text-[#FFFFFF] rounded-md flex items-center"
//                     >
//                       {isLoadingMore && <FaSpinner className="animate-spin text-sm mr-2" />}
//                       Show More
//                     </button>
//                   </div>
//                 )}
//                 {!hasMore && (
//                   <div className="text-center mt-4 text-gray-500">
//                     No more users to load.
//                   </div>
//                 )}
//               </div>
//             </div>
//           </main>
//           {showUserProfileModal && selectedUser && (
//             <UserProfileModal
//               selectedUser={selectedUser}
//               onClose={() => setShowUserProfileModal(false)}
//             />
//           )}
//           {showScrollTopButton && (
//             <button
//               onClick={scrollToTop}
//               className="fixed bottom-10 right-10 bg-[#D8393F] p-3 rounded-full text-[#ffffff]"
//             >
//               <FaArrowUp />
//             </button>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Home;


import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FaArrowUp, FaSpinner } from 'react-icons/fa';
import Header from '../Components/header';
import Sidebar from '../Components/sidebar';
import { fetchCustomerList } from '../../controller/dreamin_home';
import UserProfileModal from '../Components/detailed_view_cv_modal';
import { fetchCustomerById } from '../../controller/dreamin_home';

const Home = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const [mainContentRef, setMainContentRef] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null); // For showing user details
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state

  const fetchUsersTableData = useCallback(
    async (loadMore = false) => {
      try {
        if (!hasMore && loadMore) return;
  
        if (!loadMore) {
          setIsLoading(true);
          setSkip(0);
        } else {
          setIsLoadingMore(true);
        }
  
        const params = {
          skip: loadMore ? users.length : 0, // Dynamically calculate skip
          limit,
        };
  
        const response = await fetchCustomerList(params);
        console.log('API Response:', response);
  
        const fetchedUsers = response?.data || [];
        const hasMoreData = response?.has_more_data || false; // Use the `has_more_data` field
  
        if (loadMore) {
          setUsers((prevUsers) => [...prevUsers, ...fetchedUsers]);
        } else {
          setUsers(fetchedUsers);
        }
  
        setHasMore(hasMoreData); // Update `hasMore` based on the API response
      } catch (error) {
        console.error('Error fetching users for table:', error);
      } finally {
        setIsLoading(false);
        setIsLoadingMore(false);
      }
    },
    [users, limit, hasMore]
  );
  
  
  const handleShowMore = () => {
    if (hasMore) fetchUsersTableData(true);
  };

  


  useEffect(() => {
    fetchUsersTableData(false);
  }, []);

  const handleViewUser = async (uid) => {
    try {
      const userDetails = await fetchCustomerById(uid);
      setSelectedUser({
        id: uid, // Pass the customer ID
        ...userDetails.data, // Include other user details
      });
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };
  

  const scrollToTop = () => {
    if (mainContentRef) {
      mainContentRef.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <>
      <Header />
      <div className="flex h-screen bg-[#F3F3F3]">
        <Sidebar />
        <div className="flex-1 flex flex-col">
          <main
            ref={(ref) => setMainContentRef(ref)}
            className="flex-1 overflow-x-hidden overflow-y-auto p-6"
          >
            <div className="mt-6">
              <h1 className="text-xl font-semibold text-[#545454] mb-4">Users</h1>
              <div className="bg-white rounded-lg shadow-md p-6">
                {isLoading ? (
                  <div className="flex justify-center items-center h-64">
                    <FaSpinner className="animate-spin text-4xl text-gray-500" />
                  </div>
                ) : (
                  <div>
                    <table className="w-full text-sm">
                      <thead className="bg-gray-100">
                        <tr className="text-center font-semibold">
                          <th className="py-3">Sl. No</th>
                          <th className="py-3">Profile</th>
                          <th className="py-3">Name</th>
                          <th className="py-3">Email</th>
                          <th className="py-3">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.length > 0 ? (
                          users.map((user, index) => (
                            <tr key={user.id} className="text-center border-t">
                              <td className="py-3">{index + 1}</td>
                              <td className="py-3">
                                <img
                                  src={user.image || './profile-dreamin.png'}
                                  alt="Profile"
                                  className="w-10 h-10 rounded-full mx-auto"
                                />
                              </td>
                              <td className="py-3">{user.name || 'N/A'}</td>
                              <td className="py-3">{user.email || 'N/A'}</td>
                              <td className="py-3">
                                <button
                                  onClick={() => handleViewUser(user.id)}
                                  className="bg-[#222222] text-white px-4 py-2 rounded"
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4" className="text-center py-3">
                              No users found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    {isLoadingMore && (
                      <div className="text-center py-3">
                        <FaSpinner className="animate-spin text-2xl text-gray-500" />
                      </div>
                    )}

                    {hasMore && !isLoadingMore && (
                      <div className="text-center mt-4">
                        <button
                          onClick={handleShowMore}
                          className="bg-[#222222] text-white px-4 py-2 rounded"
                        >
                          Show More
                        </button>
                      </div>
                    )}

                    {!hasMore && (
                      <div className="text-center mt-4 text-gray-500">
                        No more users to load.
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
        {/* User Details Modal */}
        {isModalOpen && selectedUser && (
        <UserProfileModal selectedUser={selectedUser} onClose={() => setIsModalOpen(false)} />
      )}
    </>
  );
};

export default Home;
