import React from 'react';
import { useNavigate } from 'react-router-dom';

const SubscriptionPlan = () => {
  const navigate = useNavigate();

  const handlePlanClick = (planName) => {
    console.log(`Selected: ${planName}`);
    navigate('/form');
  };

  const features = [
    'Personalized Event Planning',
    'Guest Management & Invitations',
    'Budget & Expense Tracking',
    'Vendor & Service Bookings',
    'Timeline & Checklist Management',
    'Live Updates & Notifications'
  ];
  

  return (
    <div className="w-full min-h-screen">
      {/* Header Section */}
      <div className="flex justify-between items-center px-4 sm:px-8 py-2 w-full sm:w-4/5 mx-auto">
        <div className="logo">
          <img src="/dreamin_logo.svg" alt="Logo" className="h-8 w-8 sm:h-12 sm:w-12" />
        </div>
        <div className="text-xl sm:text-2xl font-normal font-inter">Checkout</div>
      </div>

      {/* Main Content */}
      <div className="flex flex-col sm:flex-row justify-between items-center w-full sm:w-4/5 mx-auto mt-4 sm:mt-8 min-h-[70vh] px-4 sm:px-0">
        {/* Logo Section - Hidden on mobile */}
        <div className="hidden sm:flex justify-center items-center border-gray-500 rounded-2xl w-1/2 h-[70%] box-border">
          <img 
            src="/dreamin_logo.svg" 
            alt="Parky Logo" 
            className="w-4/5 h-4/5 object-contain"
          />
        </div>

        {/* Plans Section */}
        <div className="flex justify-center items-center w-full sm:w-2/5 my-4 sm:my-0">
          <div 
            className="bg-gradient-to-b from-gray-300 to-gray-100 text-black rounded-2xl p-4 sm:p-6 w-full sm:w-[22rem] text-center shadow-lg cursor-pointer transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-xl"
            onClick={() => handlePlanClick('Basic Plan')}
          >
            <div className="text-3xl sm:text-4xl font-bold mb-2 font-inter">999 Rs</div>
            <div className="text-sm sm:text-base font-normal mb-4 font-inter">Per / Month</div>
            <div className="text-xl sm:text-2xl font-bold my-4 font-inter">Basic Plan</div>
            
            <div className="text-sm sm:text-base my-4">
              <div className="grid grid-cols-2 gap-2 sm:gap-4">
                <div className="text-left">
                  <ul className="list-none p-0">
                    {features.slice(0, 3).map((feature, index) => (
                      <li key={index} className="my-1 sm:my-2 flex items-center text-sm sm:text-base">
                        <span className="mr-1 sm:mr-2 text-gray-700 text-lg">•</span>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="text-left">
                  <ul className="list-none p-0">
                    {features.slice(3, 6).map((feature, index) => (
                      <li key={index} className="my-1 sm:my-2 flex items-center text-sm sm:text-base">
                        <span className="mr-1 sm:mr-2 text-gray-700 text-lg">•</span>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="text-xs sm:text-sm mt-4 font-inter font-normal">Includes GST & SCGST</div>
            <div className="mt-2 sm:mt-4 text-xs sm:text-sm font-inter">
              <span className="block">GST: 9%</span>
              <span className="block">SCGST: 9%</span>
            </div>

            <button 
              className="bg-gray-800 text-white border-none rounded-full px-4 sm:px-6 py-2 sm:py-3 mt-4 sm:mt-6 cursor-pointer text-sm sm:text-base transition-colors duration-300 hover:bg-gray-700"
              onClick={(e) => {
                e.stopPropagation();
                navigate('/form');
              }}
            >
              Pay Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan;