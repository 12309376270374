import { get_verification_list } from "./verification";
import { updateVerificationView } from "./verification";
import { getVerificationView } from "./verification";
import { showToast } from "../View/Components/toast";


export const fetchUsersFromApi = async (skip, limit, setUsers, setHasMore, setLoading, setIsFetching) => {
  setIsFetching(true);
  try {
    const response = await get_verification_list(skip, limit);
    if (response.data && Array.isArray(response.data.data)) {
      const userList = response.data.data;
      const pendingUsers = userList.filter(user => user.company_status === 'VERIFICATION_PENDING');

      if (pendingUsers.length === 0) {
        setHasMore(false); // If no users are found, stop loading more
      }

      setUsers(prevUsers => {
        const newUsers = skip === 0 ? pendingUsers : [...prevUsers, ...pendingUsers];
        return newUsers;
      });

      if (userList.length < limit) {
        setHasMore(false); // Disable loading more if we have fewer users than the limit
      }
    } else {
      setHasMore(false);
    }
  } catch (error) {
    console.error("Error fetching users:", error);
    setHasMore(false);
  } finally {
    setLoading(false);
    setIsFetching(false);
  }
};

export const fetchCompanyProfile = async (uid, setCompanyProfile, setLoading) => {
  try {
    if (!uid) {
      throw new Error('UID is undefined');
    }
    const response = await getVerificationView(uid);
    console.log('Full API Response:', response.data);
    
    if (response.data && response.status === 200 && response.data.data) {
      console.log('Setting company profile:', response.data.data); // Log before setting state
      setCompanyProfile(response.data.data); // Set company profile data from the API
    } else {
      console.warn('Empty or invalid company profile data:', response.data);
      setCompanyProfile(null); // Handle empty profile case
    }
  } catch (error) {
    console.error('Error fetching company profile:', error);
  } finally {
    setLoading(false); // Stop the loading spinner
  }
};





// Update the verification view for the company
export const submitVerificationUpdate = async (uid, payload, remarks, navigate, setButtonLoading) => {
  setButtonLoading(true);
  
  try {
    const response = await updateVerificationView(uid, payload);
    
    console.log('Full API Response:', response); // Log the full response
    
    if (response.status_code === 202) {
      // The status_code and message are in the root of the response, not in response.data
      console.log('Navigating to /verification_list...'); // Log right before navigation
      navigate('/verification_list');
    } else {
      console.warn('Unexpected response structure:', response.data); // Log any unexpected structure
    }
  } catch (error) {
    console.error('Error in catch block:', error); // More descriptive error
    showToast('Error updating verification view', 'error');
  } finally {
    setButtonLoading(false);
  }
};
