

import React, { useState } from 'react';
import EditDetailsComponent from './edit_customer_details';
import DeletePopup from './delete_pop_up';
import { deleteCustomerById } from '../../controller/dreamin_home';

const UserProfileModal = ({ selectedUser, onClose }) => {
  const { name, email, date_of_birth, phone_number, image } = selectedUser;
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Parse the date_of_birth string into a valid Date object
  const parseDateOfBirth = (dob) => {
    const [day, month, year] = dob.split('-'); // Split the string into day, month, year
    return new Date(`${year}-${month}-${day}`); // Reformat into a valid date string (YYYY-MM-DD)
  };

  const formattedDateOfBirth =
    date_of_birth && date_of_birth.includes('-')
      ? parseDateOfBirth(date_of_birth).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      : 'Invalid Date';



      const handleDeleteConfirm = async () => {
        setIsLoading(true);
        try {
          await deleteCustomerById(selectedUser.id);
          console.log(`Customer with ID ${selectedUser.id} deleted successfully.`);
          setIsDeletePopupOpen(false);
          onClose(); // Close the modal after deletion
        } catch (error) {
          console.error(`Error deleting customer with ID ${selectedUser.id}:`, error);
        } finally {
          setIsLoading(false);
        }
      };
    

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-6 w-full max-w-md relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          ✕
        </button>
        <div className="flex items-center mb-4">
          <div className="w-32 h-32 flex-shrink-0 flex items-center justify-center">
            <img
              src={image}
              alt="Profile"
              className="w-full h-full rounded-full object-cover"
            />
          </div>
          <div className="flex flex-row space-x-4 w-full justify-end">
            <button
              onClick={() => setIsEditModalOpen(true)}
              className="flex items-center justify-center w-10 h-10 bg-[#8f8d8d] rounded-full hover:bg-[#a39e9e]"
            >
              <img src="/assets/edit-icon.svg" alt="Edit" className="w-6 h-6" />
            </button>
            <button
              onClick={() => setIsDeletePopupOpen(true)}
              className="flex items-center justify-center w-10 h-10 bg-red-400 rounded-full hover:bg-red-600"
            >
              <img src="/assets/delete_account_icon.svg" alt="Delete" className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full">
          <p className="text-gray-800 text-lg mb-2">
            <strong>Name:</strong> {name}
          </p>
          <p className="text-gray-800 text-lg mb-2">
            <strong>Email:</strong> {email}
          </p>
          <p className="text-gray-800 text-lg mb-2">
            <strong>Phone Number:</strong> {phone_number}
          </p>
          <p className="text-gray-800 text-lg mb-2">
            <strong>Date of Birth:</strong> {formattedDateOfBirth}
          </p>
        </div>
        {isEditModalOpen && (
  <EditDetailsComponent
    categoryData={{
      id: selectedUser.id, // Ensure ID is passed
      name: selectedUser.name,
      phone_number: selectedUser.phone_number,
      image: selectedUser.image,
    }}
    onClose={() => setIsEditModalOpen(false)}
  />
)}

{isDeletePopupOpen && (
          <DeletePopup
            message="Are you sure you want to delete this customer?"
            onConfirm={handleDeleteConfirm}
            onCancel={() => setIsDeletePopupOpen(false)}
            isLoading={isLoading}
          />
        )}

      </div>
    </div>
  );
};

export default UserProfileModal;
