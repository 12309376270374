import React from 'react';

const Header = () => {
  return (
    <header className="flex items-center justify-between p-2 bg-[white] ">
      <div>

        <img src="/assets/dreamin.png" alt="MadLove logo" className="h-6 " />
      </div>
      <div className="flex items-center space-x-4">
        
      </div>
    </header>
  );
};

export default Header;
