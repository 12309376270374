import React, { useEffect, useState } from 'react';
import { fetchCarouselById } from '../../controller/curousal';
import { FaTimes } from 'react-icons/fa';
import { deleteCarousel } from '../../controller/curousal';
import DeletePopup from './delete_pop_up';
import { FaSpinner } from 'react-icons/fa';
import EditCurousalModal from './edit_curousal';

const CurousalDetails = ({ id, onClose ,onRefresh }) => {
    const [curousal, setCurousal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showFullImage, setShowFullImage] = useState(false);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    // Fetch carousel details by ID
    useEffect(() => {
        console.log("CurousalDetails received ID:", id);  // Debugging Log
    
        if (!id) {
            console.error("Error: Curousal ID is undefined");
            return;
        }
    
        const getCurousalDetails = async () => {
            try {
                const response = await fetchCarouselById(id);
                console.log("Fetched Carousel Details:", response); // Debugging Log
    
                if (response.data) {
                    setCurousal(response.data); // Ensure this matches API response structure
                } else {
                    console.error("Error: Unexpected API response format", response);
                }
            } catch (error) {
                console.error("Error fetching carousel details:", error);
            } finally {
                setLoading(false);  
            }
        };
    
        getCurousalDetails();
    }, [id]);

      // Handle Delete Confirmation
    
    // Handle Delete Confirmation
    const handleDelete = async () => {
        setIsDeleting(true);
        try {
            await deleteCarousel(id);
            // alert("Carousel deleted successfully!");
            setIsDeletePopupOpen(false);
            onClose();
            onRefresh();
        } catch (error) {
            console.error("Error deleting carousel:", error);
        } finally {
            setIsDeleting(false);
        }
    };
    
    

    if (loading) {
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
               <div className="bg-white p-6 rounded-lg flex items-center justify-center">
                    <FaSpinner className="animate-spin text-4xl text-gray-500" />
                </div>
            </div>
        );
    }

    if (!curousal) {
        return null;
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white rounded-lg p-6 w-96 relative">
                <button onClick={onClose} className="absolute top-2 right-2 text-gray-600 hover:text-gray-900">
                    <FaTimes size={20} />
                </button>

                <h2 className="text-xl font-semibold mb-4">Carousel Details</h2>
                <div className="flex flex-row space-x-4 w-full justify-end">
                <button
    onClick={() => setIsEditModalOpen(true)}
    className="flex items-center justify-center w-10 h-10 bg-[#8f8d8d] rounded-full hover:bg-[#a39e9e]"
>
    <img src="/assets/edit-icon.svg" alt="Edit" className="w-6 h-6" />
</button>
            <button
                        onClick={() => setIsDeletePopupOpen(true)}
                        className="flex items-center justify-center w-10 h-10 bg-red-400 rounded-full hover:bg-red-600"
                    >
                        <img src="/assets/delete_account_icon.svg" alt="Delete" className="w-6 h-6" />
                    </button>
          </div>
                <div className="mb-4">
                    <strong>ID:</strong> {curousal.id}
                </div>
           
                <div className="mb-4">
                    <strong>Company Name:</strong> {curousal.company_name || 'N/A'}
                </div>
                <div className="mb-4">
                    <strong>Title:</strong> {curousal.title || 'N/A'}
                </div>
                <div className="mb-4">
                    <strong>Start Date:</strong> {new Date(curousal.start_date).toLocaleDateString()}
                </div>
                <div className="mb-4">
                    <strong>End Date:</strong> {new Date(curousal.end_date).toLocaleDateString()}
                </div>
                <div className="mb-4">
                    <strong>Button:</strong> <a href={curousal.button_link} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">{curousal.button_name}</a>
                </div>
                
                {/* Clickable Image */}
                <div className="mb-4 cursor-pointer" onClick={() => setShowFullImage(true)}>
                    <strong>Image:</strong>
                    <img src={curousal.image_url} alt="Carousel" className="w-full rounded-md mt-2" />
                </div>

                <button onClick={onClose} className="px-4 py-2 bg-red-500 text-white rounded-lg w-full">
                    Close
                </button>
            </div>

            {/* Full Image Modal */}
            {showFullImage && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80">
                    <div className="relative">
                        <button onClick={() => setShowFullImage(false)} className="absolute top-2 right-2 text-white">
                            <FaTimes size={24} />
                        </button>
                        <img src={curousal.image_url} alt="Full View" className="max-w-full max-h-screen rounded-md" />
                    </div>
                </div>
            )}

{isDeletePopupOpen && (
                <DeletePopup
                    message="Are you sure you want to delete?"
                    onConfirm={handleDelete}
                    onCancel={() => setIsDeletePopupOpen(false)}
                    isLoading={isDeleting}
                />
            )}

{isEditModalOpen && (
    <EditCurousalModal id={id} onClose={() => setIsEditModalOpen(false)} onRefresh={onRefresh} />
)}
        </div>
    );
};

export default CurousalDetails;
