import React from "react";

const FilterAlert = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" onClick={onClose}>
      <div className="bg-white p-6 rounded-lg shadow-lg w-80" onClick={(e) => e.stopPropagation()}>
        <h2 className="text-lg font-medium mb-4">Choose pricing</h2>
        <div className="space-y-3">
          <label className="flex justify-between items-center p-2">
            <span>Budget</span>
            <input type="checkbox" />
          </label>
          <label className="flex justify-between items-center p-2">
            <span>Premium</span>
            <input type="checkbox" />
          </label>
          <label className="flex justify-between items-center p-2">
            <span>Luxury</span>
            <input type="checkbox" />
          </label>
        </div>
        <button className="w-full mt-4 bg-black text-white py-2 rounded" onClick={onClose}>
          Apply
        </button>
      </div>
    </div>
  );
};

export default FilterAlert;
