import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { firebaseConfig } from "../firebaseConfig";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Carousel = () => {
  const [carouselItems, setCarouselItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchCarouselData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "carousalData"));
        const items = querySnapshot.docs.map((doc) => doc.data());
        setCarouselItems(items);
      } catch (error) {
        console.error("Error fetching carousel data:", error);
      }
    };

    fetchCarouselData();
  }, []);

  useEffect(() => {
    if (carouselItems.length === 0) return;
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [carouselItems]);

  return (
    <div className="w-full flex justify-center py-3">
      {/* ✅ Large screen UI (Curved Corners) */}
      <div className="hidden lg:block relative w-[92%] lg:w-[98%] h-[80vh] overflow-hidden shadow-lg rounded-3xl">
        <div
          className="flex transition-transform duration-700 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {carouselItems.map((item, index) => (
            <div key={index} className="min-w-full h-[80vh] relative">
              <img
                src={item.imageUrl}
                alt={item.title || "Carousel"}
                className="w-full h-full object-cover brightness-75 rounded-3xl"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 rounded-3xl"></div>

              {/* Left-aligned Overlay Content for Each Slide */}
              <div className="absolute top-1/2 left-12 transform -translate-y-1/2 text-left text-white">
                <h1 className="text-[60px] font-['Rosarivo'] not-italic leading-[1.1]">
                  YOUR <br />
                  <span className="text-[60px]">PERFECT WEDDING AWAITS</span>
                </h1>

                {/* Button and Company Name */}
                <div className="mt-6 flex items-center gap-4">
                  <span className="text-lg font-['Rosarivo']">
                    {item.companyName !== "Nil" ? item.companyName : "Dreamin"}
                  </span>
                  <a
                    href={item.buttonLink || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="px-8 py-1 border border-white text-white text-lg font-['Rosarivo'] tracking-wide rounded-full bg-white bg-opacity-10 backdrop-blur-md"
                  >
                    {item.buttonName || "Explore"}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Navigation Dots */}
        <div className="absolute bottom-6 left-1/2 transform -translate-x-1/2 flex gap-2">
          {carouselItems.map((_, index) => (
            <div
              key={index}
              className={`w-3 h-3 rounded-full cursor-pointer transition ${
                index === currentIndex ? "bg-white" : "bg-gray-400"
              }`}
              onClick={() => setCurrentIndex(index)}
            ></div>
          ))}
        </div>
      </div>

      {/* Small screen UI (<1024px) */}
      <div className="block lg:hidden relative w-full h-[38vh] overflow-hidden">
        <div 
          className="flex h-full transition-transform duration-700 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {carouselItems.map((item, index) => (
            <div 
              key={index} 
              className="w-full h-full flex-none relative"
            >
              <div className="absolute inset-0 bg-black">
                <img
                  src={item.imageUrl}
                  alt={item.title || "Carousel"}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-30"></div>
              </div>

              <div className="absolute bottom-8 right-6 flex flex-row items-center space-x-3">
                <span className="text-white text-sm font-['Rosarivo'] font-medium">
                  {item.companyName !== "Nil" ? item.companyName : "Dreamin"}
                </span>
                <a
                  href={item.buttonLink || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-5 py-2 text-sm border border-gray-300 text-black font-['Rosarivo'] font-medium bg-white rounded-md shadow-md"
                >
                  {item.buttonName || "See More"}
                </a>
              </div>
            </div>
          ))}
        </div>

        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2">
          {carouselItems.map((_, index) => (
            <div
              key={index}
              className={`w-2.5 h-2.5 rounded-full cursor-pointer ${
                index === currentIndex ? "bg-gray-800" : "bg-gray-400"
              }`}
              onClick={() => setCurrentIndex(index)}
            ></div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default Carousel;
