import { api } from "./api";

import { GET_SUBSCRIPTION_LIST, POST_ACTIVE_SUBSCRIPTION } from "./urls";
import { GRAND_ACTIVE_SUBSCRIPTION_LIST } from "./urls";
import { GET_SUBSCRIPTION_COUNT_DETAILS } from "./urls";

const fetchsubscribedUsers = async ({ skip, limit, filter }) => { // Add 'filter' parameter
  try {
    const response = await api.get(GET_SUBSCRIPTION_LIST, {
      params: {
        skip: skip,
        limit: limit,
        filter: filter, 
      },
    });
    console.log(response);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};



export const fetchSubscriptionCountDetails = async () => {
  try {
    const response = await api.get(GET_SUBSCRIPTION_COUNT_DETAILS);
    console.log('API Response:', response.data);

    if (response.status === 200) {
      return response.data.data; 
    } else {
      throw new Error(response.data.message || 'Failed to fetch subscription count details');
    }
  } catch (error) {
    console.error('Error fetching subscription count details:', error.message);
    throw error; // Propagate the error to the calling function
  }
};





const fetchsubscriptionlist = async () => {
    try {
      const response = await api.get(GRAND_ACTIVE_SUBSCRIPTION_LIST);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };


 
  const grantSubscription = async (uid, productId) => {
    const url = POST_ACTIVE_SUBSCRIPTION(uid); // Generate the API URL using the uid
    try {
      const response = await api.post(url, null, {
        params: {
          product_id: productId, // Pass product_id as a query parameter
        },
      });
      console.log('Subscription granted successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error granting subscription:', error.response || error.message);
      throw error;
    }
  };
  

export {fetchsubscribedUsers,fetchsubscriptionlist,grantSubscription}