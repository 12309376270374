import { api } from './api'; // Assuming api is already configured in this file
import { GET_EXECUTIVES, ADD_EXECUTIVES, DELETE_EXECUTIVES, UPDATE_EXECUTIVES } from './urls'; // Ensure you have these URLs defined

// API to fetch executives with pagination and optional filters
const getExecutives = async (skip, limit, email = '', country = '', name = '') => {
  try {
    const res = await api.get(GET_EXECUTIVES, {
      params: {
        skip,       // Pagination: skip number of items
        limit,      // Pagination: number of items to fetch
        email,      // Optional email filter
        country,    // Optional country filter
        name,       // Optional name filter
      },
    });
    return res;
  } catch (error) {
    console.error('Error fetching executives:', error);
    throw error;
  }
};

// API to add a new executive
const addExecutive = async (email, country, name, password) => {
  try {
    const res = await api.post(ADD_EXECUTIVES, {
      email,   
      country, 
      name,    
      password 
    });
    return res;
  } catch (error) {
    console.error('Error adding executive:', error);
    throw error;
  }
};

// API to delete an executive
const deleteExecutive = async (id) => {
  try {
    const res = await api.delete(DELETE_EXECUTIVES, {
      params: {
        id,  
      },
    });
    return res;
  } catch (error) {
    console.error('Error deleting executive:', error);
    throw error;
  }
};
const updateExecutive = async (id, email, country, name, password) => {
  try {
    const res = await api.put(`${UPDATE_EXECUTIVES}?id=${id}`,  { // Pass id in the URL
      email,    // Required field
      country,  // Required field
      name,     // Required field
      password  // Required field
    });
    return res;
  } catch (error) {
    console.error('Error updating executive:', error);
    throw error;
  }
};



export { getExecutives, addExecutive ,deleteExecutive, updateExecutive};

