import { delete_sub_category, post_sub_category, get_sub_category, edit_sub_category } from './sub_categories';
import { showToast } from '../View/Components/toast';

// Fetch subcategories
export const fetchSubcategories = async (setSubcategories, setLoading) => {
  setLoading(true);
  try {
    const response = await get_sub_category();
    if (response.status === 200) {
      setSubcategories(response.data.data);
    } else {
      showToast('Failed to fetch subcategories', 'error');
    }
  } catch (error) {
    showToast('Error fetching subcategories', 'error');
  } finally {
    setLoading(false);
  }
};

// Save a new subcategory
export const saveSubcategory = async (subcategoryName, subcategories, setSubcategories, setLoading, setIsAddModalOpen) => {
  if (!subcategoryName) {
    showToast('Please provide a subcategory name', 'error');
    return;
  }
  
  setLoading(true);
  try {
    const response = await post_sub_category(subcategoryName);
    if (response.status === 201) {
      const newSubcategory = response.data.data;
      setSubcategories([...subcategories, newSubcategory]);
    } else {
      showToast('Failed to add subcategory', 'error');
    }
  } catch (error) {
    showToast('Error adding subcategory', 'error');
  } finally {
    setLoading(false);
    setIsAddModalOpen(false);
  }
};

// Edit an existing subcategory
export const updateSubcategory = async (subcategoryId, newName, subcategories, setSubcategories, setLoading, setIsEditModalOpen) => {
  setLoading(true);
  try {
    const response = await edit_sub_category(subcategoryId, newName);
    if (response.status === 200) {
      const updatedSubcategories = subcategories.map((subcategory) =>
        subcategory.id === subcategoryId ? { ...subcategory, name: newName } : subcategory
      );
      setSubcategories(updatedSubcategories);
    } else {
      showToast('Failed to update subcategory', 'error');
    }
  } catch (error) {
    showToast('Error updating subcategory', 'error');
  } finally {
    setLoading(false);
    setIsEditModalOpen(false);
  }
};

// Delete a subcategory
export const deleteSubcategory = async (subcategoryId, subcategories, setSubcategories, setLoading, setIsDeleteModalOpen) => {
  setLoading(true);
  try {
    const response = await delete_sub_category(subcategoryId);
    if (response.status === 200) {
      const updatedSubcategories = subcategories.filter((subcategory) => subcategory.id !== subcategoryId);
      setSubcategories(updatedSubcategories);
    } else {
      showToast('Failed to delete subcategory', 'error');
    }
  } catch (error) {
    showToast('Error deleting subcategory', 'error');
  } finally {
    setLoading(false);
    setIsDeleteModalOpen(false);
  }
};
