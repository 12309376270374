import axios from "axios";
import BASE_URL from "./urls";


export const getCompanies = async (skip = 0, limit = 10, district = '', latitude = '', longitude = '', category = '', query = '') => {
    try {
      const response = await axios.get(`${BASE_URL}/company`, {
        params: {
          skip,        // Pagination - skip
          limit,       // Pagination - limit
          district,    // Filter by district
          latitude,    // Filter by latitude
          longitude,   // Filter by longitude
          category,    // Filter by category
          query        // Filter by query
        }
      });
      return response.data;  
    } catch (error) {
      console.error("Error fetching companies:", error);
      throw error;
    }
  };