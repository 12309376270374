import React from 'react';

const Tile = ({ name, onDelete, onEdit }) => {
    return (
        <div className="flex justify-between items-center p-4 bg-white border-b border-gray-200">
            <span>{name}</span>
            <div className="flex items-center">
                <button onClick={onEdit} className="text-blue-500 mr-2">
                    <img src="/assets/edit-line.svg" alt="Edit" className="h-6 w-6" />
                </button>
                <button onClick={onDelete} className="text-red-500">
                    <img src="./delete.svg" alt="Delete" className="h-6 w-6" />
                </button>
            </div>
        </div>
    );
};

export default Tile;
