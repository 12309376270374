import React, { useState } from 'react';
import { FaLock, FaUnlock } from 'react-icons/fa';

const PasswordInputField = ({
  placeholder = "Enter password",
  name,
  value,
  onChange,
  inputClassName = '',
  fontFamily = 'font-poppins',
  borderRadius = "1px",
  backgroundColor = "#FFFFFF",
  padding = "12px",
  marginBottom = "auto",
  placeholderColor = '#737373', 
  autoComplete = "new-password",
  textColor = '#737373',
  fontSize = "14px", 
  borderColor = "#DBDBDB",
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <style>
        {`
          .custom-placeholder::placeholder {
            color: ${placeholderColor};
            font-family: 'Poppins', sans-serif;
            font-size: ${fontSize}; 
          }
          .custom-placeholder {
            font-family: 'Poppins', sans-serif;
            font-size: ${fontSize};
          }
        `}
      </style>
      <div className={`w-full mb-2 ${inputClassName}`}>
        <div className="flex items-center" style={{ borderRadius, backgroundColor, padding, marginBottom, border: `1px solid ${borderColor}` }}>
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder={placeholder}
            className={`w-full custom-placeholder ${fontFamily}`} // Add custom-placeholder class
            name={name}
            value={value}
            onChange={onChange}
            autoComplete={autoComplete}
            style={{
              backgroundColor,
              border: 'none',
              outline: 'none',
              color: textColor, 
              fontFamily: 'Poppins, sans-serif',
              fontSize: fontSize 
            }}
          />
          <div className="ml-2 cursor-pointer" onClick={togglePasswordVisibility}>
            {showPassword ? <FaUnlock className="text-[#9F9F9F]" /> : <FaLock className="text-[#9F9F9F]" />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordInputField;


