import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { firebaseConfig } from '../firebaseConfig';
import '../Buisnesscard/Buisnesscard.css';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Buisnesscard = () => {
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOffers = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "homeOffers"));
                const currentDate = new Date(); // Current date

                // Process and filter offers based on date range
                const offersData = querySnapshot.docs.map((doc) => {
                    const data = doc.data();
                    const startDate = data.startDate.toDate(); // Convert Firestore Timestamp to Date
                    const endDate = data.endDate.toDate(); // Convert Firestore Timestamp to Date

                    return {
                        id: doc.id,
                        ...data,
                        isWithinDateRange: currentDate >= startDate && currentDate <= endDate, // Date range validation
                    };
                });

                // Filter out only offers within the date range and marked as valid
                const validOffers = offersData.filter((offer) => offer.isWithinDateRange && offer.isValid);

                setOffers(validOffers);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching offers:", error);
            }
        };

        fetchOffers();
    }, []);

    return (
        <div>
            <div className="offer">
                <p className="offertext">Offers and Recommendations</p>
            </div>
            <div className="desc">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    offers.length > 0 ? (
                        offers.map((offer) => (
                            <div key={offer.id} className="cardesc">
                                <img
                                    src={offer.imageUrl}
                                    alt={offer.companyName || "Offer"}
                                    className="card-desc-image"
                                    onClick={() => window.open(offer.imageUrl, '_blank')}
                                />
                                <p>{offer.companyName}</p>
                            </div>
                        ))
                    ) : (
                        <p>No active offers available.</p>
                    )
                )}
            </div>
        </div>
    );
};

export default Buisnesscard;
