// import React, { useState, useEffect } from 'react';
// import Sidebar from '../Components/sidebar';
// import Header from '../Components/header';
// import { getExecutives } from '../../controller/executives';
// import PopupForm from '../Components/add_executives'; // Import the popup component for adding executives

// const ExecutiveList = () => {
//   const [executives, setExecutives] = useState([]);  // State to store fetched executives
//   const [selectedCountry, setSelectedCountry] = useState('');  // For filtering by country
//   const [dropdownOpen, setDropdownOpen] = useState(false);  // Dropdown for country selection
//   const [loading, setLoading] = useState(true);  // Loading state
//   const [popupOpen, setPopupOpen] = useState(false);  // State to open/close Add Executive Popup
//   const [formValues, setFormValues] = useState({ email: '', password: '', country: '', name: '' }); // Form values state for the popup

//   // Fetch executives on component mount
//   useEffect(() => {
//     fetchExecutives();
//   }, []);

//   const fetchExecutives = async () => {
//     setLoading(true); // Set loading to true before fetching data
//     try {
//       const res = await getExecutives(0, 10);
//       console.log(res); // Log the full response to inspect

//       // Access the actual data array inside res.data.data
//       if (Array.isArray(res.data.data)) {
//         setExecutives(res.data.data);  // Set the correct array of executives
//       } else {
//         console.error('Expected an array but received:', res.data.data);
//         setExecutives([]); // Set an empty array if data is not in the expected format
//       }

//       setLoading(false);  // Set loading to false after data is fetched
//     } catch (error) {
//       console.error('Error fetching executives:', error);
//       setLoading(false);  // Set loading to false in case of an error
//     }
//   };

//   const handleCountrySelect = (country) => {
//     setSelectedCountry(country);
//     setDropdownOpen(false);
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormValues(prevValues => ({
//       ...prevValues,
//       [name]: value
//     }));
//   };

//   const handleFormSubmit = () => {
//     // Handle form submission logic here, e.g., calling an API to add the executive
//     console.log('Form submitted with values:', formValues);
//     // Close the popup after form submission
//     setPopupOpen(false);
//   };

//   const handleEdit = (executiveId) => {
//     // Logic to handle editing an executive
//     console.log('Edit executive with ID:', executiveId);
//   };

//   const handleDelete = (executiveId) => {
//     // Logic to handle deleting an executive
//     console.log('Delete executive with ID:', executiveId);
//   };

//   const filteredData = selectedCountry
//     ? executives.filter(executive => executive.country === selectedCountry)
//     : executives;

//   return (
//     <>
//       <Header />
//       <div className="flex h-screen bg-[#F3F3F3]">
//         <Sidebar />
//         <div className="flex-1 flex flex-col overflow-hidden">
//           <main className="flex-1 overflow-x-hidden overflow-y-auto p-6">
//             <div className="mt-6">
//               <div className="flex justify-between items-center mb-6">
//                 <h1 className="text-xl font-semibold text-[#545454]">Executives List</h1>
//                 {/* Add Button */}
//                 <button onClick={() => setPopupOpen(true)} className="bg-[#DB0000] text-white px-4 py-2 rounded">+ Add</button>
//               </div>
//               <div className="bg-white shadow-md rounded-lg overflow-hidden">
//                 {loading ? (
//                   <div className="p-6 text-center">Loading...</div>  
//                 ) : (
//                   <table className="min-w-full divide-y divide-gray-200">
//                     <thead className="bg-gray-50">
//                       <tr>
//                         <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sl No</th>
//                         <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email ID</th>
//                         <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                           <div className="flex items-center relative">
//                             Country
//                             <button
//                               onClick={() => setDropdownOpen(!dropdownOpen)}
//                               className="ml-2 border border-gray-300 rounded-md text-sm p-1 bg-white"
//                             >
//                               <svg
//                                 className={`w-4 h-4 transform transition-transform duration-200 ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`}
//                                 fill="none"
//                                 stroke="currentColor"
//                                 viewBox="0 0 24 24"
//                                 xmlns="http://www.w3.org/2000/svg"
//                               >
//                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
//                               </svg>
//                             </button>
//                             {dropdownOpen && (
//                               <div className="absolute top-full mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
//                                 <button onClick={() => handleCountrySelect('')} className="block w-full text-left px-4 py-2 text-sm text-[#232323] hover:bg-[#FFEFEF]">All</button>
//                                 <button onClick={() => handleCountrySelect('Germany')} className="block w-full text-left px-4 py-2 text-sm text-[#232323] hover:bg-[#FFEFEF]">Germany</button>
//                                 <button onClick={() => handleCountrySelect('Canada')} className="block w-full text-left px-4 py-2 text-sm text-[#232323] hover:bg-[#FFEFEF]">Canada</button>
//                                 <button onClick={() => handleCountrySelect('India')} className="block w-full text-left px-4 py-2 text-sm text-[#232323] hover:bg-[#FFEFEF]">India</button>
//                                 <button onClick={() => handleCountrySelect('London')} className="block w-full text-left px-4 py-2 text-sm text-[#232323] hover:bg-[#FFEFEF]">London</button>
//                               </div>
//                             )}
//                           </div>
//                         </th>
//                         <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
//                         <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th> {/* New Actions Column */}
//                       </tr>
//                     </thead>
//                     <tbody className="bg-white divide-y divide-gray-200">
//                       {filteredData.map((executive, index) => (
//                         <tr key={index}>
//                           <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
//                           <td className="px-6 py-4 whitespace-nowrap">{executive.email}</td>
//                           <td className="px-6 py-4 whitespace-nowrap">{executive.country}</td>
//                           <td className="px-6 py-4 whitespace-nowrap">{executive.name}</td>
//                           <td className="px-6 py-4 whitespace-nowrap">
//                             {/* Edit and Delete icons */}
//                             <button onClick={() => handleEdit(executive.id)} className="mr-2">
//                               <img src="/Assets/edit-line.svg" alt="Edit" />
//                             </button>
//                             <button onClick={() => handleDelete(executive.id)}>
//                               <img src="./delete.svg" alt="Delete" />
//                             </button>
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 )}
//               </div>
//             </div>
//           </main>
//         </div>
//       </div>

//       {/* Popup for Adding Executives */}
//       {popupOpen && (
//         <PopupForm
//           formValues={formValues} // State for form values
//           handleInputChange={handleInputChange} // Function to update form values
//           closePopup={() => setPopupOpen(false)} // Close the popup
//           fetchExecutives={fetchExecutives} // Refresh the list after adding
//         />
//       )}

//     </>
//   );
// };

// export default ExecutiveList;

import React, { useState, useEffect } from 'react';
import Sidebar from '../Components/sidebar';
import Header from '../Components/header';
import PopupForm from '../Components/add_executives';
import Modal from '../Components/pop_up';
import { FaSpinner } from 'react-icons/fa'; 
import { showToast } from '../Components/toast';
import { fetchExecutives,addNewExecutive,removeExecutive } from '../../controller/executives_controller';

const ExecutiveList = () => {
  const [executives, setExecutives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [formValues, setFormValues] = useState({ id: '', email: '', password: '', country: '', name: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [page, setPage] = useState(0);
  const limit = 10;
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  useEffect(() => {
    fetchExecutiveData(page);
  }, [page]);

  const fetchExecutiveData = async (currentPage) => {
    setLoading(true);
    try {
      const res = await fetchExecutives(currentPage * limit, limit);
      console.log('API Response - Get Executives:', res);
      if (Array.isArray(res.data)) {
        if (currentPage === 0) {
          setExecutives(res.data);
        } else {
          setExecutives((prevExecutives) => [...prevExecutives, ...res.data]);
        }
      } else {
        console.error('Expected an array but received:', res.data);
        setExecutives([]);
      }
    } catch (error) {
      console.error('Error fetching executives:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleAddExecutive = async () => {
    try {
      const response = await addNewExecutive(formValues.email, formValues.country, formValues.name, formValues.password);
      console.log('API Response - Add Executive:', response);
      fetchExecutiveData(0);
      setPopupOpen(false);
    } catch (error) {
      console.error('Error adding executive:', error);
    }
  };

  const handleDeleteClick = (executiveId) => {
    setDeleteId(executiveId);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!deleteId) return;

    try {
      const res = await removeExecutive(deleteId);
      console.log('API Response - Delete Executive:', res);
      fetchExecutiveData(0);
      setIsModalOpen(false);
      setDeleteId(null);
      showToast('Executive deleted successfully!', 'success');
    } catch (error) {
      console.error('Error deleting executive with ID:', deleteId, error);
      showToast('Error deleting executive', 'error');
    }
  };

  const fetchMoreExecutives = async () => {
    setIsFetchingMore(true);
    try {
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error('Error fetching more executives:', error);
    } finally {
      setIsFetchingMore(false);
    }
  };

  return (
    <>
      <Header />
      <div className="flex h-screen bg-[#F3F3F3] font-poppins">
        <Sidebar />
        <div className="flex-1 flex flex-col overflow-hidden">
          <main className="flex-1 overflow-x-hidden overflow-y-auto p-6">
            <div className="mt-6">
              <div className="flex justify-between items-center mb-6">
                <h1 className="text-xl font-semibold text-[#545454]">Executives List</h1>
                <button
                  onClick={() => {
                    setFormValues({ email: '', password: '', country: '', name: '' });
                    setPopupOpen(true);
                  }}
                  className="bg-[#DB0000] text-white px-4 py-2 rounded"
                >
                  + Add
                </button>
              </div>

              <div className="bg-[#FFFF] shadow-md rounded-lg overflow-hidden">
                {loading && page === 0 ? (
                  <div className="flex items-center justify-center p-6">
                    <FaSpinner className="animate-spin text-4xl text-gray-500" />
                  </div>
                ) : (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-center  font-semibold text-[#292D32]">Sl No</th>
                        <th className="px-6 py-3 text-center  font-semibold text-[#292D32]">Email ID</th>
                        <th className="px-6 py-3 text-center  font-semibold text-[#292D32]">Country</th>
                        <th className="px-6 py-3 text-center  font-semibold text-[#292D32]">Name</th>
                        <th className="px-6 py-3 text-center  font-semibold text-[#292D32]">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="bg-[#FFFF] divide-y divide-gray-200">
                      {executives.map((executive, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 text-center whitespace-nowrap">{index + 1}</td>
                          <td className="px-6 py-4 text-center whitespace-nowrap">{executive.email}</td>
                          <td className="px-6 py-4 text-center whitespace-nowrap">{executive.country}</td>
                          <td className="px-6 py-4 text-center whitespace-nowrap">{executive.name}</td>
                          <td className="px-6 py-4 text-center whitespace-nowrap">
                            <button onClick={() => handleDeleteClick(executive.id)}>
                              <img src="./delete.svg" alt="Delete" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                {!loading && !isFetchingMore && executives.length >= (page + 1) * limit && (
                  <div className="flex justify-center mt-4">
                    <button
                      onClick={fetchMoreExecutives}
                      className="px-4 py-2 bg-[#DB0000] text-white rounded"
                    >
                      Load More
                    </button>
                  </div>
                )}

                {isFetchingMore && (
                  <div className="flex justify-center mt-4">
                    <FaSpinner className="animate-spin text-4xl text-gray-500" />
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>

      {popupOpen && (
        <PopupForm
          formValues={formValues}
          handleInputChange={handleInputChange}
          closePopup={() => setPopupOpen(false)}
          handleFormSubmit={handleAddExecutive}
        />
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmDelete}
        text="Are you sure you want to delete this Executive?"
      />
    </>
  );
};

export default ExecutiveList;