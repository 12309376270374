import React, { useState } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { addCategory } from "../../controller/catagory_dreamin";
import PhotoUpload from "./photo_upload";
import { FaSpinner } from "react-icons/fa";

const AddCategoryModal = ({ onClose, onCategoryAdded }) => {
  const [categoryName, setCategoryName] = useState("");
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const storage = getStorage();

  const handleSave = async () => {
    if (!categoryName.trim() || files.length === 0) {
      setError("Both category name and image are required.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Upload the image to Firebase Storage
      const file = files[0].file;
      const storageRef = ref(storage, `categories/${file.name}`);
      await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(storageRef);

      // Call the API to add the category
      const response = await addCategory({ categoryName, imageUrl });
      console.log("Category added successfully:", response);

      // Notify the parent component
      onCategoryAdded();
      onClose();
    } catch (error) {
      console.error("Error adding category:", error);
      setError("Failed to add category. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-6 w-full max-w-lg relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          ✕
        </button>
        <h2 className="text-xl font-semibold mb-4">Add Category</h2>
        {error && <p className="text-red-500">{error}</p>}
        <div className="mb-4">
          <label htmlFor="categoryName" className="block text-gray-700 font-medium mb-2">
            Category Name
          </label>
          <input
            type="text"
            id="categoryName"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            className="w-full border border-gray-300 rounded px-3 py-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Category Image</label>
          <PhotoUpload
            files={files}
            setFiles={setFiles}
            svgImagePath="/assets/grey_plus.svg"
            editIconPath="/assets/edit-icon.svg"
            bgColor="#F3F3F3"
            borderRadius="8px"
            width="130px"
            height="190px"
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-[#222222] text-white rounded hover:bg-blue-600"
            disabled={isLoading}
          >
            {isLoading ? <FaSpinner className="animate-spin" /> : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCategoryModal;
