import React, { useState, useEffect } from "react";
import { editCarousel, fetchCarouselById } from "../../controller/curousal";
import { FaTimes } from "react-icons/fa";
import PhotoUpload from "./photo_upload";

const EditCurousalModal = ({ id, onClose, onRefresh }) => {
    const [formData, setFormData] = useState({
        button_link: "",
        button_name: "",
        company_name: "",
        end_date: "",
        image_url: "",
        start_date: "",
        title: "",
        is_valid: true
    });

    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        const loadCurousalDetails = async () => {
            try {
                const response = await fetchCarouselById(id);
                if (response.data) {
                    setFormData(response.data);
                    setFiles([{ url: response.data.image_url }]);
                }
            } catch (error) {
                console.error("Error fetching curousal details for edit:", error);
            }
        };

        if (id) {
            loadCurousalDetails();
        }
    }, [id]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const updatedData = {
                ...formData,
                image_url: files.length > 0 ? files[0].url : formData.image_url
            };
            await editCarousel(id, updatedData);
            alert("Carousel updated successfully!");
            onClose();
            onRefresh();
        } catch (error) {
            console.error("Error updating carousel:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white p-6 rounded-lg w-[500px] relative">
                <button onClick={onClose} className="absolute top-2 right-2 text-gray-600 hover:text-gray-900">
                    <FaTimes size={20} />
                </button>
                <h2 className="text-xl font-semibold mb-4 text-center">Edit Carousel</h2>
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Title</label>
                            <input type="text" name="title" value={formData.title} onChange={handleChange} className="border rounded w-full p-2" required />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Company Name</label>
                            <input type="text" name="company_name" value={formData.company_name} onChange={handleChange} className="border rounded w-full p-2" required />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Button Name</label>
                            <input type="text" name="button_name" value={formData.button_name} onChange={handleChange} className="border rounded w-full p-2" required />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Button Link</label>
                            <input type="text" name="button_link" value={formData.button_link} onChange={handleChange} className="border rounded w-full p-2" required />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Start Date</label>
                            <input type="date" name="start_date" value={formData.start_date} onChange={handleChange} className="border rounded w-full p-2" required />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">End Date</label>
                            <input type="date" name="end_date" value={formData.end_date} onChange={handleChange} className="border rounded w-full p-2" required />
                        </div>
                    </div>
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Image</label>
                        <PhotoUpload files={files} setFiles={setFiles} />
                    </div>
                    <button type="submit" className="bg-[#222222] text-white w-full py-2 rounded-md mt-4" disabled={loading}>
                        {loading ? "Updating..." : "Update Carousel"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default EditCurousalModal;
