import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const showToast = (message, type = "info") => {
  toast(message, {
    type,
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: "bg-black text-white rounded-lg shadow-lg", // Black background and white text
    bodyClassName: "text-sm",
    progressClassName: "bg-white",
    icon: false, // Disable the default icon
  });
};

const Toast = () => {
  return <ToastContainer />;
};

export { Toast, showToast };
