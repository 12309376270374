import React, { useState } from 'react';
import PhotoUpload from '../Components/photo_upload';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { editCategoryById } from '../../controller/catagory_dreamin';

const storage = getStorage(); // Initialize Firebase storage

const EditCategoryModal = ({ categoryId, categoryName, imageUrl, onClose, onSave }) => {
    const [newCategoryName, setNewCategoryName] = useState(categoryName || '');
    const [files, setFiles] = useState(imageUrl ? [{ url: imageUrl }] : []);
    const [isSaving, setIsSaving] = useState(false);

    const handleSave = async () => {
        console.log("Saving category with ID:", categoryId); // Debugging log
        if (!categoryId) {
            console.error("Error: categoryId is undefined!");
            return;
        }
    
        setIsSaving(true);
        try {
            let uploadedImageUrl = imageUrl;
    
            if (files.length > 0 && files[0].file) {
                const storageRef = ref(storage, `categories/${files[0].file.name}`);
                await uploadBytes(storageRef, files[0].file);
                uploadedImageUrl = await getDownloadURL(storageRef);
            }
    
            const updatedCategory = {
                categoryName: newCategoryName,
                imageUrl: uploadedImageUrl,
            };
    
            console.log('Updating Category:', updatedCategory);
    
            // Call the API to update the category
            await editCategoryById(categoryId, updatedCategory);
            console.log('Category updated successfully!');
    
            // Notify the parent component
            onSave(updatedCategory);
        } catch (error) {
            console.error('Error updating category:', error);
        } finally {
            setIsSaving(false);
        }
    };
    
    
    return (
        <div
            className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
            onClick={onClose}
        >
            <div
                className="bg-white rounded-lg p-6 w-full max-w-lg relative"
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                    onClick={onClose}
                >
                    ✕
                </button>
                <h2 className="text-xl font-semibold mb-4">Edit Category</h2>
                <div className="mb-4">
                    <label htmlFor="categoryName" className="block text-gray-700 font-medium mb-2">
                        Category Name
                    </label>
                    <input
                        type="text"
                        id="categoryName"
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                        className="w-full border border-gray-300 rounded px-3 py-2"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2">Category Image</label>
                    <PhotoUpload
                        files={files}
                        setFiles={setFiles}
                        svgImagePath="/assets/upload-icon.svg"
                        editIconPath="/assets/edit-icon.svg"
                        bgColor="#F3F3F3"
                        borderRadius="8px"
                        width="130px"
                        height="190px"
                    />
                </div>
                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSave}
                        className="px-4 py-2 bg-[#222222] text-white rounded hover:bg-[#222222]"
                        disabled={isSaving}
                    >
                        {isSaving ? 'Saving...' : 'Save'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditCategoryModal;
