import React from "react";
import { ClipLoader } from "react-spinners";

const RingSpinner = ({
  visible = true, // Controls visibility
  size = 60, // Diameter of the spinner
  color = "#00FF00", // Default color (green as in the image)
  wrapperClass = "", // Additional Tailwind CSS classes for styling
}) => {
  return (
    <div className={`flex justify-center items-center ${visible ? "" : "hidden"} ${wrapperClass}`}>
      <ClipLoader size={size} color={color} loading={visible} />
    </div>
  );
};

export default RingSpinner;
