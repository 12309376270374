import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdMenu } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import LoginAlert from './login_alert_homepage';

const Hamburger = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("Please login to continue");
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const isLoggedIn = localStorage.getItem('customerUid') !== null;
  const isHomePage = location.pathname === '/';

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  const handleNavigation = (path) => {
    if (!isLoggedIn) {
      setShowAlert(true);
    } else {
      navigate(path);
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const largeSizeCheck = window.innerWidth >= 768;
      setIsLargeScreen(largeSizeCheck);

      if (!isHomePage && largeSizeCheck) {
        setIsMenuOpen(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    
    return () => window.removeEventListener("resize", handleResize);
  }, [isHomePage]);

  const buttonClass = (path) => `
    flex items-center w-full px-4 py-3 rounded-md text-white 
    ${location.pathname === path ? "bg-gray-800" : "hover:bg-gray-700"} 
    transition-all duration-200
  `;

  // Show close button only when menu is open on homepage or on small screens
  const shouldShowCloseButton = isMenuOpen && (isHomePage || !isLargeScreen);

  return (
    <>
      {/* Hamburger Button */}
      <div className={`fixed top-3 right-2 lg:top-7 lg:right-12 z-[2999] ${isHomePage || !isLargeScreen ? 'block' : 'hidden'}`}>
        <button 
          onClick={toggleMenu} 
          className="text-black text-3xl p-2"
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        >
          <MdMenu />
        </button>
      </div>

      {/* Sidebar Menu */}
      <nav
        className={`fixed top-0 right-0 h-full w-[235px] bg-black shadow-lg transform 
          transition-transform duration-300 ease-in-out z-[1999]
          ${isMenuOpen || (!isHomePage && isLargeScreen) ? 'translate-x-0' : 'translate-x-full'}`}
        aria-hidden={!isMenuOpen}
      >
        {/* Close Button - Only show when appropriate */}
        {shouldShowCloseButton && (
          <button 
            onClick={toggleMenu} 
            className="absolute top-1 right-2 text-white text-2xl p-1 border-none outline-none focus:outline-none focus:ring-0"
            aria-label="Close menu"
          >
            <AiOutlineClose />
          </button>
        )}

        {/* Navigation Buttons */}
        <div className="mt-10 space-y-2 px-6">
          <button className={buttonClass('/')} onClick={() => handleNavigation('/')}>
            <span className="lg:text-[18px] text-sm">Home</span>
            <img src="/home-dreamin.svg" alt="Home Icon" className="w-6 h-6 ml-auto nav-icon" />
          </button>

          <button className={buttonClass('/profile')} onClick={() => handleNavigation('/profile')}>
            <span className="lg:text-[18px] text-sm">Profile</span>
            <img src="/dreamin-bookmarks.svg" alt="Profile Icon" className="w-6 h-6 ml-auto nav-icon" />
          </button>

          <button className={buttonClass('/savedpage')} onClick={() => handleNavigation('/savedpage')}>
            <span className="lg:text-[18px] text-sm">Bookmarks</span>
            <img src="/profile-dreaminsvg.svg" alt="Bookmarks Icon" className="w-6 h-6 ml-auto nav-icon" />
          </button>
        </div>
      </nav>

      {/* Login Alert */}
      {showAlert && <LoginAlert message={message} onClose={handleAlertClose} />}
    </>
  );
};

export default Hamburger;