import { api } from "./api";
import { GET_PROFILE_REPORTS_LIST } from "./urls";
import { GET_DETAILED_PROFILE_REPORT } from "./urls";
import { GET_POST_REPORTS_LIST } from "./urls";

const getReports = async ({ skip = 0, limit = 10 }) => {
    try {
      const response = await api.get(GET_PROFILE_REPORTS_LIST, {
        params: { skip, limit },
      });
      
      // Ensure we're returning the correct data structure
      const events = response.data.data || [];
      return events;
      
    } catch (error) {
      console.error('Error fetching events:', error);
      throw error;
    }
  };
  const getPostReports = async ({ skip = 0, limit = 10 }) => {
    try {
      const response = await api.get(GET_POST_REPORTS_LIST, {
        params: { skip, limit },
      });
      
      // Ensure we're returning the correct data structure
      const events = response.data.data || [];
      return events;
      
    } catch (error) {
      console.error('Error fetching events:', error);
      throw error;
    }
  };

  
export {getReports,getPostReports}