import React from 'react';
import Modal from 'react-modal';
import { AiOutlineClose } from 'react-icons/ai';

const AlertComponent = ({ isModalOpen, closeModal, reportData }) => {
  if (!reportData) {
    return null; // If no data is passed, render nothing
  }

  const {
    reporterName,
    reporterProfileImage,
    reportedToName,
    reportedToProfileImage,
    reportingDate,
    issueDescription,
    reporterEmail,
  } = reportData;

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Issue Details Modal"
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75"
      overlayClassName="Overlay"
    >
      <div className="relative bg-white rounded-lg p-6 max-w-lg w-full font-poppins">
        <button
          onClick={closeModal}
          className="absolute top-4 right-4 text-black text-xl"
        >
          <AiOutlineClose className="text-gray-700" />
        </button>
        <h2 className="text-lg font-semibold mb-4 pb-2 border-b border-gray-300">
          Issue Details
        </h2>
        <div className="flex flex-col space-y-4">
          {/* Reporter and Reporting To Information */}
          <div className="flex justify-between items-center">
            {/* Reported By */}
            <div className="flex items-center space-x-4">
              <img
                src={reporterProfileImage || '/default-profile.png'}
                alt={`${reporterName}'s profile`}
                className="w-12 h-12 rounded-full"
              />
              <div>
                <p className="text-sm font-semibold">Reported By</p>
                <p className="text-sm text-gray-600">{reporterName}</p>
              </div>
            </div>

            {/* Reported To */}
            <div className="flex items-center space-x-4">
              <img
                src={reportedToProfileImage || '/default-profile.png'}
                alt={`${reportedToName}'s profile`}
                className="w-12 h-12 rounded-full"
              />
              <div>
                <p className="text-sm font-semibold">Reported To</p>
                <p className="text-sm text-gray-600">{reportedToName}</p>
              </div>
            </div>
          </div>

          {/* Reporting Date */}
          <div>
            <p className="text-sm font-semibold">Reporting Date</p>
            <p className="text-sm text-gray-600">
              {reportingDate
                ? new Date(reportingDate).toLocaleDateString()
                : 'Unknown Date'}
            </p>
          </div>

          {/* Issue Description */}
          <div>
            <p className="text-sm font-semibold">Issue</p>
            <p className="text-sm text-gray-600">{issueDescription}</p>
          </div>

          {/* Reporter Email */}
         
        </div>
      </div>
    </Modal>
  );
};

export default AlertComponent;
