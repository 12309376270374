import React, { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { getFirestore, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getDoc } from "firebase/firestore";
import RingSpinner from "../loaderspinner";
import { Helmet } from "react-helmet-async";
const db = getFirestore();
const storage = getStorage();

const Userinput = () => {
    const navigate = useNavigate();
    const { customerUid } = useParams();

    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [profileImage, setProfileImage] = useState(null);
    const [profileImageUrl, setProfileImageUrl] = useState("");

    const [loading, setLoading] = useState(false);
    const [imageUploading, setImageUploading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfileImage(file);
            setImageUploading(true);

            try {
                const storageRef = ref(storage, `customerImages/${customerUid}`);
                await uploadBytes(storageRef, file);
                const uploadedImageUrl = await getDownloadURL(storageRef);
                setProfileImageUrl(uploadedImageUrl);
            } catch (error) {
                console.error("Error uploading image:", error);
            } finally {
                setImageUploading(false);
            }
        }
    };

    const validateInputs = () => {
        const newErrors = {};
        if (!profileImage) newErrors.profileImage = "Profile image is required.";
        if (!name.trim()) newErrors.name = "Full Name is required.";
        if (!phoneNumber.trim() || phoneNumber.length !== 10)
            newErrors.phoneNumber = "Mobile Number must be 10 digits.";
        if (!dateOfBirth.trim()) newErrors.dateOfBirth = "Date of Birth is required.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateInputs()) {
            console.error("Validation failed. Check input fields.");
            return;
        }

        setLoading(true);
        try {
            let uploadedImageUrl = profileImageUrl;

            if (profileImage) {
                const storageRef = ref(storage, `customerImages/${customerUid}`);
                await uploadBytes(storageRef, profileImage);
                uploadedImageUrl = await getDownloadURL(storageRef);
            }

            const userDocRef = doc(db, "customer", customerUid);
            const userDocSnap = await getDoc(userDocRef);
            const customerId = userDocSnap.exists() && userDocSnap.data().customerId
                ? userDocSnap.data().customerId
                : Math.random().toString(36).substr(2, 10).toUpperCase();

            await setDoc(userDocRef, {
                name,
                phoneNumber,
                dateOfBirth,
                imageUrl: uploadedImageUrl,
                lastSeen: serverTimestamp(),
                customerId,
            }, { merge: true });

            setLoading(false);
            navigate('/');
        } catch (error) {
            setLoading(false);
            console.error("Error saving user data:", error);
        }
    };

    return (

          <>
                <Helmet>
                    <title>Homepage</title>
                    <meta name="description" content="Event Management Services" />
                    <link rel="canonical" href="/" />
        
                </Helmet>
        <div className="max-w-sm mx-auto p-6  lg:mt-7  rounded-md flex flex-col items-center space-y-4">
            <h2 className="text-lg font-semibold font-inter">Name and Picture</h2>
            <div className="flex flex-col items-center">
                <label htmlFor="profileImageInput" className="cursor-pointer">
                    {imageUploading ? (
                        <RingSpinner visible={true} size={40} color="#000" />
                    ) : (
                        <img
                            src="/user-circle-2.svg"
                            alt="Profile"
                            className="w-28 h-28 rounded-full bg-gray-200"
                        />
                    )}
                </label>
                <input
                    type="file"
                    id="profileImageInput"
                    accept="image/*"
                    className="hidden"
                    onChange={handleFileChange}
                />
                {errors.profileImage && <p className="text-red-500 text-sm font-inter">{errors.profileImage}</p>}
            </div>
            <div className="w-full space-y-2"> 
                <label className="block text-gray-600 font-inter">Full Name</label>
                <input
                    placeholder="Full Name"
                    className="w-full p-3 bg-gray-200 rounded-lg text-gray-800"
                    type="text"
                    value={name}
                    maxLength={30}
                    onChange={(e) => setName(e.target.value)}
                />
                {errors.name && <p className="text-red-500 text-sm font-inter">{errors.name}</p>}
                <label className="block text-gray-600 font-inter">Mobile Number</label>
                <input
                    placeholder="XXXX XXX XXX"
                    className="w-full p-3 bg-gray-200 rounded-lg text-gray-800"
                    type="number"
                    value={phoneNumber}
                    maxLength={10}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                {errors.phoneNumber && <p className="text-red-500 text-sm font-inter">{errors.phoneNumber}</p>}
                <label className="block text-gray-600 font-inter">Date of Birth</label>
                <input
                    placeholder="DD/MM/YYYY"
                    className="w-full p-3 bg-gray-200 rounded-lg text-gray-800"
                    type="date"
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                />
                {errors.dateOfBirth && <p className="text-red-500 text-sm font-inter" >{errors.dateOfBirth}</p>}
            </div>
            <button
                type="submit"
                className="mt-4 w-full bg-black text-white py-3 rounded-full text-lg"
                onClick={handleSubmit}
                disabled={loading}
            >
                {loading ? (
                    <RingSpinner visible={true} size={10} color="#FFFFFF" />
                ) : (
                    "Next"
                )}
            </button>
        </div>
        </>
    );
};

export default Userinput;
