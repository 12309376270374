import React, { useState, useEffect } from 'react';
import LoaderSpinner from '../Components/loader_spinner'; // Assuming you have a loader spinner component

const AddSubcategoryModal = ({ isOpen, onClose, onAdd, initialName = '', isEditMode = false }) => {
    const [subcategoryName, setSubcategoryName] = useState('');
    const [loading, setLoading] = useState(false); // Loading state

    useEffect(() => {
        if (isEditMode && initialName) {
            setSubcategoryName(initialName);
        } else {
            setSubcategoryName('');
        }
    }, [isEditMode, initialName]);

    const handleSubmit = async () => {
        if (subcategoryName.trim()) {
            setLoading(true); // Start loading
            try {
                await onAdd(subcategoryName); // Wait for the onAdd function to complete
                setSubcategoryName(''); // Clear the input
            } catch (error) {
                console.error('Error adding subcategory:', error);
            } finally {
                setLoading(false); // Stop loading after completion
            }
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-xl shadow-lg max-w-md w-full">
                <div className="flex justify-end">
                    <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="text-center mb-6">
                    <h2 className="text-lg font-semibold mb-2 font-poppins">
                        {isEditMode ? 'Edit Subcategory' : 'Subcategory name'}
                    </h2>
                    <input
                        type="text"
                        placeholder="Enter subcategory name"
                        value={subcategoryName}
                        onChange={(e) => setSubcategoryName(e.target.value)}
                        className="border border-gray-300 p-2 w-full rounded mt-4"
                    />
                </div>
                <div className="flex justify-center">
                    <button
                        onClick={handleSubmit}
                        className="bg-[#DB0000] text-white px-4 py-2 rounded text-lg flex items-center justify-center"
                        disabled={loading} // Disable button when loading
                    >
                        {loading ? <LoaderSpinner visible={loading} /> : 'Submit'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddSubcategoryModal;
