import React, { useState, useEffect } from "react";
import { getFirestore, collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firebaseConfig } from "../firebaseConfig";
import RingSpinner from "../loaderspinner";
import { showToast } from "../toast";
import { Toast } from "../toast";
import { FaChevronLeft } from "react-icons/fa"; // Importing the back arrow icon
import Hamburger from "../Hamburger/Hamburger";
import { useNavigate } from "react-router-dom"; // Importing useNavigate hook

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

const EditProfile = () => {
  const [profileData, setProfileData] = useState({
    name: "",
    phoneNumber: "",
    dateOfBirth: "",
    imageUrl: "",
  });
  const [saving, setSaving] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);

  const customerUid = localStorage.getItem("customerUid");
  const navigate = useNavigate(); // Initialize useNavigate hook

  // Fetch profile data
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const customersCollection = collection(db, "customer");
        const q = query(customersCollection, where("customerUid", "==", customerUid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const customerDoc = querySnapshot.docs[0];
          setProfileData(customerDoc.data());
        } else {
          showToast("No profile data found!", "warning");
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
        showToast("Error fetching profile data.", "error");
      }
    };

    if (customerUid) {
      fetchProfileData();
    }
  }, [customerUid]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle profile image upload to Firebase Storage
  const handleImageUpload = async (e) => {
    setImageUploading(true);
    const file = e.target.files[0];

    if (file) {
      try {
        const storageRef = ref(storage, `profileImages/${customerUid}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);

        setProfileData((prevData) => ({
          ...prevData,
          imageUrl: downloadURL,
        }));

        const customersCollection = collection(db, "customer");
        const q = query(customersCollection, where("customerUid", "==", customerUid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const customerDocRef = querySnapshot.docs[0].ref;
          await updateDoc(customerDocRef, { imageUrl: downloadURL });
          showToast("Image uploaded successfully!", "success");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        showToast("Error uploading image.", "error");
      }
      setImageUploading(false);
    }
  };

  // Save updated profile data
  const handleSave = async () => {
    setSaving(true);
    try {
      const customersCollection = collection(db, "customer");
      const q = query(customersCollection, where("customerUid", "==", customerUid));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const customerDocRef = querySnapshot.docs[0].ref;
        await updateDoc(customerDocRef, profileData);
        showToast("Profile updated successfully!", "success");
  
        // Wait for the toast to show before navigating
        setTimeout(() => {
          navigate("/profile"); // Navigate after toast
        }, 2000); // Adjust the delay (2000ms = 2 seconds)
      } else {
        showToast("No matching document found!", "warning");
      }
    } catch (error) {
      console.error("Error saving profile data:", error);
      showToast("Error saving profile data.", "error");
    }
    setSaving(false);
  };
  

  // Navigate back
  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <div className="h-screen flex flex-col items-center justify-start">
      {/* Toast Container */}
      <Toast />

      {/* Header Section */}
      <div className="p-2 flex items-center justify-center sm:mb-12 lg:mt-3 sm:mt-2">
        <button
          onClick={handleBackClick}
          className="text-gray-600 text-xl p-2 cursor-pointer absolute left-4"
          aria-label="Go back"
        >
          <FaChevronLeft />
        </button>
        <h2 className="lg:text-2xl sm:text-xl font-bold text-center font-inter lg:mt-8 sm:mt-18">Name and Picture</h2>
        <Hamburger /> {/* Hamburger added to the header */}
      </div>

      {/* Form Container */}
      <div className="w-[88%] max-w-xl space-y-8 text-center rounded-lg">
        {/* Profile Picture */}
        <div className="flex justify-center relative mt-4">
          <label htmlFor="profileImageInput" className="cursor-pointer">
            {imageUploading ? (
              <div className="w-32 h-32 rounded-full bg-black flex items-center justify-center">
                <RingSpinner visible={imageUploading} size={24} color="#FFFFFF" />
              </div>
            ) : (
              <img
                src={profileData.imageUrl || "https://via.placeholder.com/150"}
                alt="Profile"
                className="w-28 h-28 rounded-full object-cover"
              />
            )}
          </label>
          <input
            id="profileImageInput"
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="hidden"
          />
        </div>

        {/* Name Field */}
        <div className="relative text-left">
          <label className="block text-sm text-gray-600 mb-1">Name</label>
          <input
            type="text"
            name="name"
            value={profileData.name}
            maxLength={30}
            onChange={handleChange}
            className="w-full p-3 rounded-md bg-gray-200 text-gray-800"
            placeholder="Enter your name"
          />
        </div>

        {/* Mobile Number Field */}
        <div className="relative text-left">
          <label className="block text-sm text-gray-600 mb-1">Mobile Number</label>
          <input
            type="text"
            name="phoneNumber"
            value={profileData.phoneNumber}
            maxLength={10}
            onChange={handleChange}
            className="w-full p-3 rounded-md bg-gray-200 text-gray-800"
            placeholder="Enter your mobile number"
          />
        </div>

        {/* Date of Birth Field */}
        <div className="relative text-left">
          <label className="block text-sm text-gray-600 mb-1">Date of Birth</label>
          <input
            type="date"
            name="dateOfBirth"
            value={profileData.dateOfBirth}
            onChange={handleChange}
            className="w-full p-3 rounded-md bg-gray-200 text-gray-800"
          />
        </div>

        {/* Save Button */}
        <button
          onClick={handleSave}
          disabled={saving}
          className="w-full bg-black text-white py-3 rounded-md flex items-center justify-center"
        >
          {saving ? (
            <RingSpinner visible={saving} size={24} color="#FFFFFF" />
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
