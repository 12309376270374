import { api } from "./api";
import { GET_COMPANY_LIST, GET_COMPANY_BY_ID ,DELETE_COMPANY_BY_ID,EDIT_COMPANY_BY_ID , VERIFY_COMPANY_BY_ID} from "./urls";

// API to fetch company list with pagination and optional filters
const fetchCompanyList = async ({ skip, limit, category, query }) => {
    try {
        const params = { skip, limit };
        if (category) params.category = category;
        if (query) params.query = query; // Optional search query

        const response = await api.get(GET_COMPANY_LIST, { params });
        console.log("Company List API Response:", response);
        return response.data;
    } catch (error) {
        console.error("Error fetching company list:", error);
        throw error;
    }
};

// API to fetch company details by ID
const getCompanyById = async (id) => {
    try {
        const response = await api.get(GET_COMPANY_BY_ID(id));
        console.log("Get Company by ID API Response:", response);
        return response.data;
    } catch (error) {
        console.error(`Error fetching company with ID ${id}:`, error);
        throw error;
    }
};

// API to edit company details by ID
const editCompanyById = async (id, updatedData) => {
    try {
        const response = await api.put(EDIT_COMPANY_BY_ID(id), updatedData);
        console.log(`Edit Company by ID API Response for ID ${id}:`, response);
        return response.data;
    } catch (error) {
        console.error(`Error editing company with ID ${id}:`, error);
        throw error;
    }
};

// API to delete company by ID
const deleteCompanyById = async (id) => {
    try {
        const response = await api.delete(DELETE_COMPANY_BY_ID(id));
        console.log(`Delete Company by ID API Response for ID ${id}:`, response);
        return response.data;
    } catch (error) {
        console.error(`Error deleting company with ID ${id}:`, error);
        throw error;
    }
};


const verifyCompanyById = async (id) => {
    try {
        const response = await api.post(VERIFY_COMPANY_BY_ID(id));
        console.log(`Verify Company by ID API Response for ID ${id}:`, response);
        return response.data;
    } catch (error) {
        console.error(`Error verifying company with ID ${id}:`, error);
        throw error;
    }
};

export { fetchCompanyList, getCompanyById ,deleteCompanyById,editCompanyById , verifyCompanyById};
