import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { firebaseConfig } from "../firebaseConfig";
import { initializeApp } from "firebase/app";
import Hamburger from "../Hamburger/Hamburger";
import { useNavigate } from "react-router-dom";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi"; // Import left/right icons
import Slider from "react-slick"; // Import Slick Carousel
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const PackagePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { companyId } = location.state;
    const [packages, setPackages] = useState([]);
    const [expandedPackageId, setExpandedPackageId] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // State to manage loading

    useEffect(() => {
        const fetchPackages = async () => {
            try {
                setIsLoading(true); // Start loading
                const packageCollectionRef = collection(db, "packages", companyId, companyId);
                const packageSnapshot = await getDocs(packageCollectionRef);
                const packageList = packageSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setPackages(packageList);
            } catch (error) {
                console.error("Error fetching packages:", error);
            } finally {
                setIsLoading(false); // Stop loading
            }
        };

        fetchPackages();
    }, [companyId]);

    const handleToggleExpand = (packageId) => {
        setExpandedPackageId((prevId) => (prevId === packageId ? null : packageId));
    };

    // Custom arrows for left/right navigation
    const NextArrow = ({ onClick }) => (
        <div
            className="absolute top-1/2 right-2 transform -translate-y-1/2 text-white p-2 rounded-full cursor-pointer z-10"
            onClick={onClick}
        >
            <BiChevronRight className="text-3xl" />
        </div>
    );

    const PrevArrow = ({ onClick }) => (
        <div
            className="absolute top-1/2 left-2 transform -translate-y-1/2 text-white p-2 rounded-full cursor-pointer z-10"
            onClick={onClick}
        >
            <BiChevronLeft className="text-3xl" />
        </div>
    );

    // ✅ Slick Carousel settings with left & right navigation
    const settings = {
        dots: false,  // ❌ No dots
        infinite: true,
        speed: 500,
        slidesToShow: 1,  // Show only 1 image at a time
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        swipe: true,   // Enables swipe for mobile users
        adaptiveHeight: true,
        nextArrow: <NextArrow />,  // Custom right arrow
        prevArrow: <PrevArrow />,  // Custom left arrow
    };

    // Show loader if data is still loading
    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="loader border-t-4 border-b-4 border-gray-500 rounded-full w-8 h-8 animate-spin"></div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100 lg:w-[90%] flex flex-col items-center py-1">
            <div className="w-full max-w-2xl rounded-lg p-6">
                <button
                    onClick={() => navigate("/detailpage", { state: { companyId } })}
                    className="absolute left-4 text-black font-inter text-xl"
                >
                    <BiChevronLeft className="text-3xl md:text-4xl" />
                </button>

                <h1 className="text-xl font-inter font-semibold mb-6 text-center">Packages</h1>

                {/* Display a message if no packages are found */}
                {packages.length === 0 ? (
                    <div className="text-center text-gray-600">
                        <p>No packages found for this company.</p>
                    </div>
                ) : (
                    packages.map((pkg) => (
                        <div key={pkg.id} className="bg-gray-200 rounded-lg shadow-md p-4 mb-4 relative">
                            {/* Image Carousel for Each Package with Left & Right Arrows */}
                            {pkg.images && pkg.images.length > 0 && (
                                <div className="mb-4 rounded-lg overflow-hidden relative">
                                    <Slider {...settings}>
                                        {pkg.images.map((image, index) => (
                                            <div key={index} className="relative">
                                                <img
                                                    src={image}
                                                    alt={`Package ${pkg.packageTitle} - Image ${index + 1}`}
                                                    className="w-full h-60 object-cover rounded-lg"
                                                />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            )}

                            <div className="flex justify-between items-start">
                                <div>
                                    <h2 className="text-lg font-inter font-medium">{pkg.packageTitle}</h2>
                                    <p className="text-gray-600 text-sm">{pkg.packageDescription}</p>
                                </div>
                                <div className="bg-customGray px-3 py-1 rounded-md text-black text-sm font-semibold">
                                    ₹{pkg.packagePrice}
                                </div>
                            </div>

                            {/* Button Container */}
                            <div className="flex justify-end mt-3">
                                <button
                                    onClick={() => handleToggleExpand(pkg.id)}
                                    className="px-5 py-2 bg-black text-white font-inter text-sm rounded-md hover:bg-gray-800 focus:outline-none shadow-md"
                                >
                                    {expandedPackageId === pkg.id ? "Less" : "More"}
                                </button>
                            </div>

                            {/* Expandable Section */}
                            {expandedPackageId === pkg.id && (
                                <div className="mt-4 p-4 bg-gray-200 rounded-lg">
                                    <h3 className="font-medium mb-2 text-black">Includes:</h3>
                                    <ul className="list-disc list-inside mb-4 text-gray-600 text-sm">
                                        {pkg.extras.map((extra, index) => (
                                            <li key={index}>{extra}</li>
                                        ))}
                                    </ul>
                                    <div className="text-lg font-inter font-medium text-right">
                                        ₹{pkg.packagePrice}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                )}

                <Hamburger />
            </div>
        </div>
    );
};

export default PackagePage;
