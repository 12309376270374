// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyB1_W8alwqc9oAgmoc3WSLC23u9vSAEyVo",
  authDomain: "dreamin-cdabe.firebaseapp.com",
  projectId: "dreamin-cdabe",
  storageBucket: "dreamin-cdabe.appspot.com",
  messagingSenderId: "646690260914",
  appId: "1:646690260914:web:d423fe34ae374d41f206ab",
  measurementId: "G-JF31KV8Q15"

};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);

const db = getFirestore(app);

export { storage, auth, db };
