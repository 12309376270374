import { api } from "./api"; // Import Axios instance
import {
  GET_OFFERS_LIST,
  GET_OFFER_BY_ID,
  ADD_OFFER,
  EDIT_OFFER_BY_ID,
  DELETE_OFFER_BY_ID
} from "./urls";

// Fetch Offers List
export const fetchOffersList = async ({ skip, limit, is_valid }) => {
  try {
    const params = { skip, limit };
    if (is_valid !== undefined) {
      params.is_valid = is_valid;
    }

    const response = await api.get(GET_OFFERS_LIST, { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching offers list:", error);
    throw error;
  }
};

// Get Offer by ID
export const fetchOfferById = async (id) => {
  try {
    const response = await api.get(GET_OFFER_BY_ID(id));
    return response.data;
  } catch (error) {
    console.error("Error fetching offer details:", error);
    throw error;
  }
};

// Add a New Offer
export const addOffer = async (offerData) => {
  try {
    const response = await api.post(ADD_OFFER, offerData);
    return response.data;
  } catch (error) {
    console.error("Error adding new offer:", error);
    throw error;
  }
};

// Edit Offer by ID
export const editOfferById = async (id, offerData) => {
  try {
    const response = await api.put(EDIT_OFFER_BY_ID(id), offerData);
    return response.data;
  } catch (error) {
    console.error("Error editing offer:", error);
    throw error;
  }
};

// Delete Offer by ID
export const deleteOfferById = async (id) => {
  try {
    const response = await api.delete(DELETE_OFFER_BY_ID(id));
    return response.data;
  } catch (error) {
    console.error("Error deleting offer:", error);
    throw error;
  }
};
