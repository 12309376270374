import React, { useState, useEffect, useRef, useCallback } from 'react';
import Header from '../Components/header';
import Sidebar from '../Components/sidebar';
import { FaSpinner } from 'react-icons/fa';
import { getCustomerEnquiryById,getCustomerEnquiryList } from '../../controller/dreamin_support';
import CustomerEnquiryModal from '../Components/customer-enquiry-modal';

const CustomerEnquiryList = () => {
    const [enquiries, setEnquiries] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [selectedEnquiryDetails, setSelectedEnquiryDetails] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [skip, setSkip] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const limit = 10;
    const mainContentRef = useRef(null);
    const [selectedEnquiryId, setSelectedEnquiryId] = useState(null);

    const fetchEnquiryData = useCallback(async (loadMore = false) => {
        if (loadMore) {
            setIsLoadingMore(true);
        } else {
            setIsLoading(true);
        }

        try {
            const response = await getCustomerEnquiryList({ skip, limit });
            console.log("API Response:", response);
            const data = response.data || [];

            if (data.length === 0) {
                setHasMore(false);
            } else {
                setEnquiries((prevEnquiries) => loadMore ? [...prevEnquiries, ...data] : data);
                setHasMore(data.length === limit);
            }
        } catch (error) {
            console.error('Error fetching customer enquiries:', error);
        } finally {
            setIsLoading(false);
            setIsLoadingMore(false);
        }
    }, [skip]);

    useEffect(() => {
        fetchEnquiryData(skip > 0);
    }, [skip]);

    useEffect(() => {
        setSkip(0);
        setEnquiries([]);
        setHasMore(true);
        fetchEnquiryData();
    }, []);

   

    const handleShowMore = () => {
        setSkip((prevSkip) => prevSkip + 10);
    };


    const handleViewClick = async (id) => {
        setSelectedEnquiryId(id); // Store the selected enquiry ID
        setIsModalOpen(true); // Open the modal
    };

    return (
        <>
            <Header />
            <div className="flex h-screen bg-[#F3F3F3] font-poppins">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <main ref={mainContentRef} className="flex-1 overflow-x-hidden overflow-y-auto p-6">
                        <div className="mt-6">
                            <h1 className="text-xl font-semibold mb-6 text-[#545454]">Customer Enquiry List</h1>
                            <div className="bg-white rounded-lg shadow-md p-4 mt-4">
                                {isLoading ? (
                                    <div className="flex justify-center items-center h-64">
                                        <FaSpinner className="animate-spin text-4xl text-gray-500" />
                                    </div>
                                ) : (
                                    <div className="overflow-y-auto">
                                        <table className="w-full text-sm">
                                            <thead>
                                                <tr className="text-center text-sm font-poppins">
                                                    <th className="pb-3 font-semibold">Sl. No</th>
                                                    <th className="pb-3 font-semibold text-start">Customer Name</th>
                                                    <th className="pb-3 font-semibold text-start">Company Name</th>
                                                    <th className="pb-3 font-semibold">Budget</th>
                                                    <th className="pb-3 font-semibold">Date of Event</th>
                                                    <th className="pb-3 font-semibold">Type of Wedding</th>
                                                    <th className="pb-3 font-semibold hidden md:table-cell">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {enquiries.length > 0 ? (
                                                    enquiries.map((enquiry, index) => (
                                                        <tr
                                                            key={index}
                                                            className="text-center border-t border-[#FFEFEF] cursor-pointer hover:bg-gray-100"
                                                        >
                                                            <td className="py-3">{index + 1}</td>
                                                            <td className="py-3 text-start">{enquiry.name || 'N/A'}</td>
                                                            <td className="py-3 text-start">{enquiry.company_name || 'N/A'}</td>
                                                            <td className="py-3">{enquiry.budget ? `₹${enquiry.budget}` : 'N/A'}</td>
                                                            <td className="py-3">{enquiry.date_of_event || 'N/A'}</td>
                                                            <td className="py-3">{enquiry.type_of_wedding || 'N/A'}</td>
                                                            <td className="py-3 hidden md:table-cell">
                                                                <button
                                                                    className="px-3 py-1 bg-[#222222] rounded-md text-sm text-[#FFFFFF]"
                                                                    onClick={() => handleViewClick(enquiry.id)}
                                                                >
                                                                    View
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="6" className="text-center py-3">
                                                            No customer enquiries found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        {isLoadingMore && (
                                            <div className="text-center py-3">
                                                <FaSpinner className="animate-spin text-2xl text-gray-500 inline-block" />
                                            </div>
                                        )}
                                        {enquiries.length > 0 && hasMore && !isLoadingMore && (
                                            <div className="flex justify-center mt-4">
                                                <button
                                                    onClick={handleShowMore}
                                                    className="px-2 py-2 bg-[#D8393F] text-[#FFFFFF] rounded-md"
                                                >
                                                    Show more
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </main>
                </div>
            </div>

            {isModalOpen && selectedEnquiryId && (
    <CustomerEnquiryModal
        isOpen={isModalOpen}
        onClose={() => {
            setIsModalOpen(false);
            setSelectedEnquiryId(null); // Reset when closing modal
        }}
        enquiryId={selectedEnquiryId} // Pass selected enquiry ID
    />
)}

        </>
    );
};

export default CustomerEnquiryList;
