import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, query, where, serverTimestamp ,updateDoc} from 'firebase/firestore';
import { GoogleAuthProvider, getAuth, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { firebaseConfig } from '../firebaseConfig';
import '../Homepage/Homepage.css';
import Bottombar from '../Bottombar/Bottombar';
import Loader from '../Loader/Loader';
import Buisnesscard from '../Buisnesscard/Buisnesscard';
import Carousel from '../Carousel/Carousel';
import RingSpinner from "../loaderspinner";
import Hamburger from '../Hamburger/Hamburger';
import { useLocation, useNavigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';
// Initialize Firebase app
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const Homepage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [initialCategories, setInitialCategories] = useState([]);
    const [showMoreTiles, setShowMoreTiles] = useState(false);
    const [loading, setLoading] = useState(true);
    const [fetchingLocation, setFetchingLocation] = useState(true);
    const [userDistrict, setUserDistrict] = useState("Select Location");
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);
    const [user, setUser] = useState(null);
    const [deviceType, setDeviceType] = useState('Other');

    useEffect(() => {
        const checkDeletedAccount = async () => {
            const customerUid = localStorage.getItem('customerUid');
            if (customerUid) {
                try {
                    const customersCollection = collection(db, "customer");
                    const q = query(customersCollection, where("customerUid", "==", customerUid));
                    const querySnapshot = await getDocs(q);

                    if (!querySnapshot.empty) {
                        const userData = querySnapshot.docs[0].data();
                        if (userData.isDeleted) {
                            // Sign out the user if the account is deleted
                            if (auth.currentUser) {
                                await auth.signOut();
                            }
                            localStorage.clear();
                            setUser(null);
                            setUserDistrict("Select Location");
                            setFetchingLocation(false);

                            // Force a page reload to reset the app state
                            window.location.reload();
                        }
                    }
                } catch (error) {
                    console.error("Error checking account status:", error);
                }
            }
        };

        checkDeletedAccount();
    }, []);

    // Detect device type
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
            setDeviceType('iOS');
        } else if (/Android/.test(userAgent)) {
            setDeviceType('Android');
        }
    }, []);

    // Check auth state
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            if (currentUser) {
                localStorage.setItem('customerUid', currentUser.uid);
            }
        });

        return () => unsubscribe();
    }, []);

    // Handle Google login
    const handleGoogleLogin = async () => {
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            const customerUid = user.uid;

            // Clear localStorage and set customerUid
            localStorage.clear();
            localStorage.setItem('customerUid', customerUid);

            // Check if the user already exists in Firestore
            const customersCollection = collection(db, "customer");
            const q = query(customersCollection, where("customerUid", "==", customerUid));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const existingDoc = querySnapshot.docs[0];
                const userData = existingDoc.data();
                const customerId = existingDoc.id;

                localStorage.setItem('customerId', customerId);

                // If the account is deleted, mark it as reactivated
                if (userData.isDeleted) {
                    await updateDoc(doc(db, "customer", customerId), {
                        isDeleted: false, // Reactivate the account
                        lastSeen: serverTimestamp(),
                        status: "online"
                    });
                    navigate(`/userinput/${customerId}`);
                } else {
                    // Update lastSeen timestamp
                    await setDoc(doc(db, "customer", customerId), {
                        lastSeen: serverTimestamp(),
                        status: "online"
                    }, { merge: true });
                }
            } else {
                // User doesn't exist, create a new document in Firestore
                const newCustomerDoc = await addDoc(customersCollection, {
                    email: user.email,
                    imageUrl: user.photoURL,
                    name: user.displayName,
                    isDeleted: false, // New users are not deleted by default
                    createdAt: serverTimestamp(),
                    lastSeen: serverTimestamp(),
                    customerUid: customerUid,
                    status: "online",
                    deviceType: deviceType
                });

                const newCustomerId = newCustomerDoc.id;

                // Update Firestore document with the generated customerId
                await setDoc(doc(db, "customer", newCustomerId), {
                    customerId: newCustomerId
                }, { merge: true });

                localStorage.setItem('customerId', newCustomerId);
                navigate(`/userinput/${newCustomerId}`);
            }
        } catch (error) {
            console.error("Google login failed:", error);
        }
    };

    // Handle profile click
    const handleProfileClick = () => {
        const customerId = localStorage.getItem('customerId');
        if (customerId) {
            navigate('/profile');
        }
    };

    useEffect(() => {
        if (location.state) {
            setUserDistrict(location.state.name);
            setFetchingLocation(false);
            localStorage.setItem("selectedLocation", JSON.stringify(location.state));
        }
    }, [location.state]);

    useEffect(() => {
        const savedLocation = localStorage.getItem("selectedLocation");
        if (savedLocation) {
            setUserDistrict(JSON.parse(savedLocation).name);
            setFetchingLocation(false);
        } else {
            getCurrentLocation();
        }
    }, []);

    const getCurrentLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    fetchDistrictFromCoords(latitude, longitude);
                },
                (error) => {
                    console.error("Error fetching location:", error);
                    setFetchingLocation(false);
                }
            );
        } else {
            setFetchingLocation(false);
        }
    };

    const fetchDistrictFromCoords = async (lat, lon) => {
        try {
            const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
            );
            const data = await response.json();
            console.log(data)
            const district =
                data.address?.state_district  || "Unknown Location";
            setUserDistrict(district);
            console.log(data.address?.county )
            console.log(district)
            localStorage.setItem("selectedLocation", JSON.stringify({ name: district }));
        } catch (error) {
            console.error("Error fetching district:", error);
        }
        setFetchingLocation(false);
    };

    useEffect(() => {
        const handleResize = () => setIsLargeScreen(window.innerWidth >= 1024);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleMoreClick = () => {
        setShowMoreTiles(!showMoreTiles);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'categories'));
                const categoriesData = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        categoryName: data.categoryName,
                        ...data
                    };
                });
                setInitialCategories(categoriesData.slice(0, 5));
                setCategories(categoriesData);
                console.log('Fetched Categories:', categoriesData)
                setLoading(false);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    const handleCardClick = (categoryName) => {
        localStorage.setItem("selectedCategory", categoryName); // Store in localStorage
        navigate('/categories', { state: { categoryName } });
    };
    

    console.log('Categories for rendering:', categories); 

    return (
        <>
        <Helmet>
            <title>Dreamin - Plan Your Dream Wedding with Dreamin</title>
            <meta name="description" content="Discover top venues, entertainers, and everything you need to plan your dream wedding with Dreamin." />
            <link rel="canonical" href="/" />

        </Helmet>
        <div className="page">
            
            {loading ? (
                <Loader />
            ) : (
                <>
                   <Hamburger />

{/* Desktop Navigation */}
<div className="hidden lg:flex items-center justify-between w-full px-16 pt-6 bg-white">
    <div className="flex items-center gap-4">
        <img src="/dreaminn.svg" alt="Dreamin Logo" className="h-12" />
        <p className="navhead text-xl font-semibold">Dreamin</p>
    </div>

    <div className="flex items-center gap-6 pr-12 ">
        <button
            className="text-lg font-inter cursor-pointer"
            onClick={() => navigate('/chooselocation')}
        >
            {fetchingLocation ? <RingSpinner size={20} color="black" /> : userDistrict}
        </button>

        {!user && (
            <button 
                className="px-8 py-2 bg-black  text-white rounded-full"
                onClick={handleGoogleLogin}
            >
                LOGIN
            </button>
        )}
    </div>
</div>

{/* Mobile Navigation */}
<div className="block lg:hidden w-full px-4 relative">
    {/* Navigation Bar */}
    <nav className="flex items-center justify-between w-full mt-4">
        {/* Left: "Dreamin" Heading */}
        <div className="flex flex-col">
            <div className="flex items-center gap-2 mt-1 ">
                <p className="navhead text-lg font-semibold whitespace-nowrap">Dreamin</p>
            </div>
            {/* Location Section */}
            <div className="flex items-center gap-2 mt-1">
                <img src="./location_home_icon.svg" alt="Location" className="h-4" />
                <button
                    className="text-sm font-inter text-black border-gray-600 cursor-pointer"
                    onClick={() => navigate('/chooselocation')}
                >
                    {fetchingLocation ? <RingSpinner size={15} color="black" /> : userDistrict}
                </button>
            </div>
        </div>
        {/* Right: LOGIN Button & Sidebar (Hamburger) in the same row */}
        <div className="flex items-center gap-2 pb-6">
            {!user && (
                <button
                    className="px-5 py-1 bg-black text-white rounded-full text-sm whitespace-nowrap mr-14"
                    onClick={handleGoogleLogin}
                >
                    LOGIN
                </button>
            )}
            {/* Existing Hamburger component */}
            <Hamburger />
        </div>
    </nav>

    {/* Heading Section */}
    <div className="text-left pt-5">
        <p className="text-2xl font-['Rosarivo'] leading-tight">
            Your Perfect
            <br />
            <span className="text-2xl font-['Rosarivo'] font-medium">Wedding Awaits!</span>
        </p>
    </div>
</div>


                    <br />
                    <Carousel />
                    <br />

                    {/* <div className="card-container">
                            {isLargeScreen
                                ? categories.map(category => (
                                    <div className="card" key={category.id} onClick={() => handleCardClick(category.categoryName)}>
                                        <div className="image-container">
                                            <img src={category.imageUrl} alt={category.name} />
                                            <p className="category-name">{category.categoryName}</p>
                                        </div>
                                    </div>
                                ))
                                : (showMoreTiles ? categories : initialCategories).map(category => (
                                    <div className="card" key={category.id} onClick={() => handleCardClick(category.categoryName)}>
                                        <div className="image-container">
                                            <img src={category.imageUrl} alt={category.name} />
                                            <p className="category-name">{category.categoryName}</p>
                                        </div>
                                    </div>
                                ))
                            } */}
                           {/* Toggle Show More / Show Less */}
{/* {!isLargeScreen && (
  <div className="card" onClick={handleMoreClick}>
    <div className="image-container">
      <img src={showMoreTiles ? '/less.svg' : '/photography.svg'} alt={showMoreTiles ? 'Show Less' : 'Show More'} />
      <p className="category-name">{showMoreTiles ? 'Show Less' : 'Show More'}</p>
    </div>
  </div>
)}

                        </div> */}
                        <div className="card-container">
    {isLargeScreen
        ? categories.slice(0, 1).map((category) => (
            <div className="card" key={category.id} onClick={() => handleCardClick(category.categoryName)}>
                <div className="image-container">
                    <img src={category.imageUrl} alt={category.name} />
                    <p className="category-name">{category.categoryName}</p>
                </div>
            </div>
        ))
        : (showMoreTiles ? categories : initialCategories.slice(0, 1)).map((category) => (
            <div className="card" key={category.id} onClick={() => handleCardClick(category.categoryName)}>
                <div className="image-container">
                    <img src={category.imageUrl} alt={category.name} />
                    <p className="category-name">{category.categoryName}</p>
                </div>
            </div>
        ))
    }
</div>


                    <Buisnesscard />


                    <footer className="w-full px-6 pt-6 bg-black text-white">
    <div className="max-w-screen-md mx-auto text-xs font-medium text-center font-inter">
        
        {/* Logo (Replace with actual logo image or SVG) */}
        <div className="flex justify-center">
            <img src="/dreamin-footer.svg" alt="Dreamin Logo" className="h-18 w-18"/>
        </div>

        {/* Small Screens: Two Column Grid */}
        <div className="grid grid-cols-2 gap-4 mt-4 pt-4 sm:hidden">
    {/* Left Column with Vertical Border */}
    <div className="flex flex-col items-center space-y-2 border-r border-gray-500 pr-4">
        <a href="/termsandcondition" className="hover:text-gray-300 text-[14px] whitespace-nowrap">Terms & Conditions</a>
        <a href="/privacypolicy" className="hover:text-gray-300 text-[14px] whitespace-nowrap">Privacy Policies</a>
        <a href="/refundpolicy" className="hover:text-gray-300 text-[14px] whitespace-nowrap">Refund Policy</a>
    </div>

    {/* Right Column */}
    <div className="flex flex-col items-center space-y-2 pl-4">
        <a href="/community" className="hover:text-gray-300 text-[14px] whitespace-nowrap">About Dreamin</a>
        <a href="/checkout" className="hover:text-gray-300 text-[14px] whitespace-nowrap">Checkout</a>
        <a href="/contactus" className="hover:text-gray-300 text-[14px] whitespace-nowrap">Contact Us</a>
    </div>
</div>


     {/* Large Screens */}
<div className="hidden sm:flex justify-center items-center text-white text-sm mt-4 border-t border-gray-500 pt-4">
    <a href="/termsandcondition" className="mx-4 hover:text-gray-300 font-semibold whitespace-nowrap">Terms & Conditions</a>
    <a href="/privacypolicy" className="mx-4 hover:text-gray-300 font-semibold whitespace-nowrap">Privacy Policies</a>
    <a href="/refundpolicy" className="mx-4 hover:text-gray-300 font-semibold whitespace-nowrap">Refund Policy</a>
    <a href="/community" className="mx-4 hover:text-gray-300 font-semibold whitespace-nowrap">About Dreamin</a>
    <a href="/checkout" className="mx-4 hover:text-gray-300 font-semibold whitespace-nowrap">Checkout</a>
    <a href="/contactus" className="mx-4 hover:text-gray-300 font-semibold whitespace-nowrap">Contact Us</a>
</div>


        {/* Copyright Section */}
        <div className="border-t border-gray-500 mt-4  p-4 font-inter text-center text-[12px]">
            Copyright 2024. All Rights Reserved Dreamin
        </div>
    </div>
</footer>



                    
                    
                    



                </>
            )}
            
           

        </div>
        </>

    );
};



export default Homepage;






// import React, { useState, useEffect } from 'react';
// import { initializeApp } from 'firebase/app';
// import { getFirestore, collection, getDocs } from 'firebase/firestore';
// import { firebaseConfig } from '../firebaseConfig';
// import '../Homepage/Homepage.css';
// import Bottombar from '../Bottombar/Bottombar';
// import Loader from '../Loader/Loader';
// import Buisnesscard from '../Buisnesscard/Buisnesscard';
// import Carousel from '../Carousel/Carousel';
// import RingSpinner from "../loaderspinner";
// import Hamburger from '../Hamburger/Hamburger';
// import { useLocation, useNavigate } from 'react-router-dom';
// // Initialize Firebase app
// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);

// const Homepage = () => {
//    const location =useLocation()
//     const navigate = useNavigate();
//     const [categories, setCategories] = useState([]);
//     const [initialCategories, setInitialCategories] = useState([]);
//     const [showMoreTiles, setShowMoreTiles] = useState(false);
//     const [loading, setLoading] = useState(true);
//     const [fetchingLocation, setFetchingLocation] = useState(true); // Loading state for location
// const [userDistrict, setUserDistrict] = useState("Select Location"); // Default location

//     const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

//     useEffect(() => {
//       if (location.state) {
//           setUserDistrict(location.state.name);
//           setFetchingLocation(false);
//           localStorage.setItem("selectedLocation", JSON.stringify(location.state));
//       }
//   }, [location.state]);
  

//     useEffect(() => {
//       const savedLocation = localStorage.getItem("selectedLocation");
//       if (savedLocation) {
//           setUserDistrict(JSON.parse(savedLocation).name);
//           setFetchingLocation(false);
//       } else {
//           getCurrentLocation();
//       }
//   }, []);
  
//   const getCurrentLocation = () => {
//       if ("geolocation" in navigator) {
//           navigator.geolocation.getCurrentPosition(
//               async (position) => {
//                   const { latitude, longitude } = position.coords;
//                   fetchDistrictFromCoords(latitude, longitude);
//               },
//               (error) => {
//                   console.error("Error fetching location:", error);
//                   setFetchingLocation(false);
//               }
//           );
//       } else {
//           setFetchingLocation(false);
//       }
//   };
  
//   const fetchDistrictFromCoords = async (lat, lon) => {
//       try {
//           const response = await fetch(
//               `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
//           );
//           const data = await response.json();
//           const district =
//               data.address?.county || data.address?.state_district || "Unknown Location";
//           setUserDistrict(district);
//           localStorage.setItem("selectedLocation", JSON.stringify({ name: district }));
//       } catch (error) {
//           console.error("Error fetching district:", error);
//       }
//       setFetchingLocation(false);
//   };
  
//     // Handle screen resize to determine large screen
//     useEffect(() => {
//         const handleResize = () => setIsLargeScreen(window.innerWidth >= 1024);
//         window.addEventListener('resize', handleResize);
//         return () => window.removeEventListener('resize', handleResize);
//     }, []);

//     const handleMoreClick = () => {
//         setShowMoreTiles(!showMoreTiles);
//     };

//     useEffect(() => {
//         const timeout = setTimeout(() => {
//             setLoading(false);
//         }, 2000);
//         return () => clearTimeout(timeout);
//     }, []);

//     useEffect(() => {
//         const fetchCategories = async () => {
//             try {
//                 const querySnapshot = await getDocs(collection(db, 'categories'));
//                 const categoriesData = querySnapshot.docs.map(doc => {
//                     const data = doc.data();
//                     return {
//                         id: doc.id,
//                         categoryName: data.categoryName,
//                         ...data
//                     };
//                 });
//                 setInitialCategories(categoriesData.slice(0, 5));
//                 setCategories(categoriesData);
//                 setLoading(false);
//             } catch (error) {
//                 console.error('Error fetching categories:', error);
//             }
//         };

//         fetchCategories();
//     }, [db]);

//     const handleCardClick = (categoryName) => {
//         navigate('/categories', { state: { categoryName } });
//     };

//     return (
//         <div className="page">
//             {loading ? (
//                 <Loader />
//             ) : (
//                 <>
//                     {/* Large Screen Navbar (Updated Design) */}

//                     <div className="hidden lg:flex items-center justify-between w-full px-16 pt-6 bg-white">
//     {/* Left Side: Dreamin Logo & Text */}
//     <div className="flex items-center gap-4">
//         <img src="/dreaminn.svg" alt="Dreamin Logo" className="h-12" /> 
//         <p className="navhead text-xl font-semibold">Dreamin</p> 
//     </div>

//     {/* Right Side: Location, Login Button, and Hamburger (Moved Left) */}
//     <div className="flex items-center gap-6 pr-12">
//     <button
//     className="text-sm  cursor-pointer"
//     onClick={() => navigate('/chooselocation')}
// >
//     {fetchingLocation ? <RingSpinner size={20} color="black" /> : userDistrict}
// </button>
//         <button className="px-8 py-2 bg-black text-white rounded-full">LOGIN</button>
//         <Hamburger />
//     </div>
// </div>

                   



//                     {/* Small screen - Keep the old design */}
//                     <div className="block lg:hidden w-full px-4">
//   {/* Navbar - Logo, Location & Hamburger */}
//   <nav className="flex items-center justify-between mt-4">
//     <div className="flex flex-col">
//       {/* Dreamin Logo & Text */}
//       <div className="flex items-center gap-2">
//         <p className="navhead text-xl font-semibold">Dreamin</p> 
//       </div>
//       {/* Location with Image */}
//       <div className="flex items-center gap-2 mt-1">
//         <img src="./location_home_icon.svg" alt="Location" className="h-4" />  
//         <button
//     className="text-sm font-inter text-gray-600  cursor-pointer"
//     onClick={() => navigate('/chooselocation')}
// >
//     {fetchingLocation ? <RingSpinner size={15} color="black" /> : userDistrict}
// </button>
//       </div>
//     </div>
    
//     {/* Hamburger on the Right */}
//     <Hamburger />
//   </nav>

//   <div className="text-center mt-2">
//   <p className="text-2xl font-['Rosarivo']  leading-tight">
//     Your Perfect
//     <br />
//     <span className="text-2xl font-['Rosarivo'] font-medium ">Wedding Awaits!</span>
//   </p>
// </div>
// </div>


//                     <br />
//                     <Carousel />
//                     <br />

//                    {/* Card Section */}
// <div className="card-container grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 px-4">
//   {isLargeScreen
//     ? categories.map((category) => (
//         <div
//           className="relative card cursor-pointer overflow-hidden rounded-lg"
//           key={category.id}
//           onClick={() => handleCardClick(category.categoryName)}
//         >
//           <div className="image-container relative">
//             <img
//               src={category.imageUrl}
//               alt={category.name}
//               className="w-full h-[220px] object-cover transition-transform duration-300 hover:scale-105"
//             />
//             {/* Gradient Overlay */}
//             <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
//             {/* Centered & Bold Text with Gradient */}
//             <p className="absolute inset-x-0 bottom-4 text-white text-lg font-bold text-center px-2 drop-shadow-md bg-gradient-to-t from-gray-900 via-gray-700 to-transparent py-2">
//               {category.categoryName}
//             </p>
//           </div>
//         </div>
//       ))
//     : (showMoreTiles ? categories : initialCategories).map((category) => (
//         <div
//           className="relative card cursor-pointer overflow-hidden rounded-lg"
//           key={category.id}
//           onClick={() => handleCardClick(category.categoryName)}
//         >
//           <div className="image-container relative">
//             <img
//               src={category.imageUrl}
//               alt={category.name}
//               className="w-full h-[200px] object-cover transition-transform duration-300 hover:scale-105"
//             />
//             {/* Gradient Overlay */}
//             <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
//             {/* Centered & Bold Text with Gradient */}
//             <p className="absolute inset-x-0 bottom-4 text-white text-lg font-bold text-center px-2 drop-shadow-md bg-gradient-to-t from-gray-900 via-gray-700 to-transparent py-2">
//               {category.categoryName}
//             </p>
//           </div>
//         </div>
//       ))}

//   {!isLargeScreen && (
//     <div className="card cursor-pointer" onClick={handleMoreClick}>
//       <img
//         src={showMoreTiles ? "/less.svg" : "/photography.svg"}
//         alt={showMoreTiles ? "less" : "more"}
//         className="w-full h-[200px] object-cover"
//       />
//     </div>
//   )}
// </div>


//                     <Buisnesscard />
//                 </>
//             )}


// <footer className="w-full px-6 pt-6 mt-10">
//       <div className="max-w-screen-md mx-auto text-gray-700 text-xs font-medium text-center">
        
//         {/* Small Screens: Two Column Grid, Proper Alignment */}
//         <div className="sm:hidden grid grid-cols-2 gap-x-8 text-center mt-2">
//           <div className="flex flex-col items-center space-y-1">
//             <a href="/termsandcondition" className="hover:text-gray-900 whitespace-nowrap">Terms & Conditions</a>
//             <a href="/privacypolicy" className="hover:text-gray-900 whitespace-nowrap">Privacy Policy</a>
//             <a href="/community" className="hover:text-gray-900 whitespace-nowrap">Community Guidelines</a>
//           </div>
          
//           <div className="flex flex-col items-center space-y-1">
//             <a href="/contactus" className="hover:text-gray-900 whitespace-nowrap">Contact Us</a>
//             <a href="/checkout" className="hover:text-gray-900 whitespace-nowrap">Subscription Plans</a>
//             <a href="/refundpolicy" className="hover:text-gray-900 whitespace-nowrap">Refund Policy</a>
//           </div>
//         </div>

//         {/* Large Screens: Single Row with Properly Aligned Separators */}
//         <div className="hidden sm:flex justify-center items-center space-x-4 text-sm mt-4">
//           <a href="/termsandcondition" className="hover:text-gray-900 font-semibold whitespace-nowrap">Terms & Conditions</a>
//           <span className="inline-block mx-2">|</span>
//           <a href="/privacypolicy" className="hover:text-gray-900 font-semibold whitespace-nowrap">Privacy Policy</a>
//           <span className="inline-block mx-2">|</span>
//           <a href="/community" className="hover:text-gray-900 font-semibold whitespace-nowrap">Community Guidelines</a>
//           <span className="inline-block mx-2">|</span>
//           <a href="/contactus" className="hover:text-gray-900 font-semibold whitespace-nowrap">Contact Us</a>
//           <span className="inline-block mx-2">|</span>
//           <a href="/checkout" className="hover:text-gray-900 font-semibold whitespace-nowrap">Subscription Plans</a>
//           <span className="inline-block mx-2">|</span>
//           <a href="/refundpolicy" className="hover:text-gray-900 font-semibold whitespace-nowrap">Refund Policy</a>
//         </div>

//       </div>
//     </footer>
//         </div>
//     );
// };
// export default Homepage;