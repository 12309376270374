import axios from "axios";
import BASE_URL from "./urls";

export const getBookmarks = async (user, skip = 0, limit = 10, category = '') => {
  try {
    const response = await axios.get(`${BASE_URL}/bookmarks`, {
      params: {
        user,       // User ID
        skip,       // Pagination - skip
        limit,      // Pagination - limit
        category    // Filter by category
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching bookmarks:", error);
    throw error;
  }
};
