import React, { useState, useEffect } from 'react';
import { FaCheck, FaTimes, FaSpinner } from 'react-icons/fa';
import LoaderSpinner from '../Components/loader_spinner';
import RoundImageContainer from '../Components/round_image_container';
import { showToast } from '../Components/toast';
import { updateVerificationApprovalStatus } from '../../controller/mad_love_verification'; // Updated API function
import { getVerificationViewByUid } from '../../controller/mad_love_verification';

const VerificationDetailsPage = ({ userId, onClose }) => {
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [remarks, setRemarks] = useState('');
    const [overallStatus, setOverallStatus] = useState(null);
    const [verificationData, setVerificationData] = useState(null);

    // Fetch verification details using the userId
    useEffect(() => {
        const fetchVerificationData = async () => {
            if (!userId) {
                showToast('User ID is undefined. Cannot fetch verification details.', 'error');
                return;
            }

            try {
                const res = await getVerificationViewByUid(userId);
                setVerificationData(res?.data || null);
                setRemarks(res?.data?.remarks || '');
            } catch (error) {
                console.error('Error fetching verification data:', error);
                showToast('Error loading verification details', 'error');
            } finally {
                setLoading(false);
            }
        };

        fetchVerificationData();
    }, [userId]);

    // Handle form submission (approve/reject)
    const handleSubmit = async () => {
        if (overallStatus === null) {
            showToast('Please select an option (Approve/Reject).', 'error');
            return;
        }

        setButtonLoading(true);
        try {
            const payload = {
                is_approved: overallStatus, // true or false
                remarks: remarks || '',
            };

            console.log('Update Verification Payload:', payload); // Log the payload

            const response = await updateVerificationApprovalStatus(userId, payload); // Updated API function
            console.log('Update Verification API Response:', response); // Log the response
            showToast('Verification updated successfully!', 'success');
            onClose?.(); // Close modal after successful submission
        } catch (error) {
            console.error('Error updating verification:', error);
            showToast('Error updating verification.', 'error');
        } finally {
            setButtonLoading(false);
        }
    };

    // Loader while fetching data
    if (loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <FaSpinner className="animate-spin text-4xl text-gray-500" />
            </div>
        );
    }

    return (
        <div
            className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center"
            onClick={onClose} // Close modal when clicking outside
        >
            <div
                className="bg-white rounded-lg p-6 shadow-lg relative w-11/12 sm:w-3/4 max-h-[90vh] overflow-y-auto"
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            >
                <button
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700  text-2xl"
                    onClick={onClose}
                >
                    &times;
                </button>
                <h1 className="text-2xl font-semibold text-gray-700 mb-6">Verification Details</h1>

                {/* User Images Section */}
                <div className="mb-6">
                    <h2 className="text-lg font-medium mb-4">User Images</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                        <RoundImageContainer imgSrc={verificationData?.user_image_1} label="Image 1" />
                        <RoundImageContainer imgSrc={verificationData?.user_image_2} label="Image 2" />
                        <RoundImageContainer imgSrc={verificationData?.user_image_3} label="Image 3" />
                    </div>
                </div>

                {/* Remarks Section */}
                <div className="mb-6">
                    <h2 className="text-lg font-medium mb-2">Remarks</h2>
                    <textarea
                        className="w-full h-24 p-4 border rounded-md bg-gray-100 text-gray-700"
                        placeholder="Enter any remarks..."
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                    />
                </div>

                {/* Approve/Reject Buttons */}
                <div className="flex gap-4 mb-6">
                    <button
                        onClick={() => setOverallStatus(true)} // Approve
                        className={`px-4 py-2 rounded-md flex items-center gap-2 ${
                            overallStatus === true ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-500'
                        }`}
                    >
                        <FaCheck />
                        Approve
                    </button>
                    <button
                        onClick={() => setOverallStatus(false)} // Reject
                        className={`px-4 py-2 rounded-md flex items-center gap-2 ${
                            overallStatus === false ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-500'
                        }`}
                    >
                        <FaTimes />
                        Reject
                    </button>
                </div>

                {/* Submit Button */}
                <div className="flex justify-end">
                    <button
                        onClick={handleSubmit}
                        className="px-6 py-2 bg-[#D8393F] text-white rounded-lg shadow-md"
                        disabled={buttonLoading}
                    >
                        {buttonLoading ? <LoaderSpinner visible={true} /> : 'Submit'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VerificationDetailsPage;
