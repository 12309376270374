import React from "react";
import { useNavigate } from 'react-router-dom';
import { BiChevronLeft } from "react-icons/bi"; // Import the left arrow icon

const Termsandcondition = () => {
    const navigate = useNavigate();

    return (
        <div className="container mx-auto px-4 font-inter">
            {/* Back Button */}
            <div
                className="absolute top-6 left-6 md:left-10 lg:left-12 xl:left-16 text-gray-700 cursor-pointer"
                onClick={() => navigate(-1)} // Navigate back to the previous page
            >
                <BiChevronLeft className="text-3xl md:text-4xl" />
            </div>
            {/* Terms and Conditions Container */}
            <div className="rounded-lg p-6 sm:w-full md:w-3/4 lg:w-2/3 mx-auto max-h-screen overflow-y-auto">
                <h2 className="text-xl font-normal font-inter text-gray-800 mb-6 text-center">Terms and Conditions</h2>
                <div className="space-y-6">
                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Binding Agreement</h3>
                        <p className="text-gray-600 font-inter text-sm">These Terms and Conditions, along with the Privacy Policy and other terms, constitute a binding agreement between WECODELIFE PRIVATE LIMITED (“Website Owner” or “we” or “us” or “our”) and you (“you” or “your”). They govern your use of our website, goods, and/or services (“Services”).</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Acceptance of Terms</h3>
                        <p className="text-gray-600 font-inter text-sm">By using our website and availing of the Services, you confirm that you have read and accepted these Terms (including the Privacy Policy). We reserve the right to modify these Terms at any time. It is your responsibility to periodically review these Terms for updates.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Registration and Information</h3>
                        <p className="text-gray-600 font-inter text-sm">To access and use the Services, you agree to provide true, accurate, and complete information during and after registration. You are responsible for all actions performed using your registered account.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Warranty Disclaimer</h3>
                        <p className="text-gray-600 font-inter text-sm">Neither we nor any third parties provide any warranty or guarantee regarding the accuracy, timeliness, performance, completeness, or suitability of the information and materials offered on this website or through the Services. We exclude liability for inaccuracies or errors to the fullest extent permitted by law.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Use at Your Own Risk</h3>
                        <p className="text-gray-600 font-inter text-sm">Your use of the website and Services is at your own risk and discretion. You must independently assess and ensure that the Services meet your requirements.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Intellectual Property</h3>
                        <p className="text-gray-600 font-inter text-sm">The contents of the Website and Services are proprietary to us. You do not have any intellectual property rights or interest in these contents.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Unauthorized Use</h3>
                        <p className="text-gray-600 font-inter text-sm">Unauthorized use of the Website or Services may result in legal action as per these Terms or applicable laws.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Payment for Services</h3>
                        <p className="text-gray-600 font-inter text-sm">You agree to pay the charges associated with the Services you avail of.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Prohibited Use</h3>
                        <p className="text-gray-600 font-inter text-sm">You agree not to use the website or Services for unlawful, illegal, or forbidden purposes under these Terms or applicable laws.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Third-Party Links</h3>
                        <p className="text-gray-600 font-inter text-sm">The website and Services may contain links to third-party websites. Accessing these links means you are governed by their terms of use, privacy policy, and other policies.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Legally Binding Contract</h3>
                        <p className="text-gray-600 font-inter text-sm">By initiating a transaction for availing the Services, you enter into a legally binding contract with us for the Services.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Refund Policy</h3>
                        <p className="text-gray-600 font-inter text-sm">You are entitled to a refund if we fail to provide the Services. The timelines for refund claims are based on the specific Service you avail or our policies. If you do not request a refund within the stipulated time, you will be ineligible for one.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Force Majeure</h3>
                        <p className="text-gray-600 font-inter text-sm">We are not liable for failure to perform our obligations under these Terms if performance is prevented or delayed by force majeure events.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Governing Law</h3>
                        <p className="text-gray-600 font-inter text-sm">These Terms, and any disputes related to them, shall be governed by and construed in accordance with the laws of India.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Dispute Resolution</h3>
                        <p className="text-gray-600 font-inter text-sm">All disputes arising from these Terms shall be subject to the exclusive jurisdiction of the courts in THRIKKAKARA NORTH (PART), Kerala.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Communication</h3>
                        <p className="text-gray-600 font-inter text-sm">All communications relating to these Terms must be directed to us using the contact information provided on the website.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Termsandcondition;
