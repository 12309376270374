
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './View/Pages/home';
import Userlist from './View/Pages/company_list_page';
import ExecutiveList from './View/Pages/executives_list';
import MainHome from './View/Pages/categories_page';
import Subcategories from './View/Pages/subcategories_page';
import SubscriptionList from './View/Pages/subscription_page';
import LoginPageDashboard from './View/Pages/login_dashboard';
import CompanyDetails from './View/Pages/company_details';
import Verification from './View/Pages/verification';
import Issues from './View/Pages/issues_reported';
import { Toast } from './View/Components/toast'; // Import the Toast component
import { showToast } from './View/Components/toast';
import CompanyList from './View/Pages/company_list';
import UserProfilePage from './View/Components/detailed_view_cv_modal';
import VerificationDetailsPage from './View/Pages/deatiled_verification_page';
import CompanyProfilePage from './View/Pages/detailed_company_List';

import Feedbacklist from './View/Pages/feedback_list';
import EditProfilePage from './View/Components/edit_category';
import Supportlist from './View/Pages/support_list';
import ReportIssues from './View/Pages/post_report_list';
import SuspendedUserList from './View/Pages/category_list';
import CategoryList from './View/Pages/category_list';
import CompanyListPage from './View/Pages/company_list_page';
import CurousalList from './View/Pages/curousal_list';
import CustomerEnquiryList from './View/Pages/customer_enquiry';
import OffersList from './View/Pages/feedback_list';

function App() {
  return (
    <Router>
      {/* Render the Toast component globally to allow toast notifications */}
      <Toast />

      <Routes>
        <Route path="/home" element={<Home />} />
       
        <Route path="/executives_list" element={<ExecutiveList />} />
        <Route path="/categories" element={<MainHome />} />
        <Route path="/subcategories_page" element={<Subcategories />} />
        <Route path="/subscription" element={<SubscriptionList />} />
        <Route path="/" element={<LoginPageDashboard />} />
        <Route path="/verification/:uid" element={<Verification />} />
        <Route path="/company-details/:id" element={<CompanyDetails />} />
        <Route path="/issues-reported" element={<Issues />} />
        <Route path="/company_list" element={<CompanyListPage />} />
        <Route path="/verification-detail-page/:userId?" element={<VerificationDetailsPage />} />
        <Route path="/company-profile/:companyId" element={<CompanyProfilePage />} />
        <Route path="/user-profile-page/:userId?" element={<UserProfilePage />} />
        <Route path="/edit_details_page/:userId?" element={<EditProfilePage />} />
       
        <Route path="/offers_list" element={<OffersList />} />
        <Route path="/curousal_list" element={<CurousalList/>} />
        <Route path="/support-list" element={<Supportlist />} />
        <Route path="/customer_enquiry_list" element={<CustomerEnquiryList />} />
        <Route path="/post_reports" element={<ReportIssues />} />
        <Route path="/category_list" element={<CategoryList />} />

      </Routes>
    </Router>
  );
}

export default App;
