import { api } from "./api";
import { GET_BUSINESS_HELP_LIST , GET_BUSINESS_HELP_BY_ID ,} from "./urls";
import { GET_CUSTOMER_ENQUIRY_LIST, GET_CUSTOMER_ENQUIRY_BY_ID } from "./urls";

const getBusinessHelpList = async ({ skip = 0, limit = 10, category = "", company = "" }) => {
    try {
        const response = await api.get(GET_BUSINESS_HELP_LIST, {
            params: { skip, limit, category, company },
        });

        // Return the response data
        return response.data;
    } catch (error) {
        console.error("Error fetching business help list:", error);
        throw error;
    }
};

const getBusinessHelpById = async (id) => {
  try {
      const response = await api.get(GET_BUSINESS_HELP_BY_ID(id));
      return response.data;
  } catch (error) {
      console.error(`Error fetching business help details for ID: ${id}`, error);
      throw error;
  }
};

const getCustomerEnquiryList = async ({ skip = 0, limit = 10 }) => {
  try {
      const response = await api.get(GET_CUSTOMER_ENQUIRY_LIST, {
          params: { skip, limit },
      });
      return response.data;
  } catch (error) {
      console.error("Error fetching customer enquiry list:", error);
      throw error;
  }
};

// Fetch Customer Enquiry By ID
const getCustomerEnquiryById = async (id) => {
  try {
      const response = await api.get(GET_CUSTOMER_ENQUIRY_BY_ID(id));
      return response.data;
  } catch (error) {
      console.error(`Error fetching customer enquiry details for ID: ${id}`, error);
      throw error;
  }
};

export { getBusinessHelpList,getBusinessHelpById, getCustomerEnquiryList,getCustomerEnquiryById };
