import { api } from "./api";
import { LOGIN_URL,LOGOUT} from "./urls";


//djeidijedeidj
const login = async (email, password) => {
  try {
    const res = await api.post(
      LOGIN_URL,
      {
        username: email, // Map email to "username"
        password: password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    console.error("Login API Error:", error);
    throw error; // Handle the error properly in the calling function
  }
};

const logout = async () => {
  const res = await api.post(LOGOUT, {});
  return res;
};

export{login,logout}