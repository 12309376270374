import React, { useState, useEffect } from 'react';
import Header from '../Components/header';
import Sidebar from '../Components/sidebar';
import { FaSpinner } from 'react-icons/fa';
import { fetchCategoryList } from '../../controller/catagory_dreamin';
import Modal from '../Components/image_modal';
import CategoryDetailView from '../Components/category_details';
import AddCategoryModal from '../Components/add_category';

const CategoryList = () => {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [skip, setSkip] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null); // State to store selected image for modal
    const [isImageLoading, setIsImageLoading] = useState(false); // State for image loading
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const limit = 10;

    // Fetch category list from the API
    const fetchCategories = async (newSkip = 0, loadMore = false) => {
        if (loadMore) {
            setIsLoadingMore(true);
        } else {
            setIsLoading(true);
        }
    
        try {
            const response = await fetchCategoryList({ skip: newSkip, limit }); // ✅ Use newSkip
            console.log("API Response:", response);
    
            const data = response?.data || [];
    
            if (!Array.isArray(data)) {
                console.error("API did not return an array:", response);
                setHasMore(false);
                return;
            }
    
            if (data.length === 0) {
                setHasMore(false);
            } else {
                setCategories((prevCategories) => 
                    loadMore ? [...prevCategories, ...data] : data
                ); 
                setHasMore(data.length === limit);
            }
        } catch (error) {
            console.error("Error fetching category list:", error);
        } finally {
            setIsLoading(false);
            setIsLoadingMore(false);
        }
    };
    

    // Initial fetch
    useEffect(() => {
        setSkip(0);
        setCategories([]);
        setHasMore(true);
        fetchCategories(0); // ✅ Start from 0
    }, []);
    

    // Fetch more categories when "Show More" is clicked
    const handleShowMore = () => {
        const newSkip = skip + limit;
        setSkip(newSkip);
        fetchCategories(newSkip, true); // 
    };
    
    // Open modal with selected image
    const handleViewImage = (imageUrl) => {
        setSelectedImage(imageUrl);
        setIsImageLoading(true); // Set loading state when opening the modal
    };

    // Close modal
    const handleCloseModal = () => {
        setSelectedImage(null);
        setIsImageLoading(false); // Reset loading state
    };



    const handleViewDetails = (categoryId) => {
        console.log("Opening category details for ID:", categoryId); // Debugging log
        setSelectedCategoryId(categoryId);
    };
    

// Close detailed view modal
const handleCloseDetailModal = () => {
    setSelectedCategoryId(null);
};


const handleAddCategory = () => {
    setIsAddModalOpen(true);
  };

  const handleCategoryAdded = () => {
    // Re-fetch the category list after adding a category
    setSkip(0);
    setCategories([]);
    setHasMore(true);
    fetchCategories();
  };


    return (
        <>
            <Header />
            <div className="flex h-screen bg-[#F3F3F3] font-poppins">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <main className="flex-1 overflow-x-hidden overflow-y-auto p-6">
                        <div className="mt-6 flex justify-between items-center">
                            <h1 className="text-xl font-semibold mb-6 text-[#545454]">Category List</h1>
                            <button
                className="bg-[#222222] text-white px-4 py-2 rounded-md text-sm"
                onClick={handleAddCategory}
              >
                Add
              </button>                        </div>

                        <div className="bg-white rounded-lg shadow-md p-4 mt-4">
                            {isLoading ? (
                                <div className="flex justify-center items-center h-64">
                                    <FaSpinner className="animate-spin text-4xl text-gray-500" />
                                </div>
                            ) : (
                                <div className="overflow-y-auto">
                                    <table className="w-full text-sm">
                                        <thead>
                                            <tr className="text-left text-sm font-poppins">
                                                <th className="pb-3 font-semibold">Sl. No</th>
                                                <th className="pb-3 font-semibold">Category Name</th>
                                                <th className="pb-3 font-semibold">Image</th>
                                                <th className="pb-3 font-semibold">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {categories.length > 0 ? (
                                                categories.map((category, index) => (
                                                    <tr
                                                        key={index}
                                                        className="text-left border-t border-[#FFEFEF] hover:bg-gray-100"
                                                    >
                                                        <td className="py-3">{index + 1}</td>
                                                        <td className="py-3 text-left truncate max-w-[150px]">
                                                            {category.categoryName || 'N/A'}
                                                        </td>
                                                        <td className="py-3">
                                                            <img
                                                                src={category.imageUrl}
                                                                alt="Category"
                                                                className="w-16 h-16 object-cover cursor-pointer rounded"
                                                                onClick={() => handleViewImage(category.imageUrl)}
                                                            />
                                                        </td>
                                                        <td className="py-3">
                                                         <button
                                                            className="px-3 py-1 bg-[#222222] text-white rounded-md text-sm"
                                                             onClick={() => handleViewDetails(category.categoryId)}
                                                             >
                                                            View
                                                         </button>

                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="4" className="text-center py-3">
                                                        No categories found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {isLoadingMore && (
                                        <div className="text-center py-3">
                                            <FaSpinner className="animate-spin text-2xl text-gray-500 inline-block" />
                                        </div>
                                    )}
                                    {categories.length > 0 && hasMore && !isLoadingMore && (
                                        <div className="flex justify-center mt-4">
                                            <button
                                                onClick={handleShowMore}
                                                className="px-2 py-2 bg-[#222222] text-[#FFFFFF] rounded-md"
                                            >
                                                Show more
                                            </button>
                                        </div>
                                    )}
                                    {!hasMore && (
                                        <div className="text-center mt-4 text-gray-500">
                                            No more categories to load.
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </main>
                </div>
            </div>
            {selectedCategoryId && (
    <CategoryDetailView
        categoryId={selectedCategoryId}
        onClose={handleCloseDetailModal}
    />
)}

            {/* Modal to show category image */}
            {selectedImage && (
                <Modal onClose={handleCloseModal}>
                    <div className="p-4 flex flex-col items-center">
                        {isImageLoading && (
                            <FaSpinner className="animate-spin text-4xl text-gray-500 mb-4" />
                        )}
                        <img
                            src={selectedImage}
                            alt="Category"
                            className={`w-full max-h-[500px] object-contain rounded ${
                                isImageLoading ? 'hidden' : ''
                            }`}
                            onLoad={() => setIsImageLoading(false)} // Stop loading spinner when the image is loaded
                        />
                        <button
                            className="mt-4 bg-[#222222] text-white px-4 py-2 rounded"
                            onClick={handleCloseModal}
                        >
                            Close
                        </button>
                    </div>
                </Modal>
            )}

{isAddModalOpen && (
        <AddCategoryModal
          onClose={() => setIsAddModalOpen(false)}
          onCategoryAdded={handleCategoryAdded}
        />
      )}
        </>
    );
};


export default CategoryList;
