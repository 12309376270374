import { api } from "./api";


import { GET_CATEGORY_LIST } from "./urls";
import { ADD_CATEGORY,GET_CATEGORY_BY_ID } from "./urls";
import { EDIT_CATEGORY_BY_ID } from "./urls";
import { DELETE_CATEGORY_BY_ID } from "./urls";




  // API to fetch category list
const fetchCategoryList = async ({ skip, limit }) => {
    try {
      const params = { skip, limit }; // API parameters for pagination
      const response = await api.get(GET_CATEGORY_LIST, { params });
      console.log("Category List API Response:", response); // Log the response
      return response.data; // Adjust based on the API response structure
    } catch (error) {
      console.error("Error fetching category list:", error);
      throw error;
    }
  };


  // API to add a category
const addCategory = async ({ categoryName, imageUrl }) => {
  try {
    const payload = { categoryName, imageUrl }; // Request body
    const response = await api.post(ADD_CATEGORY, payload);
    console.log("Add Category API Response:", response); // Log the response
    return response.data; // Adjust based on the API response structure
  } catch (error) {
    console.error("Error adding category:", error);
    throw error;
  }
};

// API to fetch category by ID
const getCategoryById = async (id) => {
  try {
    const response = await api.get(GET_CATEGORY_BY_ID(id));
    console.log("Get Category by ID API Response:", response); // Log the response
    return response.data; // Adjust based on the API response structure
  } catch (error) {
    console.error(`Error fetching category with ID ${id}:`, error);
    throw error;
  }
};


// API to edit category by ID
const editCategoryById = async (id, categoryData) => {
  try {
      const response = await api.put(EDIT_CATEGORY_BY_ID(id), categoryData);
      console.log("Edit Category API Response:", response);
      return response.data;
  } catch (error) {
      console.error(`Error editing category with ID ${id}:`, error);
      throw error;
  }
};


const deleteCategoryById = async (id) => {
  try {
    const response = await api.delete(DELETE_CATEGORY_BY_ID(id));
    console.log("Delete Category API Response:", response);
    return response.data;
  } catch (error) {
    console.error(`Error deleting category with ID ${id}:`, error);
    throw error;
  }
};

export {fetchCategoryList,addCategory,getCategoryById, editCategoryById,deleteCategoryById}