import React, { useEffect, useState } from 'react';
import { getCustomerEnquiryById } from '../../controller/dreamin_support';
import { FaSpinner } from 'react-icons/fa';

const CustomerEnquiryModal = ({ isOpen, onClose, enquiryId }) => {
    const [enquiryDetails, setEnquiryDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isOpen && enquiryId) {
            fetchEnquiryDetails();
        }
    }, [isOpen, enquiryId]);

    const fetchEnquiryDetails = async () => {
        setIsLoading(true);
        try {
            const response = await getCustomerEnquiryById(enquiryId);
            if (response && response.data) {
                setEnquiryDetails(response.data);
            } else {
                console.error('Failed to fetch customer enquiry details');
            }
        } catch (error) {
            console.error('Error fetching customer enquiry details:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg w-1/2 shadow-lg">
                <h2 className="text-lg font-bold mb-4">Customer Enquiry Details</h2>

                {isLoading ? (
                    <div className="flex justify-center items-center">
                        <FaSpinner className="animate-spin text-3xl text-gray-500" />
                    </div>
                ) : enquiryDetails ? (
                    <div>
                        <div className="flex items-center space-x-4 mb-4">
                            <img
                                src={enquiryDetails.profile_image}
                                alt={enquiryDetails.name}
                                className="w-24 h-24 rounded-full"
                            />
                            <div>
                                <h3 className="text-xl font-semibold">{enquiryDetails.name}</h3>
                                <p className="text-gray-600"><strong>Username:</strong> {enquiryDetails.user_name}</p>
                            </div>
                        </div>
                        <p><strong>Company Name:</strong> {enquiryDetails.company_name}</p>
                        <p><strong>Company ID:</strong> {enquiryDetails.company_id}</p>
                        <p><strong>Customer ID:</strong> {enquiryDetails.customer_id}</p>
                        <p><strong>Budget:</strong> ₹{enquiryDetails.budget}</p>
                        <p><strong>Type of Wedding:</strong> {enquiryDetails.type_of_wedding}</p>
                        <p><strong>Date of Event:</strong> {enquiryDetails.date_of_event}</p>
                        <p><strong>Email:</strong> {enquiryDetails.email}</p>
                        <p><strong>WhatsApp Number:</strong> {enquiryDetails.whatsapp_number}</p>
                        <p><strong>Address:</strong> {enquiryDetails.address}</p>
                        <p><strong>Message:</strong> {enquiryDetails.message}</p>
                    </div>
                ) : (
                    <p className="text-red-500">No details found.</p>
                )}

                <button 
                    className="mt-4 bg-red-500 text-white px-4 py-2 rounded" 
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default CustomerEnquiryModal;
