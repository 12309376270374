// import React from 'react';
// import InputBox from './input_box';
// import Button from './button';
// import { addExecutive } from '../../controller/executives'; // Import the API function

// const PopupForm = ({ formValues, handleInputChange, closePopup, fetchExecutives }) => {

//   const handleFormSubmit = async () => {
//     try {
//       // Call the addExecutive API with the form values
//       const response = await addExecutive(formValues.email, formValues.country, formValues.name, formValues.password);
      
//       // Console log the response to check the result
//       console.log('API Response:', response);
      
//       // Optionally fetch the updated list of executives after adding
//       fetchExecutives();

//       // Close the popup after successful submission
//       closePopup();
//     } catch (error) {
//       console.error('Error adding executive:', error);
//       // Handle the error (e.g., display an error message)
//     }
//   };

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//       <div className="bg-white p-6 rounded-xl shadow-lg w-2/5 relative">
//         <button onClick={closePopup} className="absolute top-2 right-2 text-gray-600 hover:text-gray-900">
//           &times;
//         </button>
        
//         <div className="grid grid-cols-2 gap-4">
//           <InputBox
//             label="Email ID"
//             value={formValues.email}
//             onChange={handleInputChange}
//             placeholder="Enter email"
//             name="email"
//           />
//           <InputBox
//             label="Password"
//             value={formValues.password}
//             onChange={handleInputChange}
//             placeholder="Enter Password"
//             name="password"
//             type="password"
//           />
//           <InputBox
//             label="Country"
//             value={formValues.country}
//             onChange={handleInputChange}
//             placeholder="Select Country"
//             name="country"
//           />
//           <InputBox
//             label="Name"
//             value={formValues.name}
//             onChange={handleInputChange}
//             placeholder="Enter Name"
//             name="name"
//           />
//         </div>
        
//         <div className="flex justify-start w-40 mt-4">
//           <Button
//             onClick={handleFormSubmit}
//             borderColor="border-transparent"
//             backgroundColor="bg-[#DB0000]"
//             textColor="text-white"
//             fontWeight="font-semibold"
//             text="Submit"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PopupForm;
import React, { useState } from 'react';
import InputBox from './input_box';
import Button from './button';
import LoaderSpinner from './loader_spinner';
import { FaLock, FaUnlock, FaCheck } from 'react-icons/fa';
import { MdError } from 'react-icons/md';  // Import MdError icon for validation error

const PopupForm = ({ formValues, handleInputChange, closePopup, handleFormSubmit }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);  // State for form submission loading
  const [validationMessages, setValidationMessages] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
  }); // State to handle validation messages

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Validate password based on criteria
  const validatePassword = (password) => {
    const validation = {
      length: password.length >= 8,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setValidationMessages(validation);
  };

  // Handle password input change and validate password
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    handleInputChange(e);  // Pass input changes to the parent
    validatePassword(value);  // Validate the password
  };

  // Simulate form submission
  const submitForm = async () => {
    setLoading(true);  // Set loading state to true when submitting
    try {
      await handleFormSubmit();  // Call parent submit function
    } catch (error) {
      console.error('Form submission error:', error);
    } finally {
      setLoading(false);  // Set loading state to false after submission
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-xl shadow-lg w-2/5 relative">
        <button onClick={closePopup} className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-2xl">
          &times;
        </button>

        <div className="grid grid-cols-2 gap-4">
          <InputBox
            label="Email ID"
            value={formValues.email}
            onChange={handleInputChange}
            placeholder="Enter email"
            name="email"
          />

          {/* Password field with toggleable visibility */}
          <div className="relative">
            <InputBox
              label="Password"
              value={formValues.password}
              onChange={handlePasswordChange}
              placeholder="Enter Password"
              name="password"
              type={showPassword ? 'text' : 'password'}  // Toggle input type between 'text' and 'password'
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-2 mt-3 flex items-center text-gray-600 hover:text-gray-900"
            >
              {showPassword ? <FaUnlock size={15} /> : <FaLock size={15} />}  {/* Toggle icon */}
            </button>
          </div>

          <InputBox
            label="Country"
            value={formValues.country}
            onChange={handleInputChange}
            placeholder="Select Country"
            name="country"
          />
          <InputBox
            label="Name"
            value={formValues.name}
            onChange={handleInputChange}
            placeholder="Enter Name"
            name="name"
          />
        </div>

        {/* Validation messages */}
        <div className="grid grid-cols-2 gap-x-6 gap-y-2 mt-2 text-sm text-[#545454] font-poppins font-normal">
          <div className="flex items-center space-x-2">
            {validationMessages.length ? <FaCheck className="text-green-500" /> : <MdError className="text-[#DB0000]" />}
            <span>At least 8 characters</span>
          </div>
          <div className="flex items-center space-x-2">
            {validationMessages.lowercase ? <FaCheck className="text-green-500" /> : <MdError className="text-[#DB0000]" />}
            <span>Lowercase letter</span>
          </div>
          <div className="flex items-center space-x-2">
            {validationMessages.uppercase ? <FaCheck className="text-green-500" /> : <MdError className="text-[#DB0000]" />}
            <span>Uppercase letter</span>
          </div>
          <div className="flex items-center space-x-2">
            {validationMessages.number ? <FaCheck className="text-green-500" /> : <MdError className="text-[#DB0000]" />}
            <span>Number</span>
          </div>
          <div className="flex items-center space-x-2">
            {validationMessages.special ? <FaCheck className="text-green-500" /> : <MdError className="text-[#DB0000]" />}
            <span>Special character</span>
          </div>
        </div>
        
        <div className="flex justify-start w-40 mt-4">
          <button
            onClick={submitForm}  // Call submitForm that handles loading state
            className="w-full flex justify-center items-center bg-[#DB0000] text-white px-4 py-2 rounded"
            disabled={loading}  // Disable the button when loading
          >
            {loading ? (
              <LoaderSpinner visible={loading} />
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupForm;
