import React from 'react';
import { ScaleLoader } from 'react-spinners';

const LoaderSpinner = ({ visible }) => {
  return (
    <div className={`flex justify-center items-center ${visible ? '' : 'hidden'}`}>
      <ScaleLoader
        height={20}
        width={3}
        radius={2}
        margin={1}
        color="#F9F9F9"
        loading={visible}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default LoaderSpinner;
