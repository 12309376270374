import React, { useState, useEffect } from 'react';
import Header from '../Components/header';
import Sidebar from '../Components/sidebar';
import { FaSpinner } from 'react-icons/fa';
import PostAlertComponent from '../Components/post_report_alert';
import { getPostReports } from '../../controller/madlove_report';
const ReportIssues = () => {
  const [issues, setIssues] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportDetails, setReportDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const limit = 10;

  // Fetch Issues from the API
  const fetchIssues = async () => {
    try {
      setLoading(true);
      const response = await getPostReports({ skip, limit });

      // Transform response to match the UI structure
      const formattedReports = response.map((report) => ({
        reporter: {
          name: report.reportedByName || 'Unknown Reporter',
          profile_image: report.reportedByUserProfileImageUrl || '',
          user: report.reportedByDocId || 'No user ID',
        },
        reporting: {
          name: report.reportedAgainst || 'Unknown Reported',
          profile_image: '', // Add if reporting profile image is available
        },
        created_on: report.reportedTime || null,
        reason: report.reportingReason || 'No reason provided',
      }));

      if (skip === 0) {
        setIssues(formattedReports); // Reset issues on the first fetch
      } else {
        setIssues((prevIssues) => [...prevIssues, ...formattedReports]); // Append issues on pagination
      }

      setHasMore(formattedReports.length === limit); // Check if more data is available
    } catch (error) {
      console.error('Error fetching issues:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIssues();
  }, [skip]);

  const loadMoreIssues = () => {
    setSkip((prevSkip) => prevSkip + limit);
  };

  const openModal = (issue) => {
    const reportData = {
      reporterName: issue.reporter?.name || 'Unknown Reporter',
      reporterProfileImage: issue.reporter?.profile_image || '',
      reportedToName: issue.reporting?.name || 'Unknown Reporting',
      reportedToProfileImage: issue.reporting?.profile_image || '',
      reportingDate: issue.created_on || null,
      issueDescription: issue.reason || 'No description provided',
      reporterEmail: issue.reporter?.user || 'No email provided', // Assuming `user` is the email here
    };
    setReportDetails(reportData); // Pass the correctly mapped report data
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Header />
      <div className="flex h-screen bg-[#F3F3F3]">
        <Sidebar />
        <div className="flex-1 flex flex-col overflow-hidden">
          <main className="flex-1 overflow-x-hidden overflow-y-auto p-6">
            <h2 className="text-lg font-poppins text-[#292D32] mb-3 mt-3 ml-4 font-medium">Issues Reported</h2>
            <div className="bg-white rounded-lg shadow-md p-6 relative font-poppins text-[#292D32]">
              <table className="w-full">
                <thead>
                  <tr className="text-center text-sm text-[#292D32]">
                    <th className="pb-3 text-[#292D32] font-medium text-center">SL No.</th>
                    <th className="pb-3 text-[#292D32] font-medium text-left">Reported By</th>
                    <th className="pb-3 text-[#292D32] font-medium text-center hidden sm:table-cell">Reported Date</th>
                  </tr>
                </thead>
                <tbody>
                  {issues.map((issue, index) => (
                    <tr
                      key={index}
                      className="border-t border-[#FFEFEF] text-center cursor-pointer"
                      onClick={() => openModal(issue)}
                    >
                      <td className="py-3">{index + 1 + skip}</td>
                      <td className="py-3 text-left">
  <div className="flex items-center">
    <img
      src={issue.reporter?.profile_image || ''}
      alt={`${issue.reporter?.name || 'Unknown'}'s profile`}
      className="w-8 h-8 rounded-full inline-block mr-2"
    />
    <span>{issue.reporter?.name || 'Unknown'}</span>
  </div>
</td>


                      <td className="py-3 hidden sm:table-cell">
                        {issue.created_on
                          ? new Date(issue.created_on).toLocaleDateString()
                          : 'Unknown Date'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {loading && (
                <div className="flex justify-center items-center py-4">
                  <FaSpinner className="animate-spin text-gray-500 text-xl" />
                </div>
              )}
              {hasMore && (
                <div className="mt-4 text-center">
                  <button
                    onClick={loadMoreIssues}
                    className="px-3 py-1 bg-[#D8393F] text-[#FFFFFF] rounded-md text-sm"
                  >
                    Load more
                  </button>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
      {isModalOpen && (
        <PostAlertComponent
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          reportData={reportDetails}
        />
      )}
    </>
  );
};

export default ReportIssues;
