import React from "react";

const Popup = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80 text-center">
        <p className="text-lg font-inter text-gray-800">{message}</p>
        <div className="flex justify-center mt-4 space-x-4">
          <button
            className="bg-black text-white px-6 py-2 rounded-full font-medium hover:opacity-80 transition-all"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="border-2 border-black text-black px-6 py-2 rounded-full font-medium hover:bg-gray-100 transition-all"
            onClick={onConfirm}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
