import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { FcGoogle } from "react-icons/fc";
import { getFirestore, doc, setDoc, addDoc, collection, query, where, getDocs, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB1_W8alwqc9oAgmoc3WSLC23u9vSAEyVo",
  authDomain: "dreamin-cdabe.firebaseapp.com",
  projectId: "dreamin-cdabe",
  storageBucket: "dreamin-cdabe.appspot.com",
  messagingSenderId: "646690260914",
  appId: "1:646690260914:web:d423fe34ae374d41f206ab",
  measurementId: "G-JF31KV8Q15"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const Login = () => {
  const [deviceType, setDeviceType] = useState('Other');
  const navigate = useNavigate();

  // Detect device type
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
      setDeviceType('iOS');
    } else if (/Android/.test(userAgent)) {
      setDeviceType('Android');
    }
  }, []);

  // Handle Google login
  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const customerUid = user.uid;

      // Clear localStorage and set customerUid
      localStorage.clear();
      localStorage.setItem('customerUid', customerUid);

      // Check if the user already exists in Firestore
      const customersCollection = collection(db, "customer");
      const q = query(customersCollection, where("customerUid", "==", customerUid));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const existingDoc = querySnapshot.docs[0];
        const userData = existingDoc.data();
        const customerId = existingDoc.id;

        localStorage.setItem('customerId', customerId);

        if (userData.isDeleted) {
          console.log("User has been marked as deleted, redirecting to user input page.");
          navigate(`/userinput/${customerId}`);
        } else {
          console.log("Existing user, redirecting to homepage.");
          navigate('/homepage');
        }
      } else {
        // User doesn't exist, create a new document in Firestore
        const newCustomerDoc = await addDoc(customersCollection, {
          email: user.email,
          imageUrl: user.photoURL,
          isDeleted: false,
          createdAt: serverTimestamp(),
          lastSeen: serverTimestamp(),
          customerUid: customerUid,
          status: "offline",
        });

        const newCustomerId = newCustomerDoc.id;

        // Update Firestore document with the generated customerId
        await setDoc(doc(db, "customer", newCustomerId), {
          customerId: newCustomerId
        }, { merge: true });

        localStorage.setItem('customerId', newCustomerId);

        navigate(`/userinput/${newCustomerId}`);
      }
    } catch (error) {
      console.error("Google login failed:", error);
    }
  };

  return (
    <div className="h-screen flex flex-col justify-center items-center bg-white px-6">
      {/* Logo */}
      <div className="w-3/4 sm:w-1/2 md:w-1/3 lg:w-1/4 mb-8">
        <img src="/dreamin_logo.svg" alt="Dreamin Logo" className="w-full" />
      </div>

      {/* Login Button */}
      <button
        className="bg-black text-white font-semibold text-lg py-4 px-6 rounded-full max-w-sm w-full flex items-center justify-center space-x-3 shadow-lg hover:scale-105 transition-transform"
        onClick={handleGoogleLogin}
      >
        <FcGoogle className="text-2xl" />
        <span>Log in with Google</span>
      </button>

      {/* Footer */}
      <footer className="absolute bottom-1 w-full px-6 pt-6">
  <div className="max-w-screen-md mx-auto text-gray-700 text-xs font-medium text-center">
    
    {/* Small Screens: Two Column Grid, Proper Alignment */}
    <div className="sm:hidden grid grid-cols-2 gap-x-8  text-center mt-2">
      <div className="flex flex-col items-center space-y-1">
        <a href="/termsandcondition" className="hover:text-gray-900 whitespace-nowrap">Terms & Conditions</a>
        <a href="/privacypolicy" className="hover:text-gray-900 whitespace-nowrap">Privacy Policy</a>
        <a href="/community" className="hover:text-gray-900 whitespace-nowrap">Community Guidelines</a>
      </div>
      
      <div className="flex flex-col items-center space-y-1">
        <a href="/contactus" className="hover:text-gray-900 whitespace-nowrap">Contact Us</a>
        <a href="/checkout" className="hover:text-gray-900 whitespace-nowrap">Subscription Plans</a>
        <a href="/refundpolicy" className="hover:text-gray-900 whitespace-nowrap">Refund Policy</a>
      </div>
    </div>

    {/* Large Screens: Single Row with Properly Aligned Separators */}
    <div className="hidden sm:flex justify-center items-center space-x-4 text-sm mt-4">
      <a href="/termsandcondition" className="hover:text-gray-900 font-semibold whitespace-nowrap">Terms & Conditions</a>
      <span className="inline-block mx-2">|</span>
      <a href="/privacypolicy" className="hover:text-gray-900 font-semibold whitespace-nowrap">Privacy Policy</a>
      <span className="inline-block mx-2">|</span>
      <a href="/community" className="hover:text-gray-900 font-semibold whitespace-nowrap">Community Guidelines</a>
      <span className="inline-block mx-2">|</span>
      <a href="/contactus" className="hover:text-gray-900 font-semibold whitespace-nowrap">Contact Us</a>
      <span className="inline-block mx-2">|</span>
      <a href="/checkout" className="hover:text-gray-900 font-semibold whitespace-nowrap">Subscription Plans</a>
      <span className="inline-block mx-2">|</span>
      <a href="/refundpolicy" className="hover:text-gray-900 font-semibold whitespace-nowrap">Refund Policy</a>
    </div>

  </div>
</footer>


     
    </div>
  );
};

export default Login;