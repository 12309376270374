import React from "react";
import { useNavigate } from 'react-router-dom';
import { BiChevronLeft, BiPhone, BiEnvelope, BiMap } from "react-icons/bi"; // Import icons

const ContactUs = () => {
    const navigate = useNavigate();

    return (
        <div className="container mx-auto px-4 font-inter flex justify-center items-center min-h-screen relative">
            {/* Back Button */}
            <div
                className="absolute top-6 left-6 md:left-10 lg:left-12 xl:left-16 text-gray-700 cursor-pointer"
                onClick={() => navigate(-1)} // Navigate back to the previous page
            >
                <BiChevronLeft className="text-3xl md:text-4xl" />
            </div>

            {/* Contact Us Container (Centered) */}
            <div className="rounded-lg p-6 sm:w-full md:w-3/4 lg:w-2/3 mx-auto max-h-screen overflow-y-auto">
                <h2 className="text-xl font-normal font-inter text-gray-800 mb-6 text-center">
                    Contact Us
                </h2>

                {/* Paragraph Introduction */}
                <p className="text-gray-600 font-inter text-sm mb-6">
                    If you have any inquiries, feedback, or require assistance, our team is here to help. 
                    You can reach out to us through any of the channels below, and we will do our best to respond 
                    as soon as possible. Whether it's a business query or customer support, we are always available.
                </p>

                <div className="space-y-6">
                  

                    {/* Email Contacts */}
                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter flex items-center gap-2">
                            <BiEnvelope className="text-green-500 text-2xl" />
                            Email Support
                        </h3>
                        <p className="text-gray-600 font-inter text-sm">
                            For any queries, collaboration requests, or career opportunities, 
                            drop us an email at the relevant address:
                        </p>
                        <p className="text-gray-600 font-inter text-sm mt-2">📧 enquiry@wecodelife.com</p>
                    </div>

                    {/* Location */}
                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter flex items-center gap-2">
                            <BiMap className="text-green-500 text-2xl" />
                            Office Address
                        </h3>
                        <p className="text-gray-600 font-inter text-sm">
                            Visit our headquarters for business inquiries or meetings:
                        </p>
                        <p className="text-gray-600 font-inter text-sm mt-2">🏢 WeCodeLife Private Limited</p>
                        <p className="text-gray-600 font-inter text-sm">📍 Kochi, Kerala, India</p>
                    </div>

                    {/* Additional Information */}
                    
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
