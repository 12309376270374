import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { firebaseConfig } from "../firebaseConfig";
import { FaGlobe } from "react-icons/fa";
import { FaRegBookmark, FaBookmark } from "react-icons/fa"; // Import bookmark icons
import {  setDoc, deleteDoc } from "firebase/firestore";
import { BiChevronLeft } from "react-icons/bi";
import {  collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import Hamburger from "../Hamburger/Hamburger";
import Slider from "react-slick";
import { Helmet } from "react-helmet-async";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useCheckLogin from "../../controller/checkuserlogin";


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const DetailPage = () => {
    const location = useLocation();

    const { checkLogin, LoginAlertComponent } = useCheckLogin();

const companyId = location.state?.companyId || localStorage.getItem("companyId");
    const [company, setCompany] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showWebsite, setShowWebsite] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [isBookmarked, setIsBookmarked] = useState(false); // Bookmark state
    const [recentWorks, setRecentWorks] = useState([]);
    const [packages, setPackages] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // State for loading

    const navigate = useNavigate();
const websiteRef = useRef(null); // 


    const startX = useRef(0);
    const currentX = useRef(0);


    useEffect(() => {
        if (companyId) {
            localStorage.setItem("companyId", companyId);
        }
    }, [companyId]);
    

    useEffect(() => {
        const fetchCompanyDetails = async () => {
          try {
            const companyRef = collection(db, "company");
            const companySnapshot = await getDocs(query(companyRef, where("companyDocId", "==", companyId)));
      
            if (!companySnapshot.empty) {
              setCompany(companySnapshot.docs[0].data());
            } else {
              console.error("Company not found!");
            }
          } catch (error) {
            console.error("Error fetching company details:", error);
          }
        };
      
        const fetchPosts = async () => {
          try {
            const postsRef = collection(db, "posts");
            const postsQuery = query(postsRef, where("companyDocId", "==", companyId));
            const postsSnapshot = await getDocs(postsQuery);
      
            if (!postsSnapshot.empty) {
              const posts = postsSnapshot.docs.map(doc => ({
                id: doc.id,
                image: doc.data().imagePath,
                description: doc.data().description,
              }));
              setRecentWorks(posts);
            }
          } catch (error) {
            console.error("Error fetching posts:", error);
          }
        };
      
        const fetchPackages = async () => {
          try {
            const packageCollectionRef = collection(db, "packages", companyId, companyId);
            const packageSnapshot = await getDocs(packageCollectionRef);
            const packageList = packageSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          }
        };
      
        const fetchData = async () => {
          setIsLoading(true); // Start loading
          try {
            await fetchCompanyDetails();
            await fetchPosts();
            await fetchPackages();
          } catch (error) {
            console.error("Error fetching data:", error);
          } finally {
            setIsLoading(false); // Stop loading
          }
        };
      
        fetchData();
      }, [companyId]); // Dependency array
      
    

    

    

    const handleBookmarkToggle = () => {
        setIsBookmarked((prev) => !prev);
    };

    const handleAddReview = () => {
      checkLogin(() => {
        navigate(`/reviews/${company.companyDocId}`);
      });
    };
  


    
    const toggleBookmark = async () => {
      checkLogin(async () => {
        try {
          const bookmarkDocRef = doc(db, "bookmarks", companyId);
          const bookmarkData = {
            categoryType: company.categoryType || "",
            companyDocId: companyId,
            companyImageUrl: company.coverImages[currentImageIndex] || "",
            companyName: company.companyName || "",
            companyUid: company.companyUid || "",
            createdTime: Timestamp.now(),
            customerId: localStorage.getItem("customerUid"),
            docId: bookmarkDocRef.id,
            isBookMarked: true,
            starCount: company.totalStarCount || 0,
          };
  
          const bookmarkDocSnap = await getDoc(bookmarkDocRef);
  
          if (bookmarkDocSnap.exists()) {
            await deleteDoc(bookmarkDocRef);
            setIsBookmarked(false);
          } else {
            await setDoc(bookmarkDocRef, bookmarkData);
            setIsBookmarked(true);
          }
        } catch (error) {
          console.error("Error toggling bookmark:", error);
        }
      });
    };

    // Touch Events
    const handleTouchStart = (e) => {
        startX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        currentX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
        handleSwipeEnd();
    };

    // Mouse Events
    const handleMouseDown = (e) => {
        setIsDragging(true);
        startX.current = e.clientX;
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            currentX.current = e.clientX;
        }
    };

    const handleMouseUp = () => {
        if (isDragging) {
            handleSwipeEnd();
            setIsDragging(false);
        }
    };

    // Common swipe logic
    const handleSwipeEnd = () => {
        const swipeDistance = currentX.current - startX.current;
        const minSwipeDistance = 50;

        if (Math.abs(swipeDistance) > minSwipeDistance && company?.coverImages) {
            if (swipeDistance > 0) {
                // Swipe right - go to previous image
                setCurrentImageIndex((prev) =>
                    prev === 0 ? company.coverImages.length - 1 : prev - 1
                );
            } else {
                // Swipe left - go to next image
                setCurrentImageIndex((prev) =>
                    prev === company.coverImages.length - 1 ? 0 : prev + 1
                );
            }
        }
    };

    const handleEnquiryClick = () => {
      checkLogin(() => {
        navigate("/enquiry", {
          state: {
            customerId: localStorage.getItem("customerUid"),
            companyDocId: companyId,
            companyName: company.companyName || "",
            companyAddress: company.companyAddress || "",
            categoryType: company.categoryType || "",
            areaOfOperation: company.areaOfOperation || [],
            about: company.about || "",
          },
        });
      });
    };
      

      const handleConnectClick = () => {
        setShowWebsite(true);
    };
    const handleOutsideClick = (event) => {
        if (websiteRef.current && !websiteRef.current.contains(event.target)) {
            setShowWebsite(false);
        }
    };

    const truncateDescription = (description, wordLimit) => {
        const words = description.split(" ");
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(" ") + "...";
        }
        return description;
    };
    
    
    useEffect(() => {
        if (showWebsite) {
            document.addEventListener("mousedown", handleOutsideClick);
        } else {
            document.removeEventListener("mousedown", handleOutsideClick);
        }
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [showWebsite]);
    
    

    // Cleanup mouse events
    useEffect(() => {
        const handleGlobalMouseUp = () => {
            setIsDragging(false);
        };

        document.addEventListener("mouseup", handleGlobalMouseUp);
        return () => {
            document.removeEventListener("mouseup", handleGlobalMouseUp);
        };
    }, []);

    if (!company) {
        return <div className="text-center py-8 text-gray-500"></div>;
    }



    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true
    };



    if (isLoading) {
        return (
          <div className="flex items-center justify-center min-h-screen">
            <div className="loader border-t-4 border-b-4 border-gray-500 rounded-full w-8 h-8 animate-spin"></div>
          </div>
        );
      }
    return (
        <>
       <LoginAlertComponent />

        <Helmet>
                        <title>CATEGORY</title>
                        <meta name="description" content="Professional Event Photography Services
        Capturing Candid Moments for Every Event
        Wedding and Party Photography Experts
        Corporate Event Photography Solutions
        Best Photographers for Events Near You
        " />
                        <link rel="canonical" href="/detailpage" />
            
                    </Helmet>

                    
        <div className="flex flex-col items-center min-h-screen lg:w-[88%] bg-gray-100 pt-4 overflow-hidden">
             <h1 className=" text-center text-black sm:text-2xl lg:text-xl mt-2 font-normal font-inter ">
                Company Details
            </h1>
            
        {/* ✅ Header (Fixed at Top, Outside Details Section) */}
        <div className="lg:w-[88%] fixed top-0 left-0 right-0   py-4 px-3 flex items-center z-10">
        <BiChevronLeft 
    className="text-3xl text-gray-700 cursor-pointer"
    onClick={() => navigate("/categories")} 
/>

           
        </div>
            
            <div className="w-full max-w-screen-sm   overflow-y-auto rounded-lg mt-4 ">
                {/* Universal Swipeable Image Slider */}
                <div className="relative h-64  rounded-t-lg overflow-hidden">
                    {company.coverImages && company.coverImages.length > 0 ? (
                        <div
                            onTouchStart={handleTouchStart}
                            onTouchMove={handleTouchMove}
                            onTouchEnd={handleTouchEnd}
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseUp={handleMouseUp}
                            onMouseLeave={handleMouseUp}
                            className={`h-full w-full cursor-grab ${isDragging ? "cursor-grabbing" : ""}`}
                        >
                            <img
                                src={company.coverImages[currentImageIndex]}
                                alt={`Cover ${currentImageIndex + 1}`}
                                className="w-full h-64 object-cover select-none"
                                draggable="false"
                            />
                            {/* Bookmark Icon */}
                            <div
    className="absolute top-4 right-4 w-10 h-10 bg-black rounded-full flex items-center justify-center cursor-pointer"
    onClick={toggleBookmark}
>
    {isBookmarked ? (
        <FaBookmark className="text-white" />
    ) : (
        <FaRegBookmark className="text-white" />
    )}
</div>

                            {/* Dot indicators */}
                            <div className="absolute bottom-4 left-0 right-0 flex justify-center gap-2">
                                {company.coverImages.map((_, index) => (
                                    <div
                                        key={index}
                                        className={`w-2 h-2 rounded-full ${
                                            currentImageIndex === index
                                                ? "bg-white"
                                                : "bg-white/50"
                                        }`}
                                    />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="h-64 flex items-center justify-center bg-gray-200">
                            <p className="text-gray-500">No cover images available</p>
                        </div>
                    )}
                </div>

                {/* Rest of the content remains the same */}
                <div className="flex items-center justify-between p-4">
                    <div className="w-3/4">
                        <h1 className="text-md sm:text-xl font-semibold text-black truncate font-inter">{company.companyName}</h1>
                        <p className="text-gray-600 text-sm sm:text-sm font-inter">{company.companyAddress}</p>
                    </div>
                    <img
                        src={company.logo || "/api/placeholder/64/64"}
                        alt="Company Logo"
                        className="w-12 h-12 sm:w-16 sm:h-16 object-cover rounded-md shadow-md"
                    />
                </div>

                {/* 📌 Ratings & Reviews Section (Fixed) */}
                {/* 📌 Ratings & Reviews Section (Fixed) */}
{/* 📌 Ratings & Reviews Section */}
<div className="px-4 py-3 border-t border-gray-200">
    {/* Star Ratings & Review */}
    <div className="flex items-center">
        {[...Array(5)].map((_, i) => {
            const avgRating = (company.totalStarCount / (company.totalReviewCount || 1)) || 0;
            return (
                <span key={i} className={`text-lg ${i < Math.round(avgRating) ? "text-black" : "text-gray-300"}`}>
                    ★
                </span>
            );
        })}
        <span className="ml-2 text-black font-bold text-sm sm:text-base">
            {((company.totalStarCount / (company.totalReviewCount || 1)) || 0).toFixed(1)}
        </span>

        {company.totalReviewCount > 0 ? (
            <button
                onClick={() => navigate(`/reviews/${company.companyDocId}`)}
                className="ml-3 text-gray-400 text-xs sm:text-sm"
            >
                {company.totalReviewCount} Reviews
            </button>
        ) : (
          <button
          onClick={handleAddReview}
          className="ml-3 text-gray-400 text-xs sm:text-sm"
        >
                Add Review
            </button>
        )}
    </div>

    {/* 📝 About Section (Now Below Ratings) */}
    {company.about && (
        <div className="mt-2 text-gray-600 text-sm sm:text-base font-inter">
            <p>{company.about}</p>
        </div>
    )}
</div>







{packages.length > 0 && (
    <div className="px-6 py-4 border-t border-gray-200">
        <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-inter font-normal text-gray-800">Packages</h2>
            {/* "Know More" Button */}
            <button
                onClick={() => navigate("/packages", { state: { companyId } })}
                className="text-black font-inter text-sm  hover:text-gray-700 focus:outline-none"
            >
                .. know more
            </button>
        </div>

        {/* Horizontal Scroll Container */}
        <div className="flex overflow-x-auto space-x-4 scrollbar-hide">
            {packages.map((pkg) => (
                <div key={pkg.id} className="flex-none w-64 rounded-lg overflow-hidden shadow-md relative">
                    {/* Package Image with Gradient, Title, and Description */}
                    {pkg.images && pkg.images.length > 0 && (
                        <div className="relative">
                            <img
                                src={pkg.images[0]}
                                alt={`Package ${pkg.packageTitle}`}
                                className="w-full h-40 object-cover rounded-lg"
                            />
                            <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70"></div>
                            <div className="absolute bottom-0 left-2 text-white text-left p-2 font-inter">
                                {/* Package Title */}
                                <h3 className="text-sm font-normal font-inter">{pkg.packageTitle}</h3>
                                {/* Truncated Package Description */}
                                <p className="text-xs mt-1">
                                    {pkg.packageDescription.length > 30
                                        ? pkg.packageDescription.slice(0, 30) + "..."
                                        : pkg.packageDescription}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    </div>
)}










                {/* What We Offer Section */}
                {/* What We Offer Section */}
{company.offers && (
    <div className="px-6 py-4 border-t border-gray-200">
        <h2 className="text-sm sm:text-base font-inter font-normal text-black">What we offer</h2>
        <p className="mt-2 text-gray-600">{company.offers}</p>
    </div>
)}

{/* Why Us Section */}
{company.whyUs && (
    <div className="px-6 py-4 border-t border-gray-200">
        <h2 className="text-sm sm:text-basefont-inter font-normal text-black">Why Us</h2>
        <p className="mt-2 text-gray-600">{company.whyUs}</p>
    </div>
)}


  {/* Recent Works (Posts Carousel) */}
  {recentWorks.length > 0 && (
    <div className="px-6 py-4 border-t border-gray-200">
        <h2 className="text-lg font-inter font-normal text-gray-800 mb-4">Recent Works</h2>

        {/* Horizontal Scroll Container */}
        <div className="flex overflow-x-auto space-x-4 scrollbar-hide">
            {recentWorks.map((post) => (
                <div
                    key={post.id}
                    className="flex-none w-64 rounded-lg overflow-hidden shadow-md relative"
                >
                    {/* Image */}
                    <img
                        src={post.image}
                        alt="Recent Work"
                        className="w-full h-40 object-cover"
                    />
                    {/* Description */}
                    <div className="absolute text-sm bottom-0 left-0 bg-black bg-opacity-50 text-white font-inter p-2 text-left">
                        {post.description}
                    </div>
                </div>
            ))}
        </div>
    </div>
)}



          {/* Buttons Section */}
{/* Buttons Section */}
{/* Buttons Section */}
<div
  className={`px-6 py-3 ${
    showWebsite ? "bg-white" : "bg-black"
  } flex flex-col items-center justify-center rounded-lg sticky bottom-0 left-0 w-full md:relative`}
>
  {showWebsite ? (
    // Website and Social Media Links
    <div
      ref={websiteRef}
      className="w-[90%] max-w-sm mx-auto bg-customGray shadow-lg rounded-xl p-3 flex flex-col items-center gap-3"
    >
      {/* Website */}
      {company.website?.trim() && (
        <div
          className="w-full rounded-lg p-2 flex items-center gap-2 cursor-pointer"
          onClick={() =>
            window.open(
              company.website.startsWith("http")
                ? company.website
                : `https://${company.website}`,
              "_blank"
            )
          }
        >
          <FaGlobe className="text-gray-600 text-lg" />
          <span className="text-gray-600 font-medium text-sm">Website</span>
        </div>
      )}

      {/* Instagram */}
      {company.instagram?.trim() && (
        <div
          className="w-full rounded-lg p-2 flex items-center gap-2 cursor-pointer"
          onClick={() =>
            window.open(
              company.instagram.startsWith("http")
                ? company.instagram
                : `https://${company.instagram}`,
              "_blank"
            )
          }
        >
          <img src="/insta.svg" alt="Instagram" className="w-5 h-5" />
          <span className="text-gray-600 font-medium text-sm">Instagram</span>
        </div>
      )}

      {/* Google Business Link */}
      {company.googleLink?.trim() && (
        <div
          className="w-full rounded-lg p-2 flex items-center gap-2 cursor-pointer"
          onClick={() =>
            window.open(
              company.googleLink.startsWith("http")
                ? company.googleLink
                : `https://${company.googleLink}`,
              "_blank"
            )
          }
        >
          <img src="/icons/google.png" alt="Google" className="w-5 h-5" />
          <span className="text-gray-600 font-medium text-sm">Google</span>
        </div>
      )}

      {/* Facebook */}
      {company.facebook?.trim() && (
        <div
          className="w-full rounded-lg p-2 flex items-center gap-2 cursor-pointer"
          onClick={() =>
            window.open(
              company.facebook.startsWith("http")
                ? company.facebook
                : `https://${company.facebook}`,
              "_blank"
            )
          }
        >
          <img src="/facebook1.svg" alt="Facebook" className="w-5 h-5" />
          <span className="text-gray-600 font-medium text-sm">Facebook</span>
        </div>
      )}
    </div>
  ) : (
    // Check if at least one valid link exists before showing the "Connect" button
    (company.website?.trim() ||
      company.instagram?.trim() ||
      company.googleLink?.trim() ||
      company.facebook?.trim()) ? (
      <div className="flex gap-4 w-full">
        <button
          onClick={() => setShowWebsite(true)}
          className="flex-1 py-2 bg-black font-inter text-white border border-white rounded-md font-medium"
        >
          Connect
        </button>
        <button
          onClick={handleEnquiryClick}
          className="flex-1 py-2 bg-black font-inter text-white border border-white rounded-md font-medium"
        >
          Enquiry
        </button>
      </div>
    ) : (
      // Show only Enquiry button if no links exist
      <button
        onClick={handleEnquiryClick}
        className="w-full py-2 bg-black text-white border border-white rounded-md font-medium"
      >
        Enquiry
      </button>
    )
  )}
</div>






                {/* Website Section (Shown on Connect Click) */}
              


            </div>
            <Hamburger />

        </div>
        </>
    );
};

export default DetailPage;