import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const Card = ({ title, count, bgColor, textColor, icon, loading }) => {
  return (
    <div className={`${bgColor} rounded-lg shadow-sm p-4`}>
      <div className="flex justify-between items-start mb-2">
        <h2 className={`text-sm font-semibold ${textColor}`}>{title}</h2>
        <div
          className={`w-10 h-10 rounded-full ${
            bgColor === 'bg-gray-800' ? 'bg-red-600' : 'bg-[#D8393F]'
          } flex items-center justify-center`}
        >
          <img src={icon} alt={`${title} icon`} className="w-6 h-6" />
        </div>
      </div>
      <div className={`text-2xl font-bold ${textColor}`}>
        {loading ? (
          <FaSpinner className="animate-spin text-xl" /> // Display spinner when loading
        ) : (
          count
        )}
      </div>
      <p
        className={`text-xs mt-6 ${
          bgColor === 'bg-gray-800' ? 'text-green-400' : 'text-green-600'
        }`}
      >
        {/* Additional Info or Counts */}
      </p>
    </div>
  );
};

export default Card;
