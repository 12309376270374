import React, { useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { showToast } from "../toast";
import { Toast } from "../toast";
import RingSpinner from "../loaderspinner";
import Hamburger from "../Hamburger/Hamburger";
import { Helmet } from "react-helmet-async";

const locations = [
  { name: "Alappuzha", lat: 9.4981, lon: 76.3388 },
  { name: "Ernakulam", lat: 9.9816, lon: 76.2999 },
  { name: "Idukki", lat: 9.85, lon: 76.97 },
  { name: "Kannur", lat: 11.8745, lon: 75.3704 },
  { name: "Kasaragod", lat: 12.4996, lon: 74.986 },
  { name: "Kollam", lat: 8.8932, lon: 76.6141 },
  { name: "Kottayam", lat: 9.5915, lon: 76.5222 },
  { name: "Kozhikode", lat: 11.2588, lon: 75.7804 },
  { name: "Malappuram", lat: 11.0513, lon: 76.0711 },
  { name: "Palakkad", lat: 10.7867, lon: 76.6548 },
  { name: "Pathanamthitta", lat: 9.2647, lon: 76.787 },
  { name: "Trivandrum", lat: 8.5241, lon: 76.9366 },
  { name: "Thrissur", lat: 10.5276, lon: 76.2144 },
  { name: "Wayanad", lat: 11.6854, lon: 76.132 }
];

const ChooseLocation = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Handle location selection
  const handleSelect = (location) => {
    setSelectedLocation(location);
    showToast(`${location.name} selected!`, "success");
  };

  // Handle Submit Button Click
  const handleSubmit = () => {
    if (!selectedLocation) {
      showToast("Please select a location before submitting!", "error");
      return;
    }

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      showToast(`Location ${selectedLocation.name} submitted successfully!`, "success");

      // Store selected location in localStorage
      localStorage.setItem("selectedDistrict", selectedLocation.name);

      // Navigate to `/homepage` instead of `/`
      navigate("/", { state: { name: selectedLocation.name } });
    }, 2000);
  };

  return (
   <>
    <Helmet>
    <title>Choose Location - Dreamin Event Management</title>
    <meta 
      name="description" 
      content="Select your location to explore personalized event management services with Dreamin. Plan events tailored to your area today!" 
    />
    <link rel="canonical" href="/choose-location" />
  </Helmet>

    <div className="flex flex-col items-center min-h-screen w-full bg-white px-4 relative font-inter">
      {/* Toast Container */}
      <Toast />

      {/* Back Button (Fix: Always Navigate to Homepage) */}
      <button 
        onClick={() => navigate("/")} 
        className="absolute top-4 left-4 flex items-center text-black text-2xl"
      >
        <BiChevronLeft size={32} />
      </button>

      {/* Heading */}
      <h2 className="text-sm sm:text-sm md:text-xl font-semibold text-black my-6 text-center">Choose Location</h2>

      {/* Location Grid */}
      <div className="grid grid-cols-2 gap-4 w-full max-w-md sm:max-w-lg md:max-w-xl pb-20">
        {locations.map((location, index) => (
          <button
            key={index}
            onClick={() => handleSelect(location)}
            className={`w-full py-2 border border-black rounded-lg text-center text-black 
              ${selectedLocation?.name === location.name ? "bg-black text-white" : "hover:bg-gray-200"}`}
          >
            {location.name}
          </button>
        ))}
      </div>

      {/* Fixed Submit Button at the Bottom */}
      <div className="fixed bottom-0 w-full bg-white py-4 px-6 flex justify-center shadow-md">
        <button
          onClick={handleSubmit}
          className="w-full max-w-md sm:max-w-lg md:max-w-xl bg-black text-white py-3 rounded-full text-lg"
        >
          {loading ? <RingSpinner size={20} color="#fff" /> : "Submit"}
        </button>
      </div>
      <Hamburger />
    </div>
    </>
  );
};

export default ChooseLocation;
