import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Components/header";
import Sidebar from "../Components/sidebar";
import '../Components/hexagon_body.css';

const smallScreenUrls = {
  logistics: './logisticsmobile.svg',
  financial_institutions: './financialinstituitionsmobile.svg',
  start_up: './startupmobile.png',
  legal: './legal_mobile.svg',
  precious_stones_metals: './precious_mobile.png',
  investor: './investormobile.png',
  seller: './sellermobile.png',
  market_place: './marketplacemobile.png',
  projects: './projectsmobile.png',
  buyer: './buyermobile35827.svg'
};

const largeScreenUrls = {
  logistics: './logistics.svg',
  financial_institutions: './finacial.svg',
  start_up: './start-up(2).svg',
  legal: './Legal(2).svg',
  precious_stones_metals: './precious(2).svg',
  investor: './investor(2).svg',
  seller: './seller(2).svg',
  market_place: './marketplace(2).svg',
  projects: './projects(2).svg',
  buyer: './buyer(2).svg'
};

const MainHome = () => {
  const [imageUrls, setImageUrls] = useState(largeScreenUrls);
  const navigate = useNavigate();

  const handleImageClick = (category) => {
    // Store only the category name in localStorage with the key 'category'
    localStorage.setItem('category', category);

    // Navigate to the subcategories page
    navigate('/subcategories_page');
  };

  return (
    <>
      <Header />
      <div className="flex h-screen bg-[#F3F3F3]">
        <Sidebar />
        <div className="flex-1 flex flex-col overflow-hidden">
          <div className="hex-grid-container-wrapper justify-content-center items-center pt-20">
            <div className='hex-grid-container justify-content-center items-center'>
              <div className='hex-item logistics' onClick={() => handleImageClick('logistics')}>
                <img src={imageUrls.logistics} alt="logistics" />
              </div>
              <div className='hex-item financial_institutions' onClick={() => handleImageClick('financial_institutions')}>
                <img src={imageUrls.financial_institutions} alt="financialinstitutions" />
              </div>
              <div className='hex-item start_up' onClick={() => handleImageClick('start_up')}>
                <img src={imageUrls.start_up} alt="startup" />
              </div>
              <div className='hex-item legal' onClick={() => handleImageClick('legal')}>
                <img src={imageUrls.legal} alt="legal" />
              </div>
              <div className='hex-item precious_stones_metals' onClick={() => handleImageClick('precious_stones_metals')}>
                <img src={imageUrls.precious_stones_metals} alt="preciousstones" />
              </div>
              <div className='hex-item investor' onClick={() => handleImageClick('investor')}>
                <img src={imageUrls.investor} alt="invest" />
              </div>
              <div className='hex-item seller' onClick={() => handleImageClick('seller')}>
                <img src={imageUrls.seller} alt="seller" />
              </div>
              <div className='hex-item market_place' onClick={() => handleImageClick('market_place')}>
                <img src={imageUrls.market_place} alt="marketplace" />
              </div>
              <div className='hex-item projects' onClick={() => handleImageClick('projects')}>
                <img src={imageUrls.projects} alt="projects" />
              </div>
              <div className='hex-item buyer' onClick={() => handleImageClick('buyer')}>
                <img src={imageUrls.buyer} alt="buyer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainHome;
