import React, { useState } from 'react';
import Modal from 'react-modal';
import { AiOutlineClose } from 'react-icons/ai';

const PostAlertComponent = ({ isModalOpen, closeModal, reportData }) => {
  const [isImageModalOpen, setImageModalOpen] = useState(false);

  if (!reportData) {
    return null; // If no data is passed, render nothing
  }

  const {
    reporterName,
    reporterProfileImage,
    reportingDate,
    issueDescription,
    reporterEmail,
    reportedPostText,
    reportedPostImageUrl,
  } = reportData;

  const handleImageClick = () => {
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  return (
    <>
      {/* Main Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Issue Details Modal"
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75"
        overlayClassName="Overlay"
      >
        <div className="relative bg-white rounded-lg p-6 max-w-3xl w-full shadow-lg flex sm:flex-row flex-col font-poppins">
          {/* Close Button */}
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-black text-2xl"
          >
            <AiOutlineClose className="text-gray-700" />
          </button>

          {/* Details Section */}
          <div className="flex-1 pr-6">
            <h2 className="text-lg sm:text-xl font-semibold mb-4 pb-2 border-b border-gray-300 w-full font-poppins">
              Issue Details
            </h2>

            {/* Reporter Information */}
            <div className="flex items-center space-x-4">
              <img
                src={reporterProfileImage || '/default-profile.png'}
                alt={`${reporterName}'s profile`}
                className="w-10 h-10 sm:w-12 sm:h-12 rounded-full"
              />
              <div>
                <p className="text-sm sm:text-base font-semibold">Reported By</p>
                <p className="text-xs sm:text-sm text-gray-600">{reporterName}</p>
              </div>
            </div>

            {/* Reporting Date */}
            <div className="mt-4">
              <p className="text-sm sm:text-base font-semibold">Reporting Date</p>
              <p className="text-xs sm:text-sm text-gray-600">
                {reportingDate
                  ? new Date(reportingDate).toLocaleDateString()
                  : 'Unknown Date'}
              </p>
            </div>

            {/* Issue Description */}
            <div className="mt-4">
              <p className="text-sm sm:text-base font-semibold">Issue Description</p>
              <p className="text-xs sm:text-sm text-gray-600">{issueDescription}</p>
            </div>

            {/* Reported Post Text */}
            <div className="mt-4">
              <p className="text-sm sm:text-base font-semibold">Reported Post</p>
              <p className="text-xs sm:text-sm text-gray-600">
                {reportedPostText || 'No content provided'}
              </p>
            </div>
          </div>

          {/* Reported Post Image */}
          <div className="flex-shrink-0 w-full lg:mt-6 sm:w-64 mt-6 sm:mt-0 h-40 sm:h-64 bg-gray-100 rounded-lg overflow-hidden flex items-center justify-center">
            {reportedPostImageUrl ? (
              <img
                src={reportedPostImageUrl}
                alt="Reported Post"
                className="w-full h-full object-cover cursor-pointer"
                onClick={handleImageClick} // Open image modal on click
              />
            ) : (
              <p className="text-xs sm:text-sm text-gray-500">No Image Available</p>
            )}
          </div>
        </div>
      </Modal>

      {/* Image Modal */}
      {reportedPostImageUrl && (
        <Modal
          isOpen={isImageModalOpen}
          onRequestClose={closeImageModal}
          contentLabel="Large Image Modal"
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90"
          overlayClassName="Overlay"
        >
          <div className="relative bg-black p-4 rounded-lg max-w-4xl w-full">
            <button
              onClick={closeImageModal}
              className="absolute top-4 right-4 text-white text-2xl"
            >
              <AiOutlineClose />
            </button>
            <img
              src={reportedPostImageUrl}
              alt="Large Reported Post"
              className="w-full h-auto object-contain"
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default PostAlertComponent;
