import React, { useState, useEffect } from "react";

const GeneralInformationForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState(""); // New Email State
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(
        "https://dreamin-backend-646690260914.us-central1.run.app/api/v1/billings/session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ amount: 999, customer_phone: phone }),
        }
      );

      const data = await response.json();

      if (response.ok && data?.data?.payment_session_id) {
        const cashfree = window.Cashfree({ mode: "production" });
        cashfree.checkout({
          paymentSessionId: data.data.payment_session_id,
          redirectTarget: "_self",
        });
      } else {
        alert("Failed to create payment session. Please try again.");
      }
    } catch (error) {
      alert("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen ">
      <div className="w-full max-w-md p-10 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center bg-gradient-to-r from-gray-700 to-gray-800 text-transparent bg-clip-text">
          General Information
        </h2>
        <form onSubmit={handleSubmit} className="mt-6">
          <div className="mb-6">
            <label htmlFor="name" className="block text-gray-300 text-sm mb-2">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="w-full p-2 text-lg bg-transparent border-b border-gray-500 text-black focus:outline-none focus:border-gray-300"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          {/* Email Field */}
          <div className="mb-6">
            <label htmlFor="email" className="block text-gray-300 text-sm mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full p-2 text-lg bg-transparent border-b border-gray-500 text-black focus:outline-none focus:border-gray-300"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="mb-6">
            <label htmlFor="phone" className="block text-gray-300 text-sm mb-2">
              Phone Number
            </label>
            <input
              type="text"
              id="phone"
              className="w-full p-2 text-lg bg-transparent border-b border-gray-500 text-black focus:outline-none focus:border-gray-300"
              placeholder="Enter your phone number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className={`w-full py-2 text-lg font-medium rounded-md text-black transition-all duration-300 ${
              loading
                ? "bg-gray-500 cursor-not-allowed"
                : "bg-gradient-to-r from-gray-500 to-gray-400 hover:from-gray-600 hover:to-gray-500"
            }`}
            disabled={loading}
          >
            {loading ? "Processing..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default GeneralInformationForm;
