// executiveController.js

import { getExecutives,addExecutive,deleteExecutive } from "./executives";

export const fetchExecutives = async (skip, limit) => {
  try {
    const res = await getExecutives(skip, limit);
    return res.data;
  } catch (error) {
    console.error('Error fetching executives:', error);
    throw error;
  }
};

export const addNewExecutive = async (email, country, name, password) => {
  try {
    const response = await addExecutive(email, country, name, password);
    return response;
  } catch (error) {
    console.error('Error adding executive:', error);
    throw error;
  }
};

export const removeExecutive = async (executiveId) => {
  try {
    const res = await deleteExecutive(executiveId);
    return res;
  } catch (error) {
    console.error('Error deleting executive:', error);
    throw error;
  }
};