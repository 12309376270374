import React, { useState, useEffect } from 'react';
import Header from '../Components/header';
import Sidebar from '../Components/sidebar';
import Tile from '../Components/tile';
import Modal from '../Components/pop_up';
import AddSubcategoryModal from '../Components/sub_category_popup';
import { showToast } from '../Components/toast';
import { FaSpinner } from 'react-icons/fa';
import { fetchSubcategories } from '../../controller/subcategories';
import { saveSubcategory } from '../../controller/subcategories';
import { updateSubcategory } from '../../controller/subcategories';
import { deleteSubcategory } from '../../controller/subcategories';


const Subcategories = () => {
  const [subcategories, setSubcategories] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [modalText, setModalText] = useState('');
  const [editSubcategoryName, setEditSubcategoryName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSubcategories(setSubcategories, setLoading); // Use the controller function to fetch subcategories
  }, []);

  const handleDelete = (index) => {
    setCurrentIndex(index);
    setModalText(`Are you sure you want to delete ${subcategories[index].name}?`);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    const subcategory = subcategories[currentIndex];
    deleteSubcategory(subcategory.id, subcategories, setSubcategories, setLoading, setIsDeleteModalOpen); // Use the controller function to delete
  };

  const handleAdd = (subcategoryName) => {
    saveSubcategory(subcategoryName, subcategories, setSubcategories, setLoading, setIsAddModalOpen); // Use the controller function to add
  };

  const handleEdit = (index) => {
    setCurrentIndex(index);
    setEditSubcategoryName(subcategories[index].name);
    setIsEditModalOpen(true);
  };

  const confirmEdit = (newName) => {
    const subcategory = subcategories[currentIndex];
    updateSubcategory(subcategory.id, newName, subcategories, setSubcategories, setLoading, setIsEditModalOpen); // Use the controller function to edit
  };

  return (
    <>
      <Header />
      <div className="flex h-screen bg-[#EFEFEF] font-poppins">
        <Sidebar />
        <div className="flex-1 flex flex-col overflow-hidden">
          <main className="flex-1 overflow-x-hidden overflow-y-auto p-6">
            <div className="flex justify-between items-center mb-4">
              <h1 className="text-xl font-semibold">Subcategories</h1>
              <button onClick={() => setIsAddModalOpen(true)} className="bg-[#DB0000] text-white px-4 py-2 rounded">
                + Add
              </button>
            </div>

            {loading ? (
              <div className="flex justify-center items-center h-64">
                <FaSpinner className="animate-spin text-4xl text-gray-500" />
              </div>
            ) : (
              <div className="bg-white shadow rounded-lg overflow-hidden">
                {subcategories.length > 0 ? (
                  subcategories.map((subcategory, index) => (
                    <Tile
                      key={index}
                      name={subcategory.name}
                      onDelete={() => handleDelete(index)}
                      onEdit={() => handleEdit(index)}
                    />
                  ))
                ) : (
                  <p>No subcategories available.</p>
                )}
              </div>
            )}
          </main>
        </div>
      </div>
      <Modal 
        isOpen={isDeleteModalOpen} 
        onClose={() => setIsDeleteModalOpen(false)} 
        onConfirm={confirmDelete} 
        text={modalText} 
      />
      <AddSubcategoryModal 
        isOpen={isAddModalOpen} 
        onClose={() => setIsAddModalOpen(false)} 
        onAdd={handleAdd} 
      />
      <AddSubcategoryModal 
        isOpen={isEditModalOpen} 
        onClose={() => setIsEditModalOpen(false)} 
        onAdd={confirmEdit} 
        initialName={editSubcategoryName} 
        isEditMode={true} 
      />
    </>
  );
};

export default Subcategories;
