import React, { useState } from 'react';
import PhotoUpload from './photo_upload';
import { editCustomerById } from '../../controller/dreamin_home';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../services/firebase';
import PropTypes from "prop-types";

const EditDetailsComponent = ({ categoryData, onClose }) => {
  const [name, setName] = useState(categoryData.name || '');
  const [phoneNumber, setPhoneNumber] = useState(categoryData.phone_number || '');
  const [files, setFiles] = useState(categoryData.image ? [{ url: categoryData.image }] : []);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSave = async () => {
    if (!categoryData.id) {
      setError("Customer ID is missing!");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      let uploadedImageUrl = files.length > 0 ? files[0].url : '';

      if (files.length > 0 && files[0].file) {
        const storageRef = ref(storage, `customerImages/${categoryData.id}`);
        await uploadBytes(storageRef, files[0].file);
        uploadedImageUrl = await getDownloadURL(storageRef);
      }

      const updatedDetails = {
        name,
        phone_number: phoneNumber,
        image: uploadedImageUrl,
      };

      console.log('API Payload:', updatedDetails);

      const response = await editCustomerById(categoryData.id, updatedDetails);
      console.log('API Response:', response);

      onClose();
    } catch (error) {
      setError("Failed to save details. Please try again.");
      console.error('Error updating details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-6 w-full max-w-lg relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          ✕
        </button>
        <h2 className="text-xl font-semibold mb-4">Edit Details</h2>
        {error && <p className="text-red-500">{error}</p>}
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 font-medium mb-2">
            Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full border border-gray-300 rounded px-3 py-2"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="phoneNumber" className="block text-gray-700 font-medium mb-2">
            Phone Number
          </label>
          <input
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="w-full border border-gray-300 rounded px-3 py-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">User Photo</label>
          <PhotoUpload
            files={files}
            setFiles={setFiles}
            svgImagePath="/assets/upload-icon.svg"
            editIconPath="/assets/edit-icon.svg"
            bgColor="#F3F3F3"
            borderRadius="8px"
            width="130px"
            height="190px"
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-[#222222] text-white rounded hover:bg-[#222222]"
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
};

EditDetailsComponent.propTypes = {
  categoryData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    phone_number: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditDetailsComponent;
