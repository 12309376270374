import React from "react";
import { useNavigate } from 'react-router-dom';
import { BiChevronLeft } from "react-icons/bi"; // Import the left arrow icon


const PrivacyPolicy = () => {
    const navigate = useNavigate();

    return (
        <div className="container mx-auto px-4 font-inter">
            {/* Back Button */}
           
            <div
                className="absolute top-6 left-6 md:left-10 lg:left-12 xl:left-16 text-gray-700 cursor-pointer"
                onClick={() => navigate(-1)} // Navigate back to the previous page
            >
                <BiChevronLeft className="text-3xl md:text-4xl" />
            </div>
            {/* Privacy Policy Container */}
            <div className="rounded-lg p-6 sm:w-full md:w-3/4 lg:w-2/3 mx-auto max-h-screen overflow-y-auto">
                <h2 className="text-xl font-normal font-inter text-gray-800 mb-6 text-center">Privacy Policy</h2>
                <div className="space-y-6">
                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Information Collection</h3>
                        <p className="text-gray-600 font-inter text-sm">We may collect personal information such as your name, email address, phone number, and location when you register for an account or use our services. We also collect information about your interactions with the app, such as search queries and transactions.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Use of Information</h3>
                        <p className="text-gray-600 font-inter text-sm">We use your information to provide and improve our services, communicate with you, and personalize your experience on Dream In. We may also use aggregated data for analytical purposes.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Information Sharing</h3>
                        <p className="text-gray-600 font-inter text-sm">Business profiles are shared with app users, and service providers are engaged to enhance app functionality.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Data Security</h3>
                        <p className="text-gray-600 font-inter text-sm">We take reasonable measures to protect your information from unauthorized access, use, or disclosure.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Children's Privacy</h3>
                        <p className="text-gray-600 font-inter text-sm">Dream In is not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13. If you believe we have collected information from a child under 13, please contact us immediately.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
