import React, { useState } from 'react';
import LoaderSpinner from './loader_spinner';
const Modal = ({ isOpen, onClose, onConfirm, text }) => {
    const [loading, setLoading] = useState(false); // State to manage loading

    const handleConfirm = async () => {
        setLoading(true); // Show loading spinner
        await onConfirm(); // Wait for onConfirm function to complete
        setLoading(false); // Hide loading spinner
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full">
                <div className="flex justify-end">
                    <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="text-center mb-6">
                    <h2 className="text-lg font-poppins mb-2">
                        {text}
                    </h2>
                </div>
                <div className="flex justify-center space-x-4">
                    <button
                        onClick={handleConfirm}
                        className="bg-[#DB0000] text-white px-16 py-1 rounded text-lg flex justify-center items-center"
                        disabled={loading} // Disable the button while loading
                    >
                        {loading ? <LoaderSpinner visible={true} /> : 'Yes'}
                    </button>
                    <button
                        onClick={onClose}
                        className="border border-[#DB0000] text-red-500 px-16 py-1 rounded text-lg"
                        disabled={loading} // Disable the "No" button when loading
                    >
                        No
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
