import { useState } from "react";
import LoginAlert from "../components/Hamburger/login_alert_homepage";

const useCheckLogin = () => {
  const [showLoginAlert, setShowLoginAlert] = useState(false);

  const checkLogin = (callback) => {
    const customerUid = localStorage.getItem("customerUid");
    if (customerUid) {
      callback(); // Execute the action if the user is logged in
    } else {
      setShowLoginAlert(true); // Show the alert if the user is not logged in
    }
  };

  const closeLoginAlert = () => {
    setShowLoginAlert(false); // Close the alert
  };

  const LoginAlertComponent = () =>
    showLoginAlert && (
      <LoginAlert
        message="Please Login to Continue." // Default message to avoid undefined
        onClose={closeLoginAlert}
      />
    );

  return { checkLogin, LoginAlertComponent }; // Return the login check function and alert component
};

export default useCheckLogin;
