import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { getCompanyById, deleteCompanyById } from '../../controller/dreamin_company';
import DeletePopup from './delete_pop_up';
import EditCompanyModal from './edit_company_details';
import { verifyCompanyById } from '../../controller/dreamin_company';


const CompanyDetailsModal = ({ companyId, onClose }) => {
    const [company, setCompany] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null); // State to store clicked image
    const [isVerifying, setIsVerifying] = useState(false);

    useEffect(() => {
        const fetchCompanyDetails = async () => {
            try {
                const response = await getCompanyById(companyId);
                setCompany(response?.data || null);
            } catch (error) {
                console.error("Error fetching company details:", error);
            } finally {
                setIsLoading(false);
            }
        };
    
        if (companyId) {
            fetchCompanyDetails();
        }
    }, [companyId]);
    

    const handleDelete = async () => {
        setIsDeleting(true);
        try {
            await deleteCompanyById(companyId);
            onClose();
        } catch (error) {
            console.error("Error deleting company:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleVerify = async () => {
        setIsVerifying(true);
        try {
            await verifyCompanyById(companyId);
            alert("Company Verified Successfully!"); 
        } catch (error) {
            console.error("Error verifying company:", error);
        } finally {
            setIsVerifying(false);
        }
    };

    const renderTextInput = (label, value) => (
        <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">{label}</label>
            <input
                type="text"
                value={value || 'N/A'}
                readOnly
                className="w-full px-3 py-2 border rounded-md bg-gray-100"
            />
        </div>
    );

    const renderTextArea = (label, value) => (
        <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">{label}</label>
            <textarea
                value={value || 'N/A'}
                readOnly
                className="w-full px-3 py-2 border rounded-md bg-gray-100"
                rows="3"
            />
        </div>
    );

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg p-6 w-11/12 md:w-3/4 max-h-[90vh] overflow-auto relative">
                <button
                    className="absolute top-2 right-6 text-gray-600 hover:text-gray-800"
                    onClick={onClose}
                >
                    ✕
                </button>

                {isLoading ? (
                    <div className="flex justify-center items-center h-64">
                        <FaSpinner className="animate-spin text-4xl text-gray-500" />
                    </div>
                ) : company ? (
                    <div>
                        {/* Company Logo, Name, and Action Buttons */}
                        <div className="flex items-center justify-between mb-6">
                            <div className="flex items-center space-x-4">
                                <img
                                    src={company.logo}
                                    alt="Company Logo"
                                    className="w-16 h-16 rounded-full border cursor-pointer"
                                    onClick={() => setSelectedImage(company.logo)}
                                />
                                <h2 className="text-2xl font-bold">{company.name}</h2>
                            </div>
                            <div className="flex space-x-2">
                                <button className="w-24 px-4 py-2 bg-[#222222] text-white rounded-md hover:bg-gray-600" onClick={() => setIsEditModalOpen(true)}>
                                    Edit
                                </button>
                                <button
                                    className="w-24 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-600"
                                    onClick={() => setIsDeletePopupOpen(true)}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>

                        {/* Two-Column Layout */}
                       {/* Two-Column Layout */}
                       <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                {renderTextInput('Category', company.category)}
                                {renderTextArea('Address', company.address)}
                                
                                {renderTextArea('Area of Operation', company.area_of_operations?.join(', '))}
                            </div>
                            <div>
                                {renderTextInput('District', company.district)}
                                {renderTextInput('Email', company.email)}
                                {renderTextInput('Phone Number', company.phone_number)}
                                
                                {renderTextInput('City', company.city)}
                            </div>
                        </div>

                        {/* About Section */}
                        <div className="mt-6">
                            {renderTextArea('About', company.about)}
                        </div>

                        {/* Social Links */}
                        <div className="mt-6">
                            <h3 className="text-lg font-semibold mb-2">Social Links</h3>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                {renderTextInput('Facebook', company.facebook)}
                                {renderTextInput('Google', company.google)}
                                {renderTextInput('Instagram', company.instagram)}
                            </div>
                        </div>

                        {/* Cover Images */}
                        <div className="mt-6">
                            <h3 className="text-lg font-semibold mb-2">Cover Images</h3>
                            <div className="flex space-x-2 overflow-x-auto">
                                {company.cover_images?.length > 0 ? (
                                    company.cover_images.map((image, index) => (
                                        <img
                                            key={index}
                                            src={image}
                                            alt={`Cover ${index + 1}`}
                                            className="w-36 h-36 rounded-md border cursor-pointer"
                                            onClick={() => setSelectedImage(image)}
                                        />
                                    ))
                                ) : (
                                    <p className="text-gray-500">No cover images available.</p>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <p className="text-gray-500">Company details not found.</p>
                )}

                {/* Close Button */}
               {/* Conditional Verify Button or Verified Label */}
               <div className="mt-6 flex justify-end space-x-3">
    {company ? (
        company.is_verified ? (
            <div className="px-4 py-2 bg-green-200 text-green-800 rounded font-semibold">
                Verified
            </div>
        ) : (
            <button
                className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                onClick={handleVerify}
                disabled={isVerifying}
            >
                {isVerifying ? <FaSpinner className="animate-spin" /> : "Verify"}
            </button>
        )
    ) : (
        <p className="text-gray-500">Loading...</p>
    )}
    <button
        className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
        onClick={onClose}
    >
        Close
    </button>
</div>


            </div>

            {/* Image Preview Modal */}
            {selectedImage && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50" onClick={() => setSelectedImage(null)}>
                    <div className="relative p-4">
                        <img src={selectedImage} alt="Preview" className="max-w-full max-h-screen rounded-lg p-6" />
                        <button className="absolute top-2 right-2 text-white text-2xl font-bold" onClick={() => setSelectedImage(null)}>✕</button>
                    </div>
                </div>
            )}

            {/* Image Preview Modal */}
            {selectedImage && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50 " onClick={() => setSelectedImage(null)}>
                    <div className="relative p-4">
                        <img src={selectedImage} alt="Preview" className="max-w-full max-h-screen rounded-lg p-6" />
                        <button className="absolute top-2 right-2 text-white text-2xl font-bold" onClick={() => setSelectedImage(null)}>✕</button>
                    </div>
                </div>
            )}

            {/* Delete Confirmation Popup */}
            {isDeletePopupOpen && (
                <DeletePopup
                    message="Are you sure you want to delete this company?"
                    onConfirm={handleDelete}
                    onCancel={() => setIsDeletePopupOpen(false)}
                    isLoading={isDeleting}
                />
            )}
            {isEditModalOpen && company && (
                <EditCompanyModal
                    company={company}
                    onClose={() => setIsEditModalOpen(false)}
                    onUpdate={(updatedCompany) => setCompany(updatedCompany)}
                />
            )}
        </div>
    );
};

export default CompanyDetailsModal;
