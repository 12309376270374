import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { firebaseConfig } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import Popup from "../pop_up";
import { BiChevronLeft } from "react-icons/bi";
import { FaCog, FaFileContract, FaLock, FaFile, FaSignOutAlt } from "react-icons/fa";
import Hamburger from "../Hamburger/Hamburger";
import { Helmet } from "react-helmet-async";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const Profile = () => {
    const [profileData, setProfileData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const customerUid = localStorage.getItem("customerUid");
                if (!customerUid) {
                    console.error("No customerUid found in localStorage.");
                    return;
                }

                const customersCollection = collection(db, "customer");
                const q = query(customersCollection, where("customerUid", "==", customerUid));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const customerDoc = querySnapshot.docs[0];
                    setProfileData(customerDoc.data());
                } else {
                    console.error("No customer data found for the given customerUid.");
                }
            } catch (error) {
                console.error("Error fetching profile data:", error);
            }
        };

        fetchProfileData();
        const unsubscribe = onAuthStateChanged(auth, () => {});

        return () => unsubscribe();
    }, [navigate]);

    const handleLogoutClick = () => {
        setShowPopup(true);
    };

    const confirmLogout = async () => {
        try {
            await signOut(auth);
            localStorage.clear();
            navigate("/");
        } catch (error) {
            console.error("Error signing out:", error);
        }
        setShowPopup(false);
    };

    return (
        <>
                <Helmet>
                    <title>Profile</title>
                    <meta name="description" content="View and manage your profile information with Dreamin." />
                    <link rel="canonical" href="/profile" />
        
                </Helmet>
        <div className="flex flex-col items-center min-h-screen px-4 sm:px-6 md:px-8 lg:px-0 font-inter">
            {/* Back Button */}
            <button onClick={() => navigate("/")} className="absolute left-4 top-4 text-gray-700">
                <BiChevronLeft className="text-3xl md:text-4xl" />
            </button>
    
            {/* Profile Section */}
            <div className="flex flex-col items-center mt-6 w-[90%] sm:w-[80%] md:w-[60%] lg:w-[50%] xl:w-[40%]">
                <h2 className="text-lg sm:text-xl font-normal font-inter text-black">Profile</h2>
                <div className="mt-4 w-24 h-24 sm:w-28 sm:h-28 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full overflow-hidden border-4 border-gray-300">
                    {profileData && (
                        <img
                            src={profileData.imageUrl}
                            alt="Profile"
                            className="w-full h-full object-cover"
                        />
                    )}
                </div>
                <h2 className="mt-4 text-md sm:text-lg md:text-xl font-semibold text-gray-900">
                    {profileData?.name || "Name not available"}
                </h2>
                <p className="text-gray-500 text-sm sm:text-md">{profileData?.phoneNumber || "Phone number not available"}</p>
            </div>
    
            {/* Settings Section */}
            <div className="w-[90%] sm:w-[80%] md:w-[60%] lg:w-[50%] xl:w-[40%] mt-6">
                <div
                    className="flex items-center justify-between bg-customGray px-4 py-3 sm:px-6 sm:py-4 rounded-xl cursor-pointer hover:bg-gray-300"
                    onClick={() => navigate("/settings")}
                >
                    <FaCog className="text-md sm:text-lg text-gray-700" />
                    <span className="flex-grow text-gray-800 text-sm sm:text-md font-medium ml-3">Settings</span>
                    <i className="fas fa-chevron-right text-gray-700"></i>
                </div>
            </div>
    
            {/* Terms, Privacy, and Community Section */}
            <div className="w-[90%] sm:w-[80%] md:w-[60%] lg:w-[50%] xl:w-[40%] mt-3 bg-customGray rounded-xl">
                <div
                    className="flex items-center justify-between px-4 py-3 sm:px-6 sm:py-4 cursor-pointer border-b border-gray-400 hover:bg-gray-300 rounded-t-xl"
                    onClick={() => navigate("/Termsandcondition")}
                >
                    <FaFileContract className="text-md sm:text-lg text-gray-700" />
                    <span className="flex-grow text-gray-800 text-sm sm:text-md font-medium ml-3">Terms and Conditions</span>
                    <i className="fas fa-chevron-right text-gray-700"></i>
                </div>
    
                <div
                    className="flex items-center justify-between px-4 py-3 sm:px-6 sm:py-4 cursor-pointer border-b border-gray-400 hover:bg-gray-300"
                    onClick={() => navigate("/privacypolicy")}
                >
                    <FaLock className="text-md sm:text-lg text-gray-700" />
                    <span className="flex-grow text-gray-800 text-sm sm:text-md font-medium ml-3">Privacy Policy</span>
                    <i className="fas fa-chevron-right text-gray-700"></i>
                </div>
    
                <div
                    className="flex items-center justify-between px-4 py-3 sm:px-6 sm:py-4 cursor-pointer rounded-b-xl hover:bg-gray-300"
                    onClick={() => navigate("/community")}
                >
                    <FaFile className="text-md sm:text-lg text-gray-700" />
                    <span className="flex-grow text-gray-800 text-sm sm:text-md font-medium ml-3">Community Guidelines</span>
                    <i className="fas fa-chevron-right text-gray-700"></i>
                </div>
            </div>
    
            {/* Sign Out Section */}
            <div className="w-[90%] sm:w-[80%] md:w-[60%] lg:w-[50%] xl:w-[40%] mt-3">
                <div
                    className="flex items-center justify-between bg-customGray px-4 py-3 sm:px-6 sm:py-4 rounded-xl cursor-pointer hover:bg-gray-300"
                    onClick={handleLogoutClick}
                >
                    <FaSignOutAlt className="text-md sm:text-lg text-gray-700" />
                    <span className="flex-grow text-gray-800 text-sm sm:text-md font-medium ml-3">Sign out</span>
                    <i className="fas fa-chevron-right text-gray-700"></i>
                </div>
            </div>
    
            {/* Logout Popup */}
            {showPopup && (
                <Popup
                    message="Are you sure you want to log out?"
                    onConfirm={confirmLogout}
                    onCancel={() => setShowPopup(false)}
                />
            )}
    
            <Hamburger />
        </div>
        </>
    );
    
};

export default Profile;
