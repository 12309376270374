
//production
const BASE_URL = "https://dreamin-backend-646690260914.us-central1.run.app/dashboard/api/v1";

//development
// dreamin


const LOGIN_URL = `${BASE_URL}/auth/login`;
// Customer APIs
const GET_CUSTOMER_LIST = `${BASE_URL}/customer`;
const GET_CUSTOMER_BY_ID = (id) => `${BASE_URL}/customer/${id}`;
const EDIT_CUSTOMER_BY_ID = (id) => `${BASE_URL}/customer/${id}`;
const DELETE_CUSTOMER_BY_ID = (id) => `${BASE_URL}/customer/${id}`;

// Category APIs
const GET_CATEGORY_LIST = `${BASE_URL}/category`;
const ADD_CATEGORY = `${BASE_URL}/category`;
const GET_CATEGORY_BY_ID = (id) => `${BASE_URL}/category/${id}`;
const EDIT_CATEGORY_BY_ID = (id) => `${BASE_URL}/category/${id}`;
const DELETE_CATEGORY_BY_ID = (id) => `${BASE_URL}/category/${id}`;

// Company APIs
const GET_COMPANY_LIST = `${BASE_URL}/company`;
const GET_COMPANY_BY_ID = (id) => `${BASE_URL}/company/${id}`;
const EDIT_COMPANY_BY_ID = (id) => `${BASE_URL}/company/${id}`;
const DELETE_COMPANY_BY_ID = (id) => `${BASE_URL}/company/${id}`;
const VERIFY_COMPANY_BY_ID = (id) => `${BASE_URL}/company/${id}/verify`; 

// Offers APIs
const GET_OFFERS_LIST = `${BASE_URL}/offers`;
const GET_OFFER_BY_ID = (id) => `${BASE_URL}/dashboard/api/v1/offers/${id}`;
const ADD_OFFER = `${BASE_URL}/dashboard/api/v1/offers`;
const EDIT_OFFER_BY_ID = (id) => `${BASE_URL}/dashboard/api/v1/offers/${id}`;
const DELETE_OFFER_BY_ID = (id) => `${BASE_URL}/dashboard/api/v1/offers/${id}`;

// Carousel APIs
const GET_CAROUSEL_LIST = `${BASE_URL}/corousal`;
const GET_CAROUSEL_BY_ID = (id) => `${BASE_URL}/corousal/${id}`;
const ADD_CAROUSEL = `${BASE_URL}/corousal`;
const EDIT_CAROUSEL_BY_ID = (id) => `${BASE_URL}/corousal/${id}`;
const DELETE_CAROUSEL_BY_ID = (id) => `${BASE_URL}/corousal/${id}`;

//support 
const GET_BUSINESS_HELP_LIST = `${BASE_URL}/support/help/business`;
const GET_BUSINESS_HELP_BY_ID = (id) => `${BASE_URL}/support/help/business/${id}`;

// New Customer Enquiry APIs
const GET_CUSTOMER_ENQUIRY_LIST = `${BASE_URL}/support/enquiry/customer`;
const GET_CUSTOMER_ENQUIRY_BY_ID = (id) => `${BASE_URL}/support/enquiry/customer/${id}`;


//mad love 
const GET_USER_DATA = `${BASE_URL}/user/data`;
const GET_USER_LIST = `${BASE_URL}/user`;
const GET_USER_BY_ID = (id) => `${BASE_URL}/user/${id}`; // Fetch user by ID API
const EDIT_USER_BY_ID = (id) => `${BASE_URL}/user/${id}`;

const GET_SUPPORT_LIST=`${BASE_URL}/support`
const GET_DETAILED_SUPPORT_VIEW=(id)=>`${BASE_URL}/support/${id}`
const POST_SUPPORT_VIEW=(id)=>`${BASE_URL}/support/${id}/resolved`
const GET_MAD_LOVE_VERIFICATION_LIST=`${BASE_URL}/verification/verification-list`
const GET_VERIFICATION_VIEW_BY_UID = (uid) => `${BASE_URL}/verification/verification${uid}`;
const UPDATE_VERIFICATION_APPROVAL_STATUS = (uid) => `${BASE_URL}/verification/verification${uid}`;
const SUSPEND_USER_BY_UID = (uid) => `${BASE_URL}/user/suspend/${uid}`;
const DELETE_USER_BY_ID = (id) => `${BASE_URL}/user/${id}`;
const FEEDBACK_LIST = `${BASE_URL}/support/feedback`;
const GET_SUSPENDED_USER_LIST = `${BASE_URL}/usersuspended-list`;
const GET_REVENUE = `${BASE_URL}/user/revenue`; // Total Revenue API
const UNSUSPEND_USER_BY_UID = (uid) => `${BASE_URL}/user/un-suspend/${uid}`;
const GRAND_ACTIVE_SUBSCRIPTION_LIST=`${BASE_URL}/subscriptions/subscription`
const GET_SUBSCRIPTION_COUNT_DETAILS=`${BASE_URL}/subscriptions/subscription-count`
const POST_ACTIVE_SUBSCRIPTION=(uid)=>`${BASE_URL}/subscriptions/user-subscription/${uid}`
const GET_PROFILE_REPORTS_LIST=`${BASE_URL}/reports/user`
const GET_DETAILED_PROFILE_REPORT=(id)=>`${BASE_URL}/reports/user/${id}`
const GET_POST_REPORTS_LIST=`${BASE_URL}/reports/post`
const GET_SUBSCRIPTION_LIST=`${BASE_URL}/subscriptions/subscription-list`
const GET_ACTIVE_SUBSCRIPTIONS=`${BASE_URL}/subscriptions/subscription`
//subcategories
const POST_SUB_CATEGORY=`${BASE_URL}/company/sub_category`
const EDIT_SUB_CATEGORY=`${BASE_URL}/company/sub_category`
const DELETE_SUB_CATEGORY=`${BASE_URL}/company/sub_category`
const GET_SUB_CATEGORY=`${BASE_URL}/company/sub_category`
//verification

const GET_VERIFICATION_LIST=`${BASE_URL}/verification`
const GET_VERIFICATION_VIEW = (uid) => `${BASE_URL}/user/verification-view?uid=${uid}`;
const UPDATE_VERIFICATION_VIEW = (uid) => `${BASE_URL}/user/user-verification?uid=${uid}`;
//reposrts
//company-veification

const GET_COMPANY_VERIFICATION_VIEW= (cid) => `${BASE_URL}/company/view?cid=${cid}`
const UPDATE_COMPANY_VERIFICATION_VIEW = (cid) => `${BASE_URL}/company/verification?cid=${cid}`;
//news
const POST_NEWS=`${BASE_URL}/extra/news`
const POST_EVENT=`${BASE_URL}/extra/news`
const DELETE_EVENT=(id)=>`${BASE_URL}/extra/event/${id}`
const GET_EVENT_BY_ID= (id) => `${BASE_URL}/extra/event/${id}`;
const POST_FILE=`${BASE_URL}/file/upload`
const ADD_EVENT=`${BASE_URL}/extra/event`
const EDIT_EVENT= (id) =>`${BASE_URL}/extra/event/${id}`
const GET_EVENTS=`${BASE_URL}/extra/event`
const GET_REPORTS=   `${BASE_URL}/reports`;
const GET_REPORTS_VIEW =`${BASE_URL}/reports/report_view`;
const UPDATE_REPORTS_VIEW=`${BASE_URL}/reports/report_view`
//delete

const DELETE_USER=`${BASE_URL}/user`;

//
const GET_EXECUTIVES = `${BASE_URL}/executive`;
const ADD_EXECUTIVES = `${BASE_URL}/executive`;
const UPDATE_EXECUTIVES = `${BASE_URL}/executive`;
const DELETE_EXECUTIVES = `${BASE_URL}/executive`;
const GET_COMPLETED_USERS = `${BASE_URL}/users/completed`;
const GET_REGISTERED_USERS = `${BASE_URL}/users/registered`;
const GET_VERIFIED_USERS = `${BASE_URL}/company/verified`;
const GET_REQUESTED = `${BASE_URL}/company/verification_requested`;
const GET_ALL_COMPANIES =`${BASE_URL}/company`;
const GET_COMPANY_DETAILS = `${BASE_URL}/company/company_details`;
const LOGOUT= `${BASE_URL}/auth/logout`;



/////genezez apis


const USERLIST=`${BASE_URL}/user`;

const DETAILED_USER_VIEW=`${BASE_URL}/user/view`

const GET_DETAILED_COMPANY_VIEW=`${BASE_URL}/company/view`
const VERIFICATION_LIST=`${BASE_URL}/user/user-verification`
const PENDING_USERS=`${BASE_URL}/user/pending`
const  VERIFIED_USERS=`${BASE_URL}/user/verified`
const  APPROVED_USERS=`${BASE_URL}/user/approved`
const TOTAL_USERS=`${BASE_URL}/user/total`
const WAITING_LIST=`${BASE_URL}/user/await`

const GET_DEATILED_FEEDBACK=`${BASE_URL}/feedback/view`
const UPDATE_STATUS_WAITING=`${BASE_URL}/user/await`
const ISSUES_REPORT_LIST=`${BASE_URL}/issues`
const ISSUES_REPORT_DETAILED_VIEW=`${BASE_URL}/issues/view`
const SUBSCRIPTION_LIST=`${BASE_URL}/subscription/user-subscription`
const GRAND_SUBSCRIPTION_LIST=`${BASE_URL}/subscription` 
const GRAND_SUBSCRIPTION=`${BASE_URL}/subscription/user-subscription`
const INCOMPLETE_LIST=`${BASE_URL}/user/all`
const SUBSCRIPTION_LIST_REVENUE=`${BASE_URL}/subscription/revenue`
const UPDATE_ISSUE_REPOTED=`${BASE_URL}/issues`
//edit details
const UPDATE_USER_PROFILE = `${BASE_URL}/user`;




//madlove





export{

   
    BASE_URL,
    LOGIN_URL,
    GET_CUSTOMER_LIST,
    GET_CUSTOMER_BY_ID,
    GET_CATEGORY_LIST,
    EDIT_CATEGORY_BY_ID,
    ADD_CATEGORY,
    GET_CATEGORY_BY_ID,
    EDIT_CUSTOMER_BY_ID,
    DELETE_CUSTOMER_BY_ID,
    DELETE_CATEGORY_BY_ID,
    GET_COMPANY_LIST,
    GET_COMPANY_BY_ID,
    EDIT_COMPANY_BY_ID,
    DELETE_COMPANY_BY_ID,
    GET_OFFERS_LIST,
    GET_OFFER_BY_ID,
    ADD_OFFER,
    EDIT_OFFER_BY_ID,
    DELETE_OFFER_BY_ID,
    GET_CAROUSEL_LIST,
    GET_CAROUSEL_BY_ID,
    EDIT_CAROUSEL_BY_ID,
    DELETE_CAROUSEL_BY_ID,
    ADD_CAROUSEL,
    GET_BUSINESS_HELP_LIST,
    GET_BUSINESS_HELP_BY_ID,
    GET_CUSTOMER_ENQUIRY_LIST,
    GET_CUSTOMER_ENQUIRY_BY_ID,
    VERIFY_COMPANY_BY_ID,



    //madlove
    GET_USER_DATA,
    GET_USER_LIST,
    GET_USER_BY_ID,
    EDIT_USER_BY_ID,
    GET_SUPPORT_LIST,
    GET_DETAILED_SUPPORT_VIEW,
    GET_MAD_LOVE_VERIFICATION_LIST,
    GRAND_ACTIVE_SUBSCRIPTION_LIST,
    GET_SUBSCRIPTION_COUNT_DETAILS,
    POST_ACTIVE_SUBSCRIPTION,
    POST_SUPPORT_VIEW,
    GET_VERIFICATION_VIEW_BY_UID,
    UPDATE_VERIFICATION_APPROVAL_STATUS,
    SUSPEND_USER_BY_UID,
    DELETE_USER_BY_ID,
   FEEDBACK_LIST,
   GET_SUSPENDED_USER_LIST,
   UNSUSPEND_USER_BY_UID,
   GET_REVENUE,
    //madlove
    
    GET_ACTIVE_SUBSCRIPTIONS,
    GET_PROFILE_REPORTS_LIST,
    GET_SUBSCRIPTION_LIST,
    GET_POST_REPORTS_LIST,
    INCOMPLETE_LIST,
    EDIT_EVENT,
    POST_EVENT,
    DELETE_EVENT,
    ADD_EVENT,
    POST_FILE,
    GET_EVENT_BY_ID,
    GET_EVENTS,
    POST_NEWS,
  
    UPDATE_COMPANY_VERIFICATION_VIEW,
    GET_COMPANY_VERIFICATION_VIEW,
    DELETE_USER,
    GRAND_SUBSCRIPTION,
    GRAND_SUBSCRIPTION_LIST,
    UPDATE_ISSUE_REPOTED,
    SUBSCRIPTION_LIST_REVENUE,
    SUBSCRIPTION_LIST,
    ISSUES_REPORT_DETAILED_VIEW,
    ISSUES_REPORT_LIST,
    UPDATE_STATUS_WAITING,
    GET_DEATILED_FEEDBACK,
  
    WAITING_LIST,
    PENDING_USERS,
    VERIFIED_USERS,
    APPROVED_USERS,
    TOTAL_USERS,
    VERIFICATION_LIST,
   
    GET_DETAILED_COMPANY_VIEW,
    DETAILED_USER_VIEW,
    USERLIST,
    GET_REPORTS,
    UPDATE_REPORTS_VIEW,
    GET_REPORTS_VIEW,
    UPDATE_VERIFICATION_VIEW,
    GET_VERIFICATION_LIST,
    GET_VERIFICATION_VIEW,
   
    POST_SUB_CATEGORY,
    EDIT_SUB_CATEGORY,
    DELETE_SUB_CATEGORY,
    GET_SUB_CATEGORY,
    GET_EXECUTIVES,
    ADD_EXECUTIVES,
    UPDATE_EXECUTIVES,
    DELETE_EXECUTIVES,
    GET_COMPLETED_USERS,
    GET_REGISTERED_USERS,
    GET_VERIFIED_USERS,
    GET_REQUESTED,
    GET_COMPANY_DETAILS,
    GET_ALL_COMPANIES,
    UPDATE_USER_PROFILE,
    LOGOUT

}