import React, { useEffect, useState } from 'react';
import { getBusinessHelpById } from '../../controller/dreamin_support';
import { FaSpinner } from 'react-icons/fa';

const BusinessHelpModal = ({ isOpen, onClose, businessId }) => {
    const [businessDetails, setBusinessDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isOpen && businessId) {
            fetchBusinessDetails();
        }
    }, [isOpen, businessId]);

    const fetchBusinessDetails = async () => {
        setIsLoading(true);
        try {
            const response = await getBusinessHelpById(businessId);
            if (response && response.data) {
                setBusinessDetails(response.data);
            } else {
                console.error('Failed to fetch business details');
            }
        } catch (error) {
            console.error('Error fetching business details:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg w-1/2">
                <h2 className="text-lg font-bold mb-4">Business Help Details</h2>
                
                {isLoading ? (
                    <div className="flex justify-center items-center">
                        <FaSpinner className="animate-spin text-3xl text-gray-500" />
                    </div>
                ) : businessDetails ? (
                    <div>
                        <div className="flex items-center space-x-4 mb-4">
                            <img
                                src={businessDetails.company_image}
                                alt={businessDetails.company_name}
                                className="w-24 h-24 rounded-full"
                            />
                            <div>
                                <h3 className="text-xl font-semibold">{businessDetails.company_name}</h3>
                                <p className="text-gray-600">{businessDetails.title}</p>
                            </div>
                        </div>
                        <p><strong>Company ID:</strong> {businessDetails.company_id}</p>
                        <p><strong>Contact Number:</strong> {businessDetails.contact_number}</p>
                        <p><strong>Description:</strong></p>
                        <p className="text-gray-700">{businessDetails.description}</p>
                    </div>
                ) : (
                    <p className="text-red-500">No details found.</p>
                )}

                <button 
                    className="mt-4 bg-red-500 text-white px-4 py-2 rounded" 
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default BusinessHelpModal;
