import React, { useState, useEffect, useRef } from 'react';
import Header from '../Components/header';
import Sidebar from '../Components/sidebar';
import { FaSpinner } from 'react-icons/fa';
import { fetchCarousels } from '../../controller/curousal';
import CurousalDetails from '../Components/curousal_details';
import AddCurousalModal from '../Components/add_curousal';

const CurousalList = () => {
    const [curousals, setCurousals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [noDataMessage, setNoDataMessage] = useState('');
    const [selectedCurousal, setSelectedCurousal] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    const limit = 10;
    const mainContentRef = useRef(null);

    // Fetch Curousal List
    const refreshCarouselList = async () => {
        setLoading(true);
        try {
            const response = await fetchCarousels({ skip: 0, limit });
            console.log("Updated API Response:", response);

            if (response.data && response.data.length > 0) {
                setCurousals(response.data);
                setHasMoreData(response.data.length === limit);
                setNoDataMessage('');
            } else {
                setCurousals([]);
                setNoDataMessage('No carousel data found.');
                setHasMoreData(false);
            }
        } catch (error) {
            console.error('Error fetching curousal data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshCarouselList();
    }, [page]);

    const handleShowMore = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handleViewClick = (curousal) => {
        console.log("Clicked Carousel:", curousal); // Debugging Log
        setSelectedCurousal(curousal);  // Store the full object instead of just ID
        setIsModalOpen(true);
    };
    
    

    return (
        <>
            <Header />
            <div className="flex h-screen bg-[#F3F3F3]">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <main ref={mainContentRef} className="flex-1 overflow-x-hidden overflow-y-auto p-6">
                        <div className=" ">
                        <div className="mt-6 flex justify-between items-center">
    <h1 className="text-xl font-semibold text-[#545454]">Carousal List</h1>
    <button
        className="bg-[#222222] text-white px-4 py-2 rounded-md text-sm"
        onClick={() => setIsAddModalOpen(true)} // Open modal on click
    >
        Add
    </button>


                        {/* Add Curousal Modal */}
                    {/* Add Curousal Modal */}
{isAddModalOpen && (
    <AddCurousalModal 
        onClose={() => setIsAddModalOpen(false)} 
        onRefresh={refreshCarouselList}  // ✅ Corrected function name
    />
)}

</div>

                            <div className="bg-white rounded-lg shadow-md p-4 mt-4">
                                {loading && curousals.length === 0 ? (
                                    <div className="flex justify-center items-center h-64">
                                        <FaSpinner className="animate-spin text-4xl text-gray-500" />
                                    </div>
                                ) : (
                                    <div className="overflow-y-auto">
                                        <table className="w-full text-sm">
                                            <thead>
                                                <tr className="text-center text-sm font-poppins">
                                                    <th className="pb-3 font-semibold">ID</th>
                                                    <th className="pb-3 font-semibold">Company Name</th>
                                                    <th className="pb-3 font-semibold">Title</th>
                                                    <th className="pb-3 font-semibold">Image</th>
                                                    <th className="pb-3 font-semibold">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {curousals.length > 0 ? (
                                                    curousals.map((item) => (
                                                        <tr key={item.id} className="text-center border-t border-gray-200 hover:bg-gray-100">
                                                            <td className="py-4">{item.id}</td>
                                                            <td className="py-4">{item.company_name || 'N/A'}</td>
                                                            <td className="py-4">{item.title || 'N/A'}</td>
                                                            <td className="py-4">
                                                                <img
                                                                    src={item.image_url}
                                                                    alt="Carousel"
                                                                    className="h-12 w-12 rounded-md object-cover mx-auto"
                                                                />
                                                            </td>
                                                            <td className="py-4">
                                                                <button
                                                                    onClick={() => handleViewClick(item)}
                                                                    className="px-3 py-1 bg-[#222222] rounded-md text-sm text-[#FFFFFF]"
                                                                >
                                                                    View
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5" className="text-center py-6 text-gray-500">
                                                            {noDataMessage || 'No data available'}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        {hasMoreData && (
                                            <div className="flex justify-center mt-4">
                                                <button
                                                    onClick={handleShowMore}
                                                    disabled={loading}
                                                    className={`px-4 py-2 bg-[#D8393F] text-white font-semibold rounded-lg flex items-center justify-center gap-2 ${
                                                        loading ? 'opacity-70 cursor-not-allowed' : ''
                                                    }`}
                                                >
                                                    {loading ? (
                                                        <FaSpinner className="animate-spin text-white" />
                                                    ) : (
                                                        'Show more'
                                                    )}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            {isModalOpen && selectedCurousal && (
                <CurousalDetails 
                    id={selectedCurousal.id} 
                    onClose={() => setIsModalOpen(false)} 
                    onRefresh={refreshCarouselList} 
                />
            )}


        </>
    );
};

export default CurousalList;
