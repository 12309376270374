import React, { useState } from 'react';

const RoundImageContainer = ({ imgSrc, label }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  // Placeholder image for empty src
  const defaultImage = 'path/to/default-placeholder.png'; // Specify the path to your default placeholder image

  return (
    <div className="flex flex-col items-center">
      <div className="cursor-pointer" onClick={toggleModal}>
        {/* Ensure the container always has a background and rounded appearance */}
        <div className="rounded-full w-32 h-32 bg-gray-200 flex justify-center items-center">
          <img src={imgSrc || defaultImage} alt={label} className="rounded-full object-cover" style={{ width: '128px', height: '128px' }} />
        </div>
      </div>

      {isModalOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center" 
          onClick={toggleModal} // Close modal when clicking outside the image
        >
          <img 
            src={imgSrc || defaultImage} 
            alt={label} 
            className="max-w-xs sm:max-w-sm w-auto h-auto" // Adjusted size for smaller modal
          />
        </div>
      )}
    </div>
  );
};

export default RoundImageContainer;
