
import './App.css';
import Login from './components/Login/Login';
import Homepage from './components/Homepage/Homepage';
import Detailpage from './components/Detailpage/Detailpage';
import Profile from './components/Profile/Profile';
import Settings from './components/Settings/Settings';
import Savedpage from './components/Savedpage/Savedpage';
import Reviews from './components/Review/Reviews';
import Editprofile from './components/Editprofile/Editprofile';
import Rateandreview from './components/Rateandreview/Rateandreview';
import Enquiry from './components/Enquiry/Enquiry';
import Categories from './components/Categories/Categories';
import Userinput from './components/Userinput/Userinput';
import Privacypolicy from './components/Privacypolicy/Privacypolicy';
import Termsandcondition from './components/Termsandcondition/Termsandcondition';
import Community from './components/Community/Community';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PackagesPage from './components/packages/package';
import BookmarkedCompanies from './components/Savedpage/Savedpage';
import ReviewDisplay from './components/Reviews/addreview';
import ReviewPage from './components/Reviews/addreview';
import SubscriptionPlans from './subscription/checkout';
import GeneralInformationForm from './subscription/general-form';
import PaymentSuccessful from './subscription/success';
import LoadingPage from './subscription/loading_page';
import RefundPolicy from './components/refundpolicy';
import ContactUs from './components/contactus';
import ChooseLocation from './components/Homepage/choose_location';
import AddTraveller from './components/scanner';
function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Login/>} />  */}
        <Route path="/" element={<Homepage />}/>
        <Route path="/detailpage" element={<Detailpage />}/>
        <Route path="/profile" element={<Profile />}/> 
        <Route path="/settings" element={<Settings />}/>
        <Route path="/savedpage" element={<BookmarkedCompanies />}/>
        <Route path="/rateandreview" element={<Rateandreview />}/>
        <Route path="/reviews" element={<Reviews />}/>
        <Route path="/editprofile" element={<Editprofile />}/>
        <Route path="/enquiry" element={<Enquiry />}/>
        <Route path="/categories" element={<Categories />}/>
        <Route path="/userinput/:customerUid" element={<Userinput />} />
        <Route path="/privacypolicy" element={<Privacypolicy />}/>
        <Route path="/termsandcondition" element={<Termsandcondition />}/>
        <Route path="/community" element={<Community />}/>
        <Route path="/packages" element={<PackagesPage />} />
        <Route path="/checkout" element={<SubscriptionPlans />} />
        <Route path="/form" element={<GeneralInformationForm />} />
        <Route path="/success" element={<PaymentSuccessful />} />
        <Route path="/loading" element={<LoadingPage/>} />
        <Route path="/refundpolicy" element={<RefundPolicy/>} />
        <Route path="/contactus" element={<ContactUs/>} />
        <Route path="/chooselocation" element={<ChooseLocation/>} />
        <Route path="/contactus" element={<ContactUs/>} />

        <Route path="/addtraveler" element={<AddTraveller/>} />


        <Route path="/reviews/:companyDocId" element={<ReviewPage />} />


        {/* <Route path="/usernewpage" element={<Userinput/>}/>     */}

      </Routes>
    </Router>
  );
}

export default App;
