import React, { useState } from 'react';
import InputBox from '../Components/input_box';
import PasswordInputField from '../Components/password_input';
import { Link, useNavigate } from 'react-router-dom';
import LoaderSpinner from '../Components/loader_spinner';
import { login } from '../../controller/auth'; // Updated login API function
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../controller/constants/constants'; // Import constants

const LoginPageDashboard = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      // Call the new login API
      const response = await login(email, password); // Updated to match the correct API params
      
      if (response.status === 200) {
        console.log('Login Success:', response.data);
  
        // Correctly access the tokens from the response
        localStorage.setItem(ACCESS_TOKEN, response.data.data.access); // Corrected to `response.data.access`
        localStorage.setItem(REFRESH_TOKEN, response.data.refresh || ""); // Optional: Use refresh token if available
  
        // Navigate to the home page
        navigate('/home');
      } else {
        console.error('Login failed with status:', response.status);
      }
    } catch (error) {
      console.error('Login Failed:', error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="w-screen h-screen flex overflow-hidden bg-[#FBFCF8]">
      {/* Left Side Logo Section */}
      <div className="bg-black w-1/3 h-full flex items-center justify-center hidden sm:flex">
        <img src="assets/dreamin.jpg" alt="Logo" className="h-36 w-32" />
      </div>

      {/* Right Side Form Section */}
      <div className="bg-[#FFFFFF] w-full sm:w-3/4 flex items-center justify-center px-4 sm:px-0 relative">
        <div className="w-full max-w-md max-h-lg space-y-10">
          {/* Header Section */}
          <div className="mb-1 space-y-2">
            <div className="flex justify-center">
              <img src="./assets/dreamin_logo.svg" alt="Logo" className="h-28 mb-4" />
            </div>
            <h2 className="text-2xl font-bold text-[#171717]">Welcome Back!</h2>
            <h2 className="text-3xl font-bold text-[#171717]">Login to continue</h2>
          </div>

          {/* Form Section */}
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <InputBox
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="dreamin@gmail.com"
                type="email"
              />
            </div>
            <div className="mb-4">
              <PasswordInputField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
              />
            </div>

            {/* Login Button with Spinner */}
            <div className="mt-3 w-full">
              <button
                type="submit"
                className={`bg-[#222222] text-white font-semibold w-full py-2 rounded flex justify-center items-center ${
                  loading ? 'cursor-not-allowed opacity-50' : ''
                }`}
                disabled={loading}
              >
                {loading ? <LoaderSpinner visible={true} /> : 'Log in'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPageDashboard;
