import { api } from "./api"

import { POST_SUB_CATEGORY } from "./urls"
import { EDIT_SUB_CATEGORY } from "./urls"
import { DELETE_SUB_CATEGORY } from "./urls"
import { GET_SUB_CATEGORY } from "./urls"



const post_sub_category = async (name) => {
  // Fetch the category from localStorage
  const category_name = localStorage.getItem('category');
  
  // Make sure category_name is a valid string before making the request
  if (!category_name) {
      throw new Error("Category not found in localStorage.");
  }

  const res = await api.post(POST_SUB_CATEGORY, {
      name: name,
      category: category_name // Send the valid category string to the API
  });
  return res;
};


const edit_sub_category = async (id, updated_name) => {
  const category = localStorage.getItem('category');

  if (!updated_name || typeof updated_name !== 'string') {
      throw new Error('Invalid name format');
  }

  const res = await api.put(`${EDIT_SUB_CATEGORY}?id=${id}`, {
      name: updated_name,   // Ensure updated_name is a string
      category: category    // Ensure category is a string
  });

  return res;
};



  const get_sub_category = async () => {
    try {
      // Retrieve category from localStorage
      const category = localStorage.getItem('category');  
      
      // Check if category exists in localStorage
      if (!category) {
        throw new Error("Category not found in localStorage");
      }
  
      // Send category as a query parameter
      const res = await api.get(GET_SUB_CATEGORY, {
        params: { category }  // Send category as query parameter
      });
      
      return res;
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      throw error;
    }
  };
  

  const delete_sub_category = async (id) => {
    const res = await api.delete(`${DELETE_SUB_CATEGORY}?id=${id}`, {
    });
    return res;
};

  

export {post_sub_category,edit_sub_category,get_sub_category,delete_sub_category}