import React from 'react';
import Select from 'react-select';

// List of subscription status options
const statusOptions = [
  { value: 'active', label: 'ACTIVE' },
  { value: 'expired', label: 'EXPIRED' },
  { value: 'cancelled', label: 'CANCELLED' },
];

const SubscriptionStatusFilterComponent = ({ selectedStatus, onStatusChange }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #ccc',
      boxShadow: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '10px',
      backgroundColor: state.isSelected ? '#f1f1f1' : 'white',
      color: '#333',
    }),
    singleValue: (provided) => ({
      ...provided,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#232323',
    }),
  };

  return (
    <Select
      value={statusOptions.find((status) => status.value === selectedStatus) || statusOptions[0]} // Default to 'ACTIVE'
      onChange={(selectedOption) => onStatusChange(selectedOption ? selectedOption.value : 'active')} // Default to 'active' if cleared
      options={statusOptions}
      styles={customStyles}
      placeholder="Select Subscription Status"
      isClearable
      isSearchable={false}
      aria-label="Subscription Status Filter" // Accessibility improvement
    />
  );
};

export default SubscriptionStatusFilterComponent;
