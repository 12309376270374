import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { firebaseConfig } from "../firebaseConfig";
import { BiChevronLeft } from "react-icons/bi";
import { RiFilter3Line } from "react-icons/ri";
import { Helmet } from "react-helmet-async";
import Hamburger from "../Hamburger/Hamburger";
import FilterAlert from "../pricing_filter_component";
import { getCompanies } from "../../controller/category_wise_companies";
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Categories = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const categoryName = location.state?.categoryName || localStorage.getItem("selectedCategory");
  const [companies, setCompanies] = useState([]);
  const [bookmarkedCompanies, setBookmarkedCompanies] = useState({});
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [loading, setLoading] = useState(true); // State for loading spinner

  useEffect(() => {
    if (categoryName) {
      localStorage.setItem("selectedCategory", categoryName); // Persist category selection
    }
  }, [categoryName]);

  useEffect(() => {
    const fetchCompanies = async () => {
      setLoading(true); // Start loading
      try {
        if (!categoryName) {
          console.error("No category name provided.");
          setLoading(false);
          return;
        }

        // Step 1: Fetch company IDs from Firestore
        const q = query(
          collection(db, "company"),
          where("categoryType", "==", categoryName.toLowerCase())
        );
        const querySnapshot = await getDocs(q);
        const firestoreCompanies = querySnapshot.docs.map((doc) => ({
          id: doc.id, // Preserve Firestore document ID
          ...doc.data(),
        }));

        // Step 2: Fetch company details from API using the IDs
        const apiCompanies = await Promise.all(
          firestoreCompanies.map(async (company) => {
            try {
              const apiData = await getCompanies(0, 1, "", "", "", categoryName, company.id);
              return { ...company, ...apiData.data[0] }; // Merge Firestore data with API response
            } catch (apiError) {
              console.error(`Error fetching details for company ${company.id}:`, apiError);
            }
          })
        );

        setCompanies(apiCompanies);
      } catch (error) {
        console.error("Error fetching companies:", error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchCompanies();
  }, [categoryName]);

  const handleCardClick = (company) => {
    navigate("/detailpage", { state: { companyId: company.id } });
  };

  const toggleBookmark = (companyId) => {
    setBookmarkedCompanies((prev) => ({
      ...prev,
      [companyId]: !prev[companyId],
    }));
  };

  return (
    <>
      <Helmet>
        <title>Category</title>
        <meta
          name="description"
          content="Professional Event Photography Services
          Capturing Candid Moments for Every Event
          Wedding and Party Photography Experts
          Corporate Event Photography Solutions
          Best Photographers for Events Near You"
        />
        <link rel="canonical" href="/categories" />
      </Helmet>
      <div className="flex min-h-screen lg:w-[90%] p-6 font-inter relative">
        {/* Left Section (Main Content) */}
        <div className="flex-1 flex flex-col items-center">
          <div
            className="absolute top-6 left-6 md:left-10 lg:left-12 xl:left-16 text-gray-700 cursor-pointer"
            onClick={() => navigate("/")}
          >
            <BiChevronLeft className="text-3xl md:text-3xl" />
          </div>

          <div className="mb-6 flex items-center space-x-3">
            <h2 className="text-xl font-inter font-medium text-gray-700">
              {categoryName || "Category"}
            </h2>
            <RiFilter3Line
              className="text-gray-700 cursor-pointer text-2xl"
              onClick={() => {
                if (window.innerWidth < 768) {
                  setIsBottomSheetOpen(true);
                } else {
                  setIsFilterOpen(true);
                }
              }}
            />
          </div>

          {/* Loading Spinner */}
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <div className="loader border-t-4 border-b-4 border-gray-500 rounded-full w-8 h-8 animate-spin"></div>
            </div>
          ) : companies.length > 0 ? (
            <div className="flex flex-col items-center gap-6 bottom-9 w-full">
              {companies.map((company) => (
                <div
                  key={company.id}
                  className="relative bg-gray-200 rounded-lg shadow-lg w-full max-w-md"
                >
                  <div
                    className="absolute top-3 right-3 cursor-pointer text-xl text-gray-700"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleBookmark(company.id);
                    }}
                  >
                    {bookmarkedCompanies[company.id] ? (
                      <i className="fas fa-bookmark"></i>
                    ) : (
                      <i className="far fa-bookmark"></i>
                    )}
                  </div>

                  <img
                    className="w-full h-60 object-cover rounded-t-lg"
                    src={
                      company.coverImages && company.coverImages.length > 0
                        ? company.coverImages[0]
                        : "https://via.placeholder.com/400"
                    }
                    alt={company.companyName || "Company"}
                    onClick={() => handleCardClick(company)}
                  />

                  <div className="p-4 flex flex-col gap-4">
                    <div className="flex items-center gap-4">
                      <img
                        src={company.logo || "https://via.placeholder.com/50"}
                        alt={`${company.companyName} Logo`}
                        className="w-12 h-12 rounded-md object-cover"
                      />
                      <div>
                        <div className="text-lg font-medium font-inter text-gray-800">
                          {company.companyName}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex justify-center items-center h-full">
              <h2 className="text-center font-inter font-thin text-gray-500 text-xl">
                No companies found in the "{categoryName}" category.
              </h2>
            </div>
          )}
        </div>

        {isFilterOpen && <FilterAlert onClose={() => setIsFilterOpen(false)} />}
        {isBottomSheetOpen && (
          <div
            className="fixed inset-0 flex justify-center items-end bg-black bg-opacity-50"
            onClick={() => setIsBottomSheetOpen(false)}
          >
            <div
              className="w-full bg-white shadow-lg rounded-t-3xl p-6 transition-transform transform translate-y-0"
              onClick={(e) => e.stopPropagation()}
            >
              <h2 className="text-lg font-medium font-inter mb-2">Choose pricing</h2>
              <div className="space-y-1">
                <label className="flex justify-between items-center p-1">
                  <span>Budget</span>
                  <input type="checkbox" />
                </label>
                <label className="flex justify-between items-center p-1">
                  <span>Premium</span>
                  <input type="checkbox" />
                </label>
                <label className="flex justify-between items-center p-1">
                  <span>Luxury</span>
                  <input type="checkbox" />
                </label>
              </div>
              <button
                className="w-full mt-2 bg-black text-white py-2 rounded"
                onClick={() => setIsBottomSheetOpen(false)}
              >
                Apply
              </button>
            </div>
          </div>
        )}
        <Hamburger />
      </div>
    </>
  );
};

export default Categories;
