import { api } from "./api"
import { GET_VERIFICATION_LIST } from "./urls";
import { GET_VERIFICATION_VIEW } from "./urls";
import { UPDATE_VERIFICATION_VIEW } from "./urls";
import { UPDATE_USER_PROFILE } from "./urls";
const get_verification_list = async (skip, limit) => {
    try {
        const res = await api.get(`${GET_VERIFICATION_LIST}`, {
            params: {
                skip: skip,
                limit: limit
            }
        });
        console.log(res)
        return res;
    } catch (error) {
        console.error("Error fetching verification list:", error);
        throw error;
    }
};
const getVerificationView = async (uid) => {
    try {
      // Log the full URL you're using for the API call to verify it's correct
      console.log('API URL:', GET_VERIFICATION_VIEW(uid));
      
      const res = await api.get(GET_VERIFICATION_VIEW(uid));
      
      // Log the full response to check if the API call was successful
      console.log('Full API Response:', res);
      
      return res;  // Return the full response to the caller
    } catch (error) {
      console.error('Error fetching verification view:', error);
      throw error;
    }
  };
  

const updateVerificationView = async (uid, payload) => {
    try {
        const res = await api.put(UPDATE_VERIFICATION_VIEW(uid), payload); 
        console.log('Updated Verification View:', res.data); 
        return res.data;
    } catch (error) {
        console.error('Error updating verification view:', error); 
        throw error;
    }
};
const updateUserProfile = async (uid, payload) => {
    try {
        const res = await api.put(`${UPDATE_USER_PROFILE}?uid=${uid}`, payload);
        console.log('User Profile Updated:', res.data);
        return res.data;
    } catch (error) {
        console.error('Error updating user profile:', error);
        throw error;
    }
};
export { get_verification_list,getVerificationView,updateVerificationView,updateUserProfile };

