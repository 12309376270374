// import React, { useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { Menu, X } from 'lucide-react';
// import { logout } from '../../controller/auth';
// import DeletePopup from './delete_pop_up';
// const Sidebar = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [activeItem, setActiveItem] = useState(location.pathname);
//   const [isVerificationsOpen, setIsVerificationsOpen] = useState(false);
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//   const [isReportsOpen, setIsReportsOpen] = useState(false); // Tracks whether the report section is open or not
//   const [isReportActive, setIsReportActive] = useState(false); // Tracks if the report section is active
//   const [showLogoutPopup, setShowLogoutPopup] = useState(false); // Tracks visibility of logout popup
//   const [isLoggingOut, setIsLoggingOut] = useState(false); // Tracks logout API call status

//   const handleNavigation = (path) => {
//     setActiveItem(path);
//     navigate(path);
//     setIsMobileMenuOpen(false);
//   };

//   const handleLogoutConfirm = () => {
//     localStorage.clear(); // Clear all localStorage data
//     setShowLogoutPopup(false);
//     navigate('/'); // Navigate to the home page
//   };
//   const handleLogoutCancel = () => {
//     setShowLogoutPopup(false);
//   };

//   const isVerificationActive = activeItem === '/company_verification_list' || activeItem === '/user_verification_list';

//   const MobileMenuButton = () => (
//     <button 
//       onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
//       className="fixed top-5 left-4 p-2 rounded-lg z-50 lg:hidden"
//     >
//       {isMobileMenuOpen ? (
//         <X className="h-6 w-6 text-gray-700" />
//       ) : (
//         <Menu className="h-6 w-6 text-gray-700" />
//       )}
//     </button>
//   );

//   const sidebarContent = (
//     <div className="h-full overflow-y-auto">
//       <div className="p-4">
//         {/* Add any logo or additional content here */}
//       </div>
//       <nav className="mt-6">
//         <SidebarLink
//           imgSrc="/assets/home-2.svg"
//           label="Home"
//           path="/home"
//           activeItem={activeItem}
//           onClick={() => handleNavigation('/home')}
//         />
//         <SidebarLink
//           imgSrc="/assets/suspended_list.svg"
//           label="Category List"
//           path="/catagory_list"
//           activeItem={activeItem}
//           onClick={() => handleNavigation('/catagory_list')}
//         />
//         <SidebarLink
//           imgSrc="/assets/message.svg"
//           label="Subscriptions"
//           path="/subscription"
//           activeItem={activeItem}
//           onClick={() => handleNavigation('/subscription')}
//         />
//          <SidebarLink
//           imgSrc="/assets/post_request.svg"
//           label="Company List"
//           path="/company_list"
//           activeItem={activeItem}
//           onClick={() => handleNavigation('/company_list')}
//         />

//         {/* Report Section */}
//         <div
//           className={`flex items-center py-6 px-6 space-x-5 cursor-pointer ${
//             isReportActive || isReportsOpen ? 'bg-[#232323] rounded-lg' : ''
//           }`}
//           onClick={() => setIsReportsOpen(!isReportsOpen)}
//         >
//           <img 
//             src="/assets/issues_reported.svg" 
//             alt="Reports" 
//             className={`w-6 h-6 mr-4 ${
//               isReportActive || isReportsOpen
//                 ? 'filter invert-[21%] sepia-[86%] saturate-[7621%] hue-rotate-[346deg] brightness-[96%] contrast-[91%]'
//                 : ''
//             }`}
//           />
//           <span className={isReportActive || isReportsOpen ? 'text-[#D8393F]' : ''}>
//             Reports
//           </span>
//         </div>

//         {isReportsOpen && (
//           <div className="space-y-2 pl-10">
//             <div
//               className={`flex items-center py-4 px-6 space-x-5 cursor-pointer ${
//                 activeItem === '/issues-reported'
//                   ? 'bg-[#232323] rounded-lg' 
//                   : 'hover:bg-[#232323] hover:rounded-lg'
//               }`}
//               onClick={() => handleNavigation('/issues-reported')}
//             >
//               <img
//                 src="/assets/user_report.svg"
//                 alt="User Reports"
//                 className="w-6 h-6 mr-4"
//               />
//               <span 
//                 className={
//                   activeItem === '/issues-reported' 
//                     ? 'text-[#D8393F]' 
//                     : 'hover:text-[#D8393F]'
//                 }
//               >
//                 Profile Reports
//               </span>
//             </div>
//             <div
//               className={`flex items-center py-4 px-6 space-x-5 cursor-pointer ${
//                 activeItem === '/post_reports' 
//                   ? 'bg-[#232323] rounded-lg' 
//                   : 'hover:bg-[#232323] hover:rounded-lg'
//               }`}
//               onClick={() => handleNavigation('/post_reports')}
//             >
//               <img
//                 src="/assets/post_report.svg"
//                 alt="post reports"
//                 className="w-6 h-6 mr-4"
//               />
//               <span 
//                 className={
//                   activeItem === '/post_reports' 
//                     ? 'text-[#D8393F]' 
//                     : 'hover:text-[#D8393F]'
//                 }
//               >
//                 Post Reports
//               </span>
//             </div>
//           </div>
//         )}

//         <SidebarLink
//           imgSrc="/assets/feedback_nav.svg"
//           label="Support"
//           path="/support-list"
//           activeItem={activeItem}
//           onClick={() => handleNavigation('/support-list')}
//         />
      
//         <SidebarLink
//           imgSrc="/assets/feedback_new_icon.svg"
//           label="Offers"
//           path="/offers_list"
//           activeItem={activeItem}
//           onClick={() => handleNavigation('/offers_list')}
//         />

// <SidebarLink
//           imgSrc="/assets/feedback_new_icon.svg"
//           label="Curousal"
//           path="/curousal_list"
//           activeItem={activeItem}
//           onClick={() => handleNavigation('/curousal_list')}
//         />
       
//        <SidebarLink
//          imgSrc="/assets/log_out_dashboard.svg"
//          label="Logout"
//          path="/logout"
//          onClick={() => setShowLogoutPopup(true)} 
//          activeItem={activeItem}
//        />

//       </nav>
//     </div>
//   );

//   return (
//     <>
//       <MobileMenuButton />
//       <aside 
//         className={`
//           fixed lg:static w-64 bg-[#171717] text-white h-screen font-poppins font-normal 
//           flex flex-col overflow-hidden transition-transform duration-300 ease-in-out
//           ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
//           lg:w-64 z-40
//         `}
//       >
//         {sidebarContent}
//       </aside>
//       {/* Overlay for mobile */}
//       {isMobileMenuOpen && (
//         <div 
//           className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
//           onClick={() => setIsMobileMenuOpen(false)}
//         />
//       )}
//       {/* Logout Confirmation Popup */}
//       {showLogoutPopup && (
//         <DeletePopup
//           message="Are you sure you want to log out?"
//           onConfirm={handleLogoutConfirm}
//           onCancel={handleLogoutCancel}
//           isLoading={isLoggingOut}
//         />
//       )}
//     </>
//   );
// };

// const SidebarLink = ({ imgSrc, label, path, activeItem, onClick }) => {
//   const isActive = activeItem === path;

//   return (
//     <div
//       className={`flex items-center py-4 px-6 space-x-5 cursor-pointer group ${
//         isActive ? 'bg-[#232323] rounded-lg' : 'hover:bg-[#232323] hover:rounded-lg'
//       }`}
//       onClick={onClick}
//     >
//       <img
//         src={imgSrc}
//         alt={label}
//         className={`w-6 h-6 mr-4 ${
//           isActive 
//             ? 'filter invert-[21%] sepia-[86%] saturate-[7621%] hue-rotate-[346deg] brightness-[96%] contrast-[91%]' 
//             : 'group-hover:filter group-hover:invert-[21%] group-hover:sepia-[86%] group-hover:saturate-[7621%] group-hover:hue-rotate-[346deg] group-hover:brightness-[96%] group-hover:contrast-[91%]'
//         }`}
//       />
//       <span className={`${isActive ? 'text-[#D8393F]' : 'group-hover:text-[#D8393F]'}`}>
//         {label}
//       </span>
//     </div>
//   );
// };

// export default Sidebar;


import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { logout } from '../../controller/auth';
import DeletePopup from './delete_pop_up';

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(location.pathname);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isReportsOpen, setIsReportsOpen] = useState(false); 
  const [isReportActive, setIsReportActive] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isSupportOpen, setIsSupportOpen] = useState(false); // New state to toggle Support submenu

  const handleNavigation = (path) => {
    setActiveItem(path);
    navigate(path);
    setIsMobileMenuOpen(false);
  };

  const handleLogoutConfirm = () => {
    localStorage.clear(); 
    setShowLogoutPopup(false);
    navigate('/'); 
  };

  const handleLogoutCancel = () => {
    setShowLogoutPopup(false);
  };

  const MobileMenuButton = () => (
    <button 
      onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      className="fixed top-5 left-4 p-2 rounded-lg z-50 lg:hidden"
    >
      {isMobileMenuOpen ? (
        <X className="h-6 w-6 text-gray-700" />
      ) : (
        <Menu className="h-6 w-6 text-gray-700" />
      )}
    </button>
  );

  const sidebarContent = (
    <div className="h-full overflow-y-auto">
      <div className="p-4">
        {/* Add any logo or additional content here */}
      </div>
      <nav className="mt-6">
        <SidebarLink
          imgSrc="/assets/home-2.svg"
          label="Home"
          path="/home"
          activeItem={activeItem}
          onClick={() => handleNavigation('/home')}
        />
        <SidebarLink
          imgSrc="/assets/suspended_list.svg"
          label="Category List"
          path="/category_list"
          activeItem={activeItem}
          onClick={() => handleNavigation('/category_list')}
        />
        {/* <SidebarLink
          imgSrc="/assets/message.svg"
          label="Subscriptions"
          path="/subscription"
          activeItem={activeItem}
          onClick={() => handleNavigation('/subscription')}
        /> */}
        <SidebarLink
          imgSrc="/assets/post_request.svg"
          label="Company List"
          path="/company_list"
          activeItem={activeItem}
          onClick={() => handleNavigation('/company_list')}
        />

        {/* Report Section */}
        {/* <div
          className={`flex items-center py-6 px-6 space-x-5 cursor-pointer ${
            isReportActive || isReportsOpen ? 'bg-[#232323] rounded-lg' : ''
          }`}
          onClick={() => setIsReportsOpen(!isReportsOpen)}
        >
          <img 
            src="/assets/issues_reported.svg" 
            alt="Reports" 
            className={`w-6 h-6 mr-4 ${
              isReportActive || isReportsOpen
                ? 'filter invert-[21%] sepia-[86%] saturate-[7621%] hue-rotate-[346deg] brightness-[96%] contrast-[91%]'
                : ''
            }`}
          />
          <span className={isReportActive || isReportsOpen ? 'text-[#D8393F]' : ''}>
            Reports
          </span>
        </div> */}

        {isReportsOpen && (
          <div className="space-y-2 pl-10">
            <SidebarSubLink
              imgSrc="/assets/user_report.svg"
              label="Profile Reports"
              path="/issues-reported"
              activeItem={activeItem}
              onClick={() => handleNavigation('/issues-reported')}
            />
            <SidebarSubLink
              imgSrc="/assets/post_report.svg"
              label="Post Reports"
              path="/post_reports"
              activeItem={activeItem}
              onClick={() => handleNavigation('/post_reports')}
            />
          </div>
        )}

        {/* Support Section */}
        <div
          className={`flex items-center py-6 px-6 space-x-5 cursor-pointer ${
            isSupportOpen ? 'bg-[#232323] rounded-lg' : ''
          }`}
          onClick={() => setIsSupportOpen(!isSupportOpen)}
        >
          <img 
            src="/assets/feedback_nav.svg" 
            alt="Support" 
            className={`w-6 h-6 mr-4 ${
              isSupportOpen ? 'filter invert-[21%] sepia-[86%] saturate-[7621%] hue-rotate-[346deg] brightness-[96%] contrast-[91%]' : ''
            }`}
          />
          <span className={isSupportOpen ? 'text-[#D8393F]' : ''}>
            Support
          </span>
        </div>

        {isSupportOpen && (
          <div className="space-y-2 pl-10">
            <SidebarSubLink
              imgSrc="/assets/feedback_nav.svg"
              label="Business"
              path="/support-list"
              activeItem={activeItem}
              onClick={() => handleNavigation('/support-list')}
            />
            <SidebarSubLink
              imgSrc="/assets/feedback_nav.svg"
              label="Customer Enquiry"
              path="/customer_enquiry_list"
              activeItem={activeItem}
              onClick={() => handleNavigation('/customer_enquiry_list')}
            />
            <SidebarSubLink
              imgSrc="/assets/feedback_nav.svg"
              label="Category"
              path="/support-category"
              activeItem={activeItem}
              onClick={() => handleNavigation('/support-category')}
            />
          </div>
        )}

        <SidebarLink
          imgSrc="/assets/feedback_new_icon.svg"
          label="Offers"
          path="/offers_list"
          activeItem={activeItem}
          onClick={() => handleNavigation('/offers_list')}
        />
        <SidebarLink
          imgSrc="/assets/feedback_new_icon.svg"
          label="Curousal"
          path="/curousal_list"
          activeItem={activeItem}
          onClick={() => handleNavigation('/curousal_list')}
        />
       
        <SidebarLink
          imgSrc="/assets/log_out_dashboard.svg"
          label="Logout"
          path="/logout"
          onClick={() => setShowLogoutPopup(true)} 
          activeItem={activeItem}
        />
      </nav>
    </div>
  );

  return (
    <>
      <MobileMenuButton />
      <aside 
        className={`fixed lg:static w-64 bg-[#171717] text-white h-screen font-poppins font-normal 
          flex flex-col overflow-hidden transition-transform duration-300 ease-in-out
          ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
          lg:w-64 z-40`}
      >
        {sidebarContent}
      </aside>
      {isMobileMenuOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}
      {showLogoutPopup && (
        <DeletePopup
          message="Are you sure you want to log out?"
          onConfirm={handleLogoutConfirm}
          onCancel={handleLogoutCancel}
          isLoading={isLoggingOut}
        />
      )}
    </>
  );
};

const SidebarLink = ({ imgSrc, label, path, activeItem, onClick }) => {
  const isActive = activeItem === path;

  return (
    <div
      className={`flex items-center py-4 px-6 space-x-5 cursor-pointer group ${
        isActive ? 'bg-[#232323] rounded-lg' : 'hover:bg-[#232323] hover:rounded-lg'
      }`}
      onClick={onClick}
    >
      <img src={imgSrc} alt={label} className="w-6 h-6 mr-4" />
      <span className={isActive ? 'text-[#D8393F]' : ''}>{label}</span>
    </div>
  );
};

const SidebarSubLink = ({ imgSrc, label, path, activeItem, onClick }) => {
  return (
    <SidebarLink imgSrc={imgSrc} label={label} path={path} activeItem={activeItem} onClick={onClick} />
  );
};

export default Sidebar;
