import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Modal from '../Components/image_modal';
import EditCategoryModal from './edit_category';
import { getCategoryById } from '../../controller/catagory_dreamin';
import DeletePopup from './delete_pop_up';
import { deleteCategoryById } from '../../controller/catagory_dreamin';

const CategoryDetailView = ({ categoryId, onClose }) => {
    const [category, setCategory] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isImageLoading, setIsImageLoading] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    

    useEffect(() => {
        const fetchCategoryDetails = async () => {
            try {
                console.log("Fetching category with ID:", categoryId); // Debugging log
                setIsLoading(true);
                const response = await getCategoryById(categoryId);
                console.log("Fetched category data:", response);
                
                if (response?.data) {
                    setCategory(response.data); // Ensure correct data is stored
                    console.log("Stored category state:", response.data);
                } else {
                    console.error("Error: Received undefined category data!");
                }
            } catch (error) {
                console.error("Error fetching category details:", error);
            } finally {
                setIsLoading(false);
            }
        };
    
        if (categoryId) {
            fetchCategoryDetails();
        }
    }, [categoryId]);
    
    
    const handleEdit = () => {
        console.log('Edit button clicked');
        console.log('Category ID:', category?.id); // Debug log to check if category.id exists
        setIsEditModalOpen(true);
    };
    

    return (
        <>
            <Modal onClose={onClose}>
                <div className="p-4 flex flex-col items-center">
                    {isLoading ? (
                        <FaSpinner className="animate-spin text-4xl text-gray-500 mb-4" />
                    ) : category ? (
                        <div className="w-full">
                            <div className="flex justify-between items-center mb-4">
                                {/* Category Name on the Left */}
                                <h2 className="text-xl font-semibold text-gray-800">{category.categoryName}</h2>
                                {/* Edit and Delete Icons on the Right */}
                                <div className="flex space-x-2">
                                <button
                            className="p-2 bg-[#f3f3f3] rounded-full hover:bg-[#f2f2f2]"
                            onClick={() => {
                                console.log('Opening edit modal for category ID:', category?.categoryId); // Debugging log
                                setIsEditModalOpen(true);
                            }}
                        >
                            <img src="/assets/edit-icon.svg" alt="Edit" className="w-5 h-5" />
                        </button>

                        <button
                            className="p-2 bg-red-500 rounded-full hover:bg-red-600"
                            onClick={() => {
                                console.log('Delete button clicked for category ID:', category?.categoryId);
                                setIsDeletePopupOpen(true); // Open confirmation popup
                            }}
                        >
                            <img src="/assets/delete_account_icon.svg" alt="Delete" className="w-5 h-5" />
                        </button>

                                </div>
                            </div>
                            <div className="relative text-center">
                                {isImageLoading && (
                                    <FaSpinner className="animate-spin text-4xl text-gray-500 absolute inset-0 m-auto" />
                                )}
                                <img
                                    src={category.imageUrl}
                                    alt={category.categoryName}
                                    className={`w-full max-h-[500px] object-contain rounded ${isImageLoading ? 'hidden' : ''}`}
                                    onLoad={() => setIsImageLoading(false)}
                                    onError={() => setIsImageLoading(false)}
                                />
                            </div>
                        </div>
                    ) : (
                        <p className="text-gray-500">Failed to load category details.</p>
                    )}
                    <button
                        className="mt-4 bg-[#222222] text-white px-4 py-2 rounded"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </Modal>

            {/* Edit Category Modal */}
            {isEditModalOpen && category && (
    <EditCategoryModal
    categoryId={category.categoryId}
        categoryName={category.categoryName}
        imageUrl={category.imageUrl}
        onClose={() => setIsEditModalOpen(false)}
        onSave={(updatedCategory) => {
            setCategory((prev) => ({
                ...prev,
                ...updatedCategory
            }));
            setIsEditModalOpen(false);
        }}
             />
                
            )}
            {isDeletePopupOpen && (
    <DeletePopup
        message="Are you sure you want to delete this category?"
        isLoading={isDeleting}
        onConfirm={async () => {
            if (!category?.categoryId) {
                console.error("Category ID is missing!");
                return;
            }

            setIsDeleting(true);
            try {
                console.log("Deleting category with ID:", category.categoryId);
                await deleteCategoryById(category.categoryId); // Call delete API
                console.log("Category deleted successfully!");
                setIsDeletePopupOpen(false);
                onClose(); // Close modal after deletion
            } catch (error) {
                console.error("Error deleting category:", error);
            } finally {
                setIsDeleting(false);
            }
        }}
        onCancel={() => {
            console.log("Delete action canceled");
            setIsDeletePopupOpen(false);
        }}
    />
)}

        </>
    );
};

CategoryDetailView.propTypes = {
    categoryId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CategoryDetailView;
