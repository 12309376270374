import React from 'react';
import { FaSpinner } from 'react-icons/fa'; // Importing a spinner icon from react-icons

const DeletePopup = ({ message, onConfirm, onCancel, isLoading }) => { // Changed onClose to onCancel
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 w-80 text-center mx-2 flex flex-col items-center">
                <div className="flex justify-center mb-4">
                    <img src="/assets/pop-up.svg" alt="Info" className="w-12 h-12" />
                </div>
                <p className="text-black mb-4">{message}</p>
                <div className="flex justify-center space-x-2">
                    {isLoading ? (
                        <FaSpinner className="animate-spin text-[#222222] text-2xl" /> // Loader during API call
                    ) : (
                        <>
                            <button 
                                className="border border-[#222222] text-[#222222] py-2 w-24 rounded" 
                                onClick={onConfirm}
                            >
                                Yes
                            </button>
                            <button 
                                className="bg-[#222222] text-white py-2 w-24 rounded"
                                onClick={onCancel} // Changed onClose to onCancel
                            >
                                No
                            </button>
                        </>
                    )}
                </div>
            </div>
            
        </div>
    );
};

export default DeletePopup;
