import React from "react";
import { useNavigate } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi"; // Import the left arrow icon

const AboutUs = () => {
    const navigate = useNavigate();

    return (
        <div className="container mx-auto px-4 font-inter relative">
            {/* Back Button (Top Left) */}
            <div
                className="absolute top-6 left-6 md:left-10 lg:left-12 xl:left-16 text-gray-700 cursor-pointer"
                onClick={() => navigate(-1)} // Navigate back to the previous page
            >
                <BiChevronLeft className="text-3xl md:text-4xl" />
            </div>

            {/* About Us and Community Guidelines Container */}
            <div className="rounded-lg p-6 sm:w-full md:w-3/4 lg:w-2/3 mx-auto max-h-screen overflow-y-auto">
                <h2 className="text-xl font-normal font-inter text-gray-800 mb-6 text-center">
                    About Us & Community Guidelines
                </h2>
                <div className="space-y-6">
                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">About Us</h3>
                        <p className="text-gray-600 font-inter text-sm">
                            Dream In is your go-to wedding planning platform, connecting couples with top vendors and resources to create their dream weddings effortlessly. Join us for a stress-free and memorable journey from "yes" to "I do"!
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Respect Others</h3>
                        <p className="text-gray-600 font-inter text-sm">
                            Treat all users with kindness and respect. Do not engage in harassment, hate speech, or discrimination based on race, gender, religion, or any other characteristic.
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Be Honest</h3>
                        <p className="text-gray-600 font-inter text-sm">
                            Provide accurate information and avoid misleading or deceptive practices. Do not impersonate others or create fake accounts.
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Respect Privacy</h3>
                        <p className="text-gray-600 font-inter text-sm">
                            Do not share personal or confidential information about others without their consent. Respect the privacy settings and preferences of other users.
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Stay on Topic</h3>
                        <p className="text-gray-600 font-inter text-sm">
                            Keep discussions and interactions relevant to wedding-related topics. Avoid spamming or posting unrelated content.
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Report Abuse</h3>
                        <p className="text-gray-600 font-inter text-sm">
                            If you encounter any violations of these guidelines or inappropriate behavior, please report it to us immediately.
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Legal Compliance</h3>
                        <p className="text-gray-600 font-inter text-sm">
                            Follow all applicable laws and regulations when using Dream In. Do not engage in illegal activities or promote unlawful behavior.
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Violation of Guidelines</h3>
                        <p className="text-gray-600 font-inter text-sm">
                            Violation of these community guidelines may result in account suspension or termination. We reserve the right to remove any content or take action against users who violate these guidelines.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
