

// import React, { useState, useEffect, useRef } from 'react';
// import Header from '../Components/header';
// import Sidebar from '../Components/sidebar';
// import { FaSpinner, FaStar, FaRegStar } from 'react-icons/fa';
// import FeedbackModal from '../Components/feedback_modal';
// import { fetchFeedback } from '../../controller/madlove_feedback';

// const Feedbacklist = () => {
//     const [feedbacks, setFeedbacks] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [selectedUser, setSelectedUser] = useState(null);
//     const [page, setPage] = useState(0);
//     const [hasMoreData, setHasMoreData] = useState(true);
//     const [noFeedbackMessage, setNoFeedbackMessage] = useState('');
//     const limit = 10;
//     const mainContentRef = useRef(null);

//     const fetchFeedbackList = async () => {
//         setLoading(true);
//         try {
//             const data = await fetchFeedback({
//                 skip: page * limit,
//                 limit: limit,
//             });

//             console.log("API Response:", data);

//             if (data.length === 0) {
//                 if (page === 0) {
//                     setNoFeedbackMessage('No feedback found.');
//                 } else {
//                     setNoFeedbackMessage('No more feedback to load.');
//                 }
//                 setHasMoreData(false);
//             } else {
//                 setFeedbacks((prevFeedbacks) => (page === 0 ? data : [...prevFeedbacks, ...data]));
//                 setHasMoreData(data.length === limit);
//                 setNoFeedbackMessage('');
//             }
//         } catch (error) {
//             console.error('Error fetching feedback:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchFeedbackList();
//     }, [page]);

//     const openModal = (user) => {
//         setSelectedUser(user);
//         setIsModalOpen(true);
//     };

//     const closeModal = () => {
//         setIsModalOpen(false);
//         setSelectedUser(null);
//     };

//     const renderStars = (rating) => (
//         <div className="flex justify-center items-center gap-1">
//             {Array.from({ length: 5 }, (_, index) =>
//                 index < rating ? (
//                     <FaStar key={index} className="text-[#D8393F]" />
//                 ) : (
//                     <FaRegStar key={index} className="text-gray-400" />
//                 )
//             )}
//         </div>
//     );

//     const truncateFeedback = (feedback, maxLength = 50) => {
//         if (!feedback) return 'N/A';
//         return feedback.length > maxLength ? `${feedback.slice(0, maxLength)}...` : feedback;
//     };

//     const handleShowMore = () => {
//         setPage((prevPage) => prevPage + 1);
//     };

//     return (
//         <>
//             <Header />
//             <div className="flex h-screen bg-[#F3F3F3]">
//                 <Sidebar />
//                 <div className="flex-1 flex flex-col overflow-hidden">
//                     <main ref={mainContentRef} className="flex-1 overflow-x-hidden overflow-y-auto p-6">
//                         <div className="mt-6">
//                             <h1 className="text-xl font-semibold mb-6 text-[#545454]">Offers</h1>

//                             <div className="bg-white rounded-lg shadow-md p-4 mt-4">
//                                 {loading && feedbacks.length === 0 ? (
//                                     <div className="flex justify-center items-center h-64">
//                                         <FaSpinner className="animate-spin text-4xl text-gray-500" />
//                                     </div>
//                                 ) : (
//                                     <div className="overflow-y-auto">
//                                         <table className="w-full text-sm">
//                                             <thead>
//                                                 <tr className="text-center text-sm font-poppins">
//                                                     <th className="text-left pb-3 font-semibold">User Name</th>
//                                                     <th className="pb-3 font-semibold hidden md:table-cell">Gender</th>
//                                                     <th className="pb-3 font-semibold hidden md:table-cell">Rating</th>
//                                                     <th className="pb-3 font-semibold hidden md:table-cell">Feedback</th>
//                                                     <th className="pb-3 font-semibold hidden md:table-cell">Actions</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {feedbacks.length > 0 ? (
//                                                     feedbacks.map((feedback, index) => (
//                                                         <tr
//                                                             key={feedback.user?.id || index}
//                                                             className="text-center border-t border-gray-200 hover:bg-gray-100 cursor-pointer"
//                                                             onClick={() => openModal(feedback)} // Entire row clickable
//                                                         >
//                                                             {/* Profile */}
//                                                             <td className="py-4 flex items-center justify-start gap-4">
//                                                                 <img
//                                                                     src={feedback.user.profile_image || './assets/profile.jpg'}
//                                                                     alt="Profile"
//                                                                     className="h-10 w-10 rounded-full object-cover"
//                                                                 />
//                                                                 <span className="text-gray-700 font-medium text-sm">
//                                                                     {feedback.user.name}
//                                                                 </span>
//                                                             </td>

//                                                             {/* Gender - Hidden on Small Screens */}
//                                                             <td className="py-4 text-gray-500 text-sm hidden sm:table-cell">
//                                                                 {feedback.user.gender}
//                                                             </td>

//                                                             {/* Rating - Hidden on Small Screens */}
//                                                             <td className="py-4 flex justify-center hidden sm:table-cell">
//                                                                 {renderStars(feedback.rating)}
//                                                             </td>

//                                                             {/* Feedback - Hidden on Small Screens */}
//                                                             <td className="py-4 text-sm text-gray-600 hidden sm:table-cell">
//                                                                 {truncateFeedback(feedback.feedback)}
//                                                             </td>

//                                                             {/* Actions - Hidden on Small Screens */}
//                                                             <td className="py-4 hidden sm:table-cell">
//                                                                 <button
//                                                                     className="px-4 py-2 bg-[#D8393F] text-white font-semibold rounded-lg"
//                                                                     onClick={(e) => {
//                                                                         e.stopPropagation(); // Prevent row click from triggering modal
//                                                                         openModal(feedback);
//                                                                     }}
//                                                                 >
//                                                                     View
//                                                                 </button>
//                                                             </td>
//                                                         </tr>
//                                                     ))
//                                                 ) : (
//                                                     <tr>
//                                                         <td colSpan="5" className="text-center py-6 text-gray-500">
//                                                             {noFeedbackMessage || 'No feedback found.'}
//                                                         </td>
//                                                     </tr>
//                                                 )}
//                                             </tbody>
//                                         </table>
//                                         {noFeedbackMessage === 'No more feedback to load.' && (
//                                             <div className="text-center mt-4 text-gray-500">
//                                                 No more feedback to load.
//                                             </div>
//                                         )}
//                                         {hasMoreData && (
//                                             <div className="flex justify-center mt-4">
//                                                 <button
//                                                     onClick={handleShowMore}
//                                                     disabled={loading}
//                                                     className={`px-4 py-2 bg-[#D8393F] text-white font-semibold rounded-lg flex items-center justify-center gap-2 ${
//                                                         loading ? 'opacity-70 cursor-not-allowed' : ''
//                                                     }`}
//                                                 >
//                                                     {loading ? (
//                                                         <FaSpinner className="animate-spin text-white" />
//                                                     ) : (
//                                                         'Show more'
//                                                     )}
//                                                 </button>
//                                             </div>
//                                         )}
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                     </main>
//                 </div>
//             </div>
//             <FeedbackModal isOpen={isModalOpen} onClose={closeModal} user={selectedUser} />
//         </>
//     );
// };

// export default Feedbacklist;
import React, { useState, useEffect, useRef } from "react";
import Header from "../Components/header";
import Sidebar from "../Components/sidebar";
import { FaSpinner } from "react-icons/fa";
import { fetchOffersList } from "../../controller/offers";

const OffersList = () => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [noOffersMessage, setNoOffersMessage] = useState("");
  const limit = 10;
  const mainContentRef = useRef(null);

  // Fetch Offers List from API
  const fetchOffers = async () => {
    setLoading(true);
    try {
      const data = await fetchOffersList({
        skip: page * limit,
        limit: limit,
      });

      console.log("API Response:", data);

      if (data.data.length === 0) {
        if (page === 0) {
          setNoOffersMessage("No offers found.");
        } else {
          setNoOffersMessage("No more offers to load.");
        }
        setHasMoreData(false);
      } else {
        setOffers((prevOffers) => (page === 0 ? data.data : [...prevOffers, ...data.data]));
        setHasMoreData(data.data.length === limit);
        setNoOffersMessage("");
      }
    } catch (error) {
      console.error("Error fetching offers:", error);
      setNoOffersMessage("Failed to load offers. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, [page]);

  const handleShowMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <Header />
      <div className="flex h-screen bg-[#F3F3F3]">
        <Sidebar />
        <div className="flex-1 flex flex-col overflow-hidden">
          <main ref={mainContentRef} className="flex-1 overflow-x-hidden overflow-y-auto p-6">
            <div className="mt-6">
              <h1 className="text-xl font-semibold mb-6 text-[#545454]">Offers</h1>

              <div className="bg-white rounded-lg shadow-md p-4 mt-4">
                {loading && offers.length === 0 ? (
                  <div className="flex justify-center items-center h-64">
                    <FaSpinner className="animate-spin text-4xl text-gray-500" />
                  </div>
                ) : (
                  <div className="overflow-y-auto">
                    <table className="w-full text-sm">
                      <thead>
                        <tr className="text-center text-sm font-poppins">
                          <th className="text-left pb-3 font-semibold">Image</th>
                          <th className="pb-3 font-semibold hidden md:table-cell">Start Date</th>
                          <th className="pb-3 font-semibold hidden md:table-cell">End Date</th>
                          <th className="pb-3 font-semibold hidden md:table-cell">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {offers.length > 0 ? (
                          offers.map((offer) => (
                            <tr
                              key={offer.id}
                              className="text-center border-t border-gray-200 hover:bg-gray-100"
                            >
                              {/* Image */}
                              <td className="py-4 flex items-center justify-start gap-4">
                                <img
                                  src={offer.image_url || "./assets/default-image.jpg"}
                                  alt="Offer"
                                  className="h-16 w-16 rounded-md object-cover"
                                />
                              </td>

                              {/* Start Date */}
                              <td className="py-4 text-gray-500 text-sm hidden md:table-cell">
                                {new Date(offer.start_date).toLocaleDateString()}
                              </td>

                              {/* End Date */}
                              <td className="py-4 text-gray-500 text-sm hidden md:table-cell">
                                {new Date(offer.end_date).toLocaleDateString()}
                              </td>

                              {/* Actions */}
                              <td className="py-4 hidden md:table-cell">
                                <button
                                  className="px-4 py-2 bg-[#D8393F] text-white font-semibold rounded-lg"
                                  onClick={() => console.log("View offer details:", offer)}
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4" className="text-center py-6 text-gray-500">
                              {noOffersMessage || "No offers found."}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {noOffersMessage === "No more offers to load." && (
                      <div className="text-center mt-4 text-gray-500">No more offers to load.</div>
                    )}
                    {hasMoreData && (
                      <div className="flex justify-center mt-4">
                        <button
                          onClick={handleShowMore}
                          disabled={loading}
                          className={`px-4 py-2 bg-[#D8393F] text-white font-semibold rounded-lg flex items-center justify-center gap-2 ${
                            loading ? "opacity-70 cursor-not-allowed" : ""
                          }`}
                        >
                          {loading ? (
                            <FaSpinner className="animate-spin text-white" />
                          ) : (
                            "Show more"
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default OffersList;

