import React, { useState } from "react";
import { addCarousel } from "../../controller/curousal";
import { FaTimes } from "react-icons/fa";
import { storage } from "../../services/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import PhotoUpload from "../Components/photo_upload";
import { showToast } from "./toast";

const AddCurousalModal = ({ onClose, onRefresh }) => {
    const [formData, setFormData] = useState({
        button_link: "",
        button_name: "",
        company_name: "",
        end_date: "",
        image_url: "",
        start_date: "",
        title: "",
    });

    const [loading, setLoading] = useState(false);
    const [imageFile, setImageFile] = useState([]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleImageUpload = async () => {
        if (!imageFile.length) {
            showToast("Please select an image.", "error");
            return null;
        }

        const file = imageFile[0].file;
        const storageRef = ref(storage, `curousalphotos/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                "state_changed",
                null,
                (error) => {
                    console.error("Error uploading image:", error);
                    reject(error);
                },
                async () => {
                    try {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        resolve(downloadURL);
                    } catch (error) {
                        reject(error);
                    }
                }
            );
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            // Handle image upload if needed
            let uploadedImageUrl = formData.image_url;
            if (!uploadedImageUrl && imageFile.length) {
                uploadedImageUrl = await handleImageUpload();
                if (!uploadedImageUrl) {
                    setLoading(false);
                    return;
                }
            }

            // Add carousel
            const result = await addCarousel({
                ...formData,
                image_url: uploadedImageUrl
            });

            // Handle the response
            if (result.success) {
                // showToast(result.message || "Carousel added successfully!", "success");
                onRefresh();
                onClose();
            } else {
                showToast(result.message || "Failed to add carousel", "error");
            }
        } catch (error) {
            console.error("Unexpected error:", error);
            showToast("An unexpected error occurred", "error");
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white p-6 rounded-lg w-[500px] relative">
                <button onClick={onClose} className="absolute top-2 right-2 text-gray-600 hover:text-gray-900">
                    <FaTimes size={20} />
                </button>

                <h2 className="text-xl font-semibold mb-4 text-center">Add Carousel</h2>

                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 gap-4">
                        {/* Title */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Title</label>
                            <input type="text" name="title" value={formData.title} onChange={handleChange} className="border rounded w-full p-2" required />
                        </div>

                        {/* Company Name */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Company Name</label>
                            <input type="text" name="company_name" value={formData.company_name} onChange={handleChange} className="border rounded w-full p-2" required />
                        </div>

                        {/* Button Name */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Button Name</label>
                            <input type="text" name="button_name" value={formData.button_name} onChange={handleChange} className="border rounded w-full p-2" required />
                        </div>

                        {/* Button Link */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Button Link</label>
                            <input type="text" name="button_link" value={formData.button_link} onChange={handleChange} className="border rounded w-full p-2" required />
                        </div>

                        {/* Start Date */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Start Date</label>
                            <input type="date" name="start_date" value={formData.start_date} onChange={handleChange} className="border rounded w-full p-2" required />
                        </div>

                        {/* End Date */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">End Date</label>
                            <input type="date" name="end_date" value={formData.end_date} onChange={handleChange} className="border rounded w-full p-2" required />
                        </div>
                    </div>

                    {/* Image Upload Using PhotoUpload Component */}
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Image</label>
                        <PhotoUpload
                            files={imageFile}
                            setFiles={setImageFile}
                            svgImagePath="/assets/grey_plus.svg"
                            editIconPath="/assets/edit-icon.svg"
                            bgColor="#F3F3F3"
                            borderRadius="8px"
                            width="100%"
                            height="150px"
                            type="image"
                            index={1} // Unique index for input
                        />
                    </div>

                    <button type="submit" className="bg-[#D8393F] text-white w-full py-2 rounded-md mt-4" disabled={loading}>
                        {loading ? "Uploading..." : "Add Carousel"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AddCurousalModal;
