import React from "react";
import { useNavigate } from "react-router-dom";
import { RiCloseCircleFill } from "react-icons/ri"; // Import the close icon

const LoginAlert = ({ message, onClose }) => {
  const navigate = useNavigate();

  const handleContinue = () => {
    onClose(); // Close the alert
    navigate("/"); // Navigate to the home page
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-gray-700 bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-[300px] text-center relative shadow-lg">
        {/* Close Icon */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <RiCloseCircleFill className="text-xl text-black" />
        </button>

        {/* Message */}
        <p className="text-black text-lg font-inter mb-3">{message}</p>

        {/* Continue Button */}
        <button
          onClick={handleContinue}
          className="bg-black text-white rounded-full py-2 px-6 hover:bg-gray-800"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default LoginAlert;
