// import React, { useState } from 'react';
// import { FaSpinner } from 'react-icons/fa';

// const PhotoUpload = ({
//   files,
//   setFiles,
//   svgImagePath,
//   editIconPath,
//   bgColor,
//   borderRadius,
//   width,
//   height,
//   type = 'image',
// }) => {
//   const [loading, setLoading] = useState(false);

//   const handleFileUpload = (e) => {
//     const uploadedFiles = Array.from(e.target.files);
//     setLoading(true);

//     setTimeout(() => {
//       const uploadedFileData = uploadedFiles.map((file) => ({
//         file,
//         url: URL.createObjectURL(file),
//       }));

//       setFiles(uploadedFileData); // Update state with the uploaded file(s)
//       setLoading(false);
//     }, 1000); // Simulating a delay for uploading
//   };

//   return (
//     <div
//       className="relative flex items-center justify-center"
//       style={{
//         backgroundColor: bgColor || '#C1C1C1',
//         borderRadius: borderRadius || '8px',
//         width: width || '130px',
//         height: height || '190px',
//       }}
//     >
//       {loading ? (
//          <div className="flex items-center justify-center">
//          <FaSpinner className="animate-spin text-2xl text-gray-500" /> {/* FaSpinner loader */}
//        </div>// Placeholder for a loader spinner
//       ) : files.length > 0 ? (
//         <div className="relative w-full h-full">
//           {/* Display uploaded image */}
//           {files.map((file, index) => (
//             <img
//               key={index}
//               src={file.url}
//               alt="upload"
//               className="object-cover w-full h-full"
//               style={{
//                 borderRadius: borderRadius || '8px',
//                 backgroundColor: bgColor || '#E6E6E6',
//               }}
//             />
//           ))}

//           {/* Show edit icon at the center for updating */}
//           <label
//             htmlFor="file-upload"
//             className="absolute inset-0 flex items-center justify-center cursor-pointer"
//             style={{ background: 'rgba(0,0,0,0.1)' }} // Semi-transparent overlay
//           >
//             <img src={editIconPath} alt="Edit" className="w-6 h-6" />
//           </label>
//           <input
//             type="file"
//             accept={type + '/*'}
//             onChange={handleFileUpload}
//             className="hidden"
//             id="file-upload"
//           />
//         </div>
//       ) : (
//         <label
//           htmlFor="file-upload"
//           className="cursor-pointer flex flex-col items-center justify-center"
//           style={{
//             width: '100%',
//             height: '100%',
//             backgroundColor: bgColor || '#E6E6E6',
//             borderRadius: borderRadius || '8px',
//           }}
//         >
//           <img src={svgImagePath} alt="Upload" className="w-6 h-6 mb-2" />
//           <input
//             type="file"
//             accept={type + '/*'}
//             onChange={handleFileUpload}
//             className="hidden"
//             id="file-upload"
//           />
//         </label>
//       )}
//     </div>
//   );
// };

// export default PhotoUpload;


import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

const PhotoUpload = ({
  files,
  setFiles,
  svgImagePath,
  editIconPath,
  bgColor,
  borderRadius,
  width,
  height,
  type = 'image',
  index // Add index prop to make each instance unique
}) => {
  const [loading, setLoading] = useState(false);
  
  // Create unique ID for each instance
  const inputId = `file-upload-${type}-${index}`;

  const handleFileUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    setLoading(true);

    setTimeout(() => {
      const uploadedFileData = uploadedFiles.map((file) => ({
        file,
        url: URL.createObjectURL(file),
      }));

      setFiles(uploadedFileData);
      setLoading(false);
    }, 1000);
  };

  return (
    <div
      className="relative flex items-center justify-center"
      style={{
        backgroundColor: bgColor || '#C1C1C1',
        borderRadius: borderRadius || '8px',
        width: width || '130px',
        height: height || '190px',
      }}
    >
      {loading ? (
        <div className="flex items-center justify-center">
          <FaSpinner className="animate-spin text-2xl text-gray-500" />
        </div>
      ) : files.length > 0 ? (
        <div className="relative w-full h-full">
          {files.map((file, fileIndex) => (
            <img
              key={fileIndex}
              src={file.url}
              alt="upload"
              className="object-cover w-full h-full"
              style={{
                borderRadius: borderRadius || '8px',
                backgroundColor: bgColor || '#E6E6E6',
              }}
            />
          ))}

          <label
            htmlFor={inputId} // Use unique ID here
            className="absolute inset-0 flex items-center justify-center cursor-pointer"
            style={{ background: 'rgba(0,0,0,0.1)' }}
          >
            <img src={editIconPath} alt="Edit" className="w-6 h-6" />
          </label>
          <input
            id={inputId} // Use unique ID here
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
            className="hidden"
          />
        </div>
      ) : (
        <label
          htmlFor={inputId} // Use unique ID here
          className="cursor-pointer flex flex-col items-center justify-center"
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: bgColor || '#E6E6E6',
            borderRadius: borderRadius || '8px',
          }}
        >
          <img src={svgImagePath} alt="Upload" className="w-6 h-6 mb-2" />
          <input
            id={inputId} // Use unique ID here
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
            className="hidden"
          />
        </label>
      )}
    </div>
  );
};

export default PhotoUpload;
