import React from "react";
import { useNavigate } from 'react-router-dom';
import { BiChevronLeft } from "react-icons/bi"; // Import the left arrow icon

const RefundPolicy = () => {
    const navigate = useNavigate();

    return (
        <div className="container mx-auto px-4 font-inter">
            {/* Back Button */}
            <div
                className="absolute top-6 left-6 md:left-10 lg:left-12 xl:left-16 text-gray-700 cursor-pointer"
                onClick={() => navigate(-1)} // Navigate back to the previous page
            >
                <BiChevronLeft className="text-3xl md:text-4xl" />
            </div>
            {/* Refund Policy Container */}
            <div className="rounded-lg p-6 sm:w-full md:w-3/4 lg:w-2/3 mx-auto max-h-screen overflow-y-auto">
                <h2 className="text-xl font-normal font-inter text-gray-800 mb-6 text-center">Refund Policy</h2>
                <div className="space-y-6">
                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Dreamin App Refund Policy</h3>
                        <p className="text-gray-600 font-inter text-sm">At Dreamin, we strive to provide a valuable and seamless experience for all entrepreneurs. Please note that all purchases and payments made through the Dreamin app are non-refundable. Once a transaction is completed, it is considered final.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">No Refunds</h3>
                        <p className="text-gray-600 font-inter text-sm">Due to the nature of our services and the digital content provided, we do not offer refunds for any subscriptions, services, or features purchased within the app. This includes, but is not limited to, membership fees, in-app purchases, and premium features.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Exceptions</h3>
                        <p className="text-gray-600 font-inter text-sm">We do not provide exceptions to this policy. We encourage you to carefully review the features and benefits of any paid service before making a purchase.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Customer Support</h3>
                        <p className="text-gray-600 font-inter text-sm">If you encounter any issues or have questions regarding the services, our support team is available to assist you. However, refunds will not be granted based on service-related issues once the transaction is complete.</p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-black font-inter">Thank You</h3>
                        <p className="text-gray-600 font-inter text-sm">Thank you for understanding and for being part of the Dreamin entrepreneurial community.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RefundPolicy;
