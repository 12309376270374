import React, { useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { getDetailedCompanyView } from '../../controller/genezez_company_controller';

const CompanyProfileModal = ({ companyId, onClose }) => {
  const [companyData, setCompanyData] = useState(null);
  const [teamData, setTeamData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await getDetailedCompanyView(companyId);
        if (response && response.company_info) {
          setCompanyData(response.company_info);
          setTeamData(response.admins || []);
        } else {
          console.error('Company data not found in response:', response);
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanyData();
  }, [companyId]);

  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <FaSpinner className="animate-spin text-3xl text-gray-600" />
      </div>
    );
  }

  if (!companyData) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white rounded-lg p-6 shadow-lg text-center">
          Company details not found. Please try again later.
          <button onClick={onClose} className="mt-4 px-4 py-2 bg-yellow-500 text-white rounded">
            Close
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4" onClick={onClose}>
      <div
        className="bg-white rounded-lg shadow-lg p-4 sm:p-8 w-full max-w-4xl relative overflow-y-auto max-h-[90vh]"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Modal Content */}
        <div className="flex flex-col items-center">
          <img
            src={companyData.profile_image || './genezez-logo.svg'}
            alt={companyData.name}
            className="h-20 w-20 mb-4 rounded-full"
          />
          <h1 className="text-xl font-bold text-center">{companyData.name}</h1>
          <p className="text-sm text-center">{companyData.category}</p>
          <div className="flex mt-4 flex-col sm:flex-row items-center sm:justify-center w-full px-4 sm:px-0">
            {companyData.website && (
              <div className="bg-yellow-500 text-black rounded-md px-4 py-1 mb-2 sm:mb-0 sm:mr-2 w-full sm:w-auto text-center">
                <a 
                  href={companyData.website.startsWith('http') ? companyData.website : `https://${companyData.website}`} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="truncate block"
                >
                  {companyData.website}
                </a>
              </div>
            )}
            <div className="bg-yellow-500 text-black rounded-md px-4 py-1 w-full sm:w-auto text-center">
              {companyData.address || "No Address Provided"}
            </div>
          </div>
        </div>

        <div className="mt-6 mb-6 px-4 sm:px-0">
          <h2 className="text-lg font-semibold">About Company</h2>
          <p className="mt-2">{companyData.description}</p>
        </div>

        <div className="mt-6 mb-6">
          <h2 className="text-lg font-semibold px-4 sm:px-0">Team</h2>
          <div className="flex flex-wrap justify-center sm:justify-start mt-6 gap-4 px-4 sm:px-0">
            {teamData && teamData.length > 0 ? (
              teamData.map((member, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg p-4 flex flex-col items-center shadow-lg w-[160px] sm:w-[195px] h-[180px] sm:h-[190px]"
                >
                  <img
                    src={member.profile_image || '/default-profile.svg'}
                    alt={member.name}
                    className="h-20 w-20 sm:h-24 sm:w-24 rounded-full border-2 border-yellow-500 mb-4"
                  />
                  <div className="text-center">
                    <p className="font-semibold text-sm sm:text-base">{member.name}</p>
                    <p className="text-xs sm:text-sm">{member.position}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No team members found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfileModal;