import React from 'react';
import Select from 'react-select';

// Define category options
const categoryOptions = [
  { value: 'Venue', label: 'Venue' },
  { value: 'Entertainers', label: 'Entertainers' },
  { value: 'Cards', label: 'Cards' },
  { value: 'Transport', label: 'Transport' },
  { value: 'Photography', label: 'Photography' },
  { value: 'Decorations', label: 'Decorations' },
  { value: 'Light and Sounds', label: 'Light and Sounds' },
  { value: 'Cakes', label: 'Cakes' },
  { value: 'Caters', label: 'Caters' },
  { value: 'Flowers', label: 'Flowers' },
  { value: 'Makeup', label: 'Makeup' },
  { value: 'Bridal Outfits', label: 'Bridal Outfits' },
  { value: 'Jewellery', label: 'Jewellery' },
  { value: 'Event Managers', label: 'Event Managers' },
  { value: 'Groom Outfits', label: 'Groom Outfits' },
];

// Custom styles for Select component
const customStyles = {
  control: (provided) => ({
    ...provided,
    border: '1px solid #ccc',
    boxShadow: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '10px',
    backgroundColor: state.isSelected ? '#f1f1f1' : 'white',
    color: '#333',
    display: 'flex',
    alignItems: 'center',
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#232323',
  }),
};

const CategoryFilterComponent = ({ selectedCategory, onCategoryChange }) => {
  return (
    <Select
      value={categoryOptions.find((category) => category.value === selectedCategory)}
      onChange={(selectedOption) => onCategoryChange(selectedOption ? selectedOption.value : '')}
      options={categoryOptions}
      styles={customStyles}
      placeholder="Select Category"
      isClearable
      isSearchable
    />
  );
};

export default CategoryFilterComponent;
