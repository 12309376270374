import React from "react";
import { useNavigate } from "react-router-dom";

const PaymentSuccessful = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center h-screen bg-green-100 relative w-full">
      {/* Close Button */}
      <button
        className="absolute top-4 right-4 text-2xl text-gray-400 hover:text-gray-600 transition"
        onClick={() => navigate("/subscription-plans")}
      >
        ×
      </button>

      {/* Content */}
      <div className="flex flex-col items-center justify-center">
        {/* Success Icon */}
        <div className="bg-green-500 h-20 w-20 rounded-full flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            className="w-12 h-12"
          >
            <path d="M5 13l4 4L19 7" />
          </svg>
        </div>

        {/* Success Message */}
        <div className="text-center mt-6">
          <h2 className="text-green-700 text-2xl font-bold">Order Placed</h2>
          <p className="text-green-600 font-medium mt-2">Sit back and Relax</p>
          <p className="text-gray-700 mt-3">Your Purchase has been confirmed successfully.</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessful;
