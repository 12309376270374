import React, { useState, useEffect, useRef } from 'react';
import Header from '../Components/header';
import Sidebar from '../Components/sidebar';
import { FaSpinner } from 'react-icons/fa';
import { showToast } from '../Components/toast';
import { fetchsubscribedUsers } from '../../controller/madlove-subscription';
import Card from '../Components/card';
import SubscriptionStatusFilterComponent from '../Components/subscription_filter_component';
import { fetchSubscriptionCountDetails } from '../../controller/madlove-subscription';
const limit = 10;

const SubscriptionList = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('all');
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const [filter, setFilter] = useState('active'); // Use lowercase for consistency with API
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [subscriptionCounts, setSubscriptionCounts] = useState({
    total: 0,
    active: 0,
    monthly_subscription: 0, // Add monthly subscription count
  });
  const [countsLoading, setCountsLoading] = useState(true); // Track loading state for counts

  
  
  const modalRef = useRef(null);

  const fetchSubscriptions = async () => {
    try {
      setLoading(true);
      console.log('API called with skip:', skip, 'limit:', limit, 'filter:', filter);
  
      const response = await fetchsubscribedUsers({
        skip,
        limit,
        filter,
      });
  
      console.log('API response:', response);
  
      // Ensure data is an array
      const normalizedData = Array.isArray(response)
        ? response.map((item) => ({
            user_name: item.name,
            profile_image: item.profile_image,
            plan_name: item.plan_name,
            actual_price: item.price,
            created_on: item.start_date,
            ends_on: item.renewal_date,
          }))
        : [];
  
      if (skip === 0) {
        setSubscriptions(normalizedData); // Replace with new data on first load
      } else {
        setSubscriptions((prev) => [...prev, ...normalizedData]); // Append new data
      }
  
      setHasMore(normalizedData.length === limit); // Determine if more data is available
    } catch (error) {
      console.error('Error fetching subscriptions:', error.response?.data || error.message);
      showToast(
        error.response?.data?.error || 'Failed to fetch subscriptions',
        'error'
      );
      setHasMore(false); // Stop loading more data on error
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchCounts(); 
    setSkip(0);
    setSubscriptions([]);
    fetchSubscriptions();
  }, [selectedPlan, filter]);

  
  useEffect(() => {
    setSkip(0);
    setSubscriptions([]); 
    fetchSubscriptions();
  }, [selectedPlan, filter]);

  useEffect(() => {
    if (skip > 0) {
      fetchSubscriptions();
    }
  }, [skip]);

  const handleShowMore = () => {
    setSkip((prevSkip) => prevSkip + limit);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };


  const fetchCounts = async () => {
    try {
      setCountsLoading(true); // Set loading to true
      const counts = await fetchSubscriptionCountDetails(); 
      setSubscriptionCounts({
        total: counts.total || 0,
        active: counts.active || 0,
        monthly_subscription: counts.monthly_subscription || 0,
      });
    } catch (error) {
      console.error('Error fetching subscription counts:', error.message);
      showToast('Failed to fetch subscription counts', 'error');
    } finally {
      setCountsLoading(false); 
    }
  };
  
  
  

  const filteredSubscriptions = subscriptions.filter(
    (subscription) =>
      subscription.user_name &&
      subscription.user_name.toLowerCase().includes(searchTerm.toLowerCase().trim())
  );

  return (
    <>
      <Header />
      <div className="flex h-screen bg-[#F3F3F3]">
        <Sidebar />
        <div className="flex-1 flex flex-col overflow-hidden">
          <main className="flex-1 overflow-x-hidden overflow-y-auto p-6">
            {/* Card Section */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 h-auto mb-6">
              <div className="h-48 overflow-hidden">
              <Card
    title="Total Subscriptions"
    count={subscriptionCounts.total}
    bgColor="bg-white"
    textColor="text-black"
    icon="/assets/user-add.svg"
    loading={countsLoading} 
  />
</div>
<div className="h-48 overflow-hidden">
<Card
    title="Active Subscriptions"
    count={subscriptionCounts.active} 
    bgColor="bg-white"
    textColor="text-black"
    icon="/assets/user-add.svg"
    loading={countsLoading} // Pass loading state
  />
              </div>
              <div className="h-48 overflow-hidden">
              <div className="h-48 overflow-hidden">
              <Card
    title="Monthly New Subscribers"
    count={subscriptionCounts.monthly_subscription} // Use monthly_subscription count from API
    bgColor="bg-white"
    textColor="text-black"
    icon="/assets/user-add.svg"
    loading={countsLoading} 
  />
</div>

              </div>
            </div>

            <div className="w-full sm:w-1/3 mb-4">
  <SubscriptionStatusFilterComponent
    selectedStatus={filter}
    onStatusChange={handleFilterChange}
  />
</div>


            {/* Subscription List Section */}
            <h2 className="text-lg font-poppins text-[#292D32] mb-3 mt-3 ml-4 font-medium">
              Subscription list
            </h2>
            <div className="bg-white rounded-lg shadow-md p-6 relative font-poppins text-[#292D32]">
              <table className="w-full">
                <thead>
                  <tr className="text-left text-sm text-[#292D32]">
                    <th className="pb-3 text-[#292D32] font-bold">Sl. No</th>
                    <th className="pb-3 text-[#292D32] font-bold">Name</th>
                    <th className="pb-3 text-[#292D32] font-bold">Plan Name</th>
                    <th className="pb-3 text-[#292D32] font-bold hidden md:table-cell">Price</th>
                    <th className="pb-3 text-[#292D32] font-bold hidden md:table-cell">Start Date</th>
                    <th className="pb-3 text-[#292D32] font-bold hidden md:table-cell">Renewal Date</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredSubscriptions.length > 0 ? (
                    filteredSubscriptions.map((subscription, index) => (
                      <tr
                        key={index}
                        className="border-t border-[#FFEFEF] cursor-pointer"
                        onClick={() => setSelectedSubscription(subscription)}
                      >
                        <td className="py-3">{index + 1}</td>
                        <td className="py-3">{subscription.user_name}</td>
                        <td className="py-3">{subscription.plan_name}</td>
                        <td className="py-3 hidden md:table-cell">
                          {subscription.actual_price ? `$${subscription.actual_price}` : 'N/A'}
                        </td>
                        <td className="py-3 hidden md:table-cell">
                          {new Date(subscription.created_on).toLocaleDateString('en-US')}
                        </td>
                        <td className="py-3 hidden md:table-cell">
                          {new Date(subscription.ends_on).toLocaleDateString('en-US')}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center py-3">
                        No subscriptions found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {loading && (
                <div className="flex justify-center items-center py-4">
                  <FaSpinner className="animate-spin text-gray-500 text-xl" />
                </div>
              )}

              <div className="mt-4 text-center">
                {hasMore ? (
                  <button
                    onClick={handleShowMore}
                    className="px-3 py-1 bg-[#FFEFEF] text-[#232323] rounded-md text-sm"
                    disabled={loading}
                  >
                    {loading ? 'Loading...' : 'Show more'}
                  </button>
                ) : (
                  <p className="text-gray-500">No more users to load</p>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>

      {selectedSubscription && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            ref={modalRef}
            className="bg-white rounded-lg p-6 w-11/12 max-w-lg font-poppins relative"
          >
            {/* Close Icon */}
            <button
              onClick={() => setSelectedSubscription(null)}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <h3 className="text-lg font-semibold font-poppins mb-4 text-center">
              Subscription Details
            </h3>

\            <div className="flex items-center mb-6">
              <img
                src={selectedSubscription.profile_image}
                alt={`${selectedSubscription.user_name}'s profile`}
                className="w-16 h-16 rounded-full object-cover border-2 border-gray-300"
              />
              <div className="ml-4">
                <p className="text-lg font-bold text-gray-800 mb-1">
                  {selectedSubscription.user_name}
                </p>
                <p className="text-sm text-gray-500">{selectedSubscription.plan_name}</p>
              </div>
            </div>

            {/* Subscription Details */}
            <div className="flex flex-col gap-2">
              <div className="flex items-center">
                <p className="text-sm font-medium text-gray-600 w-32">Price:</p>
                <p className="text-sm text-gray-700">
                  {selectedSubscription.actual_price ? `$${selectedSubscription.actual_price}` : 'N/A'}
                </p>
              </div>
              <div className="flex items-center">
                <p className="text-sm font-medium text-gray-600 w-32">Start Date:</p>
                <p className="text-sm text-gray-700">
                  {new Date(selectedSubscription.created_on).toLocaleDateString('en-US')}
                </p>
              </div>
              <div className="flex items-center">
                <p className="text-sm font-medium text-gray-600 w-32">Renewal Date:</p>
                <p className="text-sm text-gray-700">
                  {new Date(selectedSubscription.ends_on).toLocaleDateString('en-US')}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionList;
