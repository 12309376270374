import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { editCompanyById } from "../../controller/dreamin_company";
import PhotoUpload from "./photo_upload";
import { storage } from "../../services/firebase";
import { getDownloadURL } from "firebase/storage";
import { ref } from "firebase/storage";
import { uploadBytesResumable } from "firebase/storage";

const EditCompanyModal = ({ company, onClose, onUpdate }) => {
    const [formData, setFormData] = useState({
        name: company.name || "",
        logo: [{ file: null, url: company.logo || "" }],
        category: company.category || "",
        district: company.district || "",
        about: company.about || "",
        area_of_operation: company.area_of_operation?.join(", ") || "",
        address: company.address || "",
        city: company.city || "",
        facebook: company.facebook || "",
        google: company.google || "",
        instagram: company.instagram || "",
        phone_number: company.phone_number || "",
        cover_images: company.cover_images?.map(img => ({ file: null, url: img })) || []
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    // Function to upload file to Firebase Storage
    // Function to upload file to Firebase Storage
    const uploadFileToFirebase = async (file, folder) => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, `${folder}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // You can add a progress indicator here if needed
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                },
                (error) => {
                    console.error("Upload Error:", error);
                    reject(error);
                },
                async () => {
                    try {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        resolve(downloadURL);
                    } catch (error) {
                        console.error("Error getting download URL:", error);
                        reject(error);
                    }
                }
            );
        });
    };

    // Handle image upload for logo
    const handleLogoUpload = async (newFiles) => {
        if (newFiles[0]?.file) {
            const uploadedUrl = await uploadFileToFirebase(newFiles[0].file, "logo");
            setFormData(prevState => ({
                ...prevState,
                logo: [{ file: null, url: uploadedUrl }]
            }));
        }
    };


    // Handle image upload for cover images
    const handleCoverImagesUpload = async (newFiles, index) => {
        console.log("Uploading cover image for index:", index);
        if (newFiles[0]?.file) {
            try {
                // Note the space in "cover Images" folder name
                const uploadedUrl = await uploadFileToFirebase(newFiles[0].file, "cover Images");
                const updatedCoverImages = [...formData.cover_images];
                updatedCoverImages[index] = { file: null, url: uploadedUrl };
                
                console.log("New cover image URL:", uploadedUrl);
                console.log("Updated cover images array:", updatedCoverImages);
                
                setFormData(prevState => ({
                    ...prevState,
                    cover_images: updatedCoverImages
                }));
            } catch (error) {
                console.error("Error uploading cover image:", error);
            }
        }
    };

    // Handle input change
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const updatedCompany = {
                ...formData,
                area_of_operation: formData.area_of_operation.split(",").map(op => op.trim()),
                cover_images: formData.cover_images.map(img => img.url),
                logo: formData.logo[0]?.url || ""
            };

            await editCompanyById(company.id, updatedCompany);
            onUpdate(updatedCompany);
            onClose();
        } catch (error) {
            console.error("Error updating company:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg p-6 w-11/12 md:w-3/4 max-h-[90vh] overflow-auto relative">
                <button className="absolute top-2 right-2 text-gray-600 hover:text-gray-800" onClick={onClose}>
                    ✕
                </button>

                <h2 className="text-xl font-semibold mb-4">Edit Company</h2>

                <form onSubmit={handleSubmit}>
                    {/* Logo Upload */}
                    <div className="flex justify-center mb-4">
    <PhotoUpload
        files={formData.logo}
        setFiles={handleLogoUpload}
        svgImagePath="/assets/upload.svg"
        editIconPath="/assets/edit-icon.svg"
        bgColor="#E6E6E6"
        width="130px"
        height="130px"
        type="logo"
        index="logo" // Add unique index for logo
    />
</div>


                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label className="block text-gray-700 font-semibold">Company Name</label>
                            <input type="text" name="name" value={formData.name} onChange={handleChange} className="w-full px-3 py-2 border rounded-md" />

                            <label className="block text-gray-700 font-semibold mt-2">Category</label>
                            <input type="text" name="category" value={formData.category} onChange={handleChange} className="w-full px-3 py-2 border rounded-md" />

                            <label className="block text-gray-700 font-semibold mt-2">District</label>
                            <input type="text" name="district" value={formData.district} onChange={handleChange} className="w-full px-3 py-2 border rounded-md" />

                            <label className="block text-gray-700 font-semibold mt-2">Area of Operation</label>
 <textarea 
                                name="area_of_operation"
                                value={formData.area_of_operation}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded-md"
                                rows="3"
                            />                        </div>

                        <div>
                            <label className="block text-gray-700 font-semibold">City</label>
                            <input type="text" name="city" value={formData.city} onChange={handleChange} className="w-full px-3 py-2 border rounded-md" />

                            <label className="block text-gray-700 font-semibold mt-2">Address</label>
                            <textarea name="address" value={formData.address} onChange={handleChange} className="w-full px-3 py-2 border rounded-md"></textarea>

                            <label className="block text-gray-700 font-semibold mt-2">Phone Number</label>
                            <input type="text" name="phone_number" value={formData.phone_number} onChange={handleChange} className="w-full px-3 py-2 border rounded-md" />
                        </div>
                    </div>

                    {/* About Section */}
                    <label className="block text-gray-700 font-semibold mt-2">About</label>
                    <textarea name="about" value={formData.about} onChange={handleChange} className="w-full px-3 py-2 border rounded-md"></textarea>

                    {/* Social Links */}
                    <div className="mt-6">
                        <h3 className="text-lg font-semibold mb-2">Social Links</h3>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div>
                                <label className="block text-gray-700 font-semibold">Facebook</label>
                                <input type="text" name="facebook" value={formData.facebook} onChange={handleChange} className="w-full px-3 py-2 border rounded-md" />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-semibold">Google</label>
                                <input type="text" name="google" value={formData.google} onChange={handleChange} className="w-full px-3 py-2 border rounded-md" />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-semibold">Instagram</label>
                                <input type="text" name="instagram" value={formData.instagram} onChange={handleChange} className="w-full px-3 py-2 border rounded-md" />
                            </div>
                        </div>
                    </div>
                    {/* Cover Images Upload */}
                    <div className="mt-4 flex space-x-2 overflow-x-auto">
    {formData.cover_images.map((image, index) => (
        <div key={index} className="flex-shrink-0">
            <PhotoUpload
                files={[image]}
                setFiles={(newFiles) => handleCoverImagesUpload(newFiles, index)}
                svgImagePath="/assets/upload.svg"
                editIconPath="/assets/edit-icon.svg"
                width="140px"
                height="140px"
                type="coverImage"
                index={index} // Pass the index to make each instance unique
            />
        </div>
    ))}
</div>

                    <div className="mt-4 flex justify-end">
                        <button type="submit" className="px-4 py-2 bg-[#222222] text-white rounded-md hover:bg-[#222222]" disabled={isSubmitting}>
                            {isSubmitting ? <FaSpinner className="animate-spin" /> : "Save Changes"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditCompanyModal;