import React, { useState, useEffect } from 'react';
import Header from '../Components/header';
import Sidebar from '../Components/sidebar';
import { FaSpinner } from 'react-icons/fa';
import { fetchCompanyList } from '../../controller/dreamin_company';
import { useNavigate } from 'react-router-dom';
import CompanyDetailsModal from '../Components/company_details';
import CategoryFilterComponent from '../Components/category_filter';

const CompanyListPage = () => {
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const limit = 10;

    const navigate = useNavigate();

    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [showModal, setShowModal] = useState(false);

    // Fetch companies from the API
    const fetchCompanies = async (page = 0, loadMore = false ,category = selectedCategory) => {
        const skipCount = page * limit;
        
        if (loadMore) {
            setIsLoadingMore(true);
        } else {
            setIsLoading(true);
        }

        try {
            const response = await fetchCompanyList({ 
                skip: skipCount,
                limit: limit ,
                category: category || null, 
            });
            
            const newData = response?.data || [];

            if (loadMore) {
                setCompanies(prevCompanies => [...prevCompanies, ...newData]);
            } else {
                setCompanies(newData);
            }

            setHasMore(response?.has_more_data ?? false);
        } catch (error) {
            console.error('Error fetching companies:', error);
        } finally {
            setIsLoading(false);
            setIsLoadingMore(false);
        }
    };

    // Initial fetch
    useEffect(() => {
        setCurrentPage(0);
        fetchCompanies(0, false);
    }, [selectedCategory]);

    // Fetch more companies when "Show More" is clicked
    const handleShowMore = () => {
        const nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        fetchCompanies(nextPage, true);
    };

    return (
        <>
            <Header />
            <div className="flex h-screen bg-[#F3F3F3] font-poppins">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <main className="flex-1 overflow-x-hidden overflow-y-auto p-6">
                        <div className="mt-6">
                            <h1 className="text-xl font-semibold mb-6 text-[#545454]">Company List</h1>

                            <div className="bg-white rounded-lg shadow-md p-4 mt-4">
                                {isLoading ? (
                                    <div className="flex justify-center items-center h-64">
                                        <FaSpinner className="animate-spin text-4xl text-gray-500" />
                                    </div>
                                ) : (
                                    <div className="overflow-y-auto">
                                        <table className="w-full text-sm">
                                            <thead>
                                                <tr className="text-center text-sm font-poppins">
                                                    <th className="pb-3 font-semibold">Sl. No</th>
                                                    <th className="pb-3 font-semibold">Logo</th>
                                                    <th className="pb-3 font-semibold">Company Name</th>
                                                    <th className="pb-3 font-semibold">
                                                        <CategoryFilterComponent
                                                            selectedCategory={selectedCategory}
                                                            onCategoryChange={setSelectedCategory}
                                                        />
                                                    </th>
                                                    <th className="pb-3 font-semibold">District</th>
                                                    <th className="pb-3 font-semibold">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {companies.length > 0 ? (
                                                    companies.map((company, index) => (
                                                        <tr key={company.id} className="text-center border-t border-[#FFEFEF] hover:bg-gray-100">
                                                            <td className="py-3">{index + 1}</td>
                                                            <td className="py-3">
                                                                <img
                                                                    src={company.logo || './default-logo.svg'}
                                                                    alt="Company Logo"
                                                                    className="w-12 h-12 object-cover rounded-full mx-auto"
                                                                />
                                                            </td>
                                                            <td className="py-3">{company.name || 'N/A'}</td>
                                                            <td className="py-3">{company.category || 'N/A'}</td>
                                                            <td className="py-3">{company.district || 'N/A'}</td>
                                                            <td className="py-3">
                                                                <button
                                                                    className="px-3 py-1 bg-[#222222] text-white rounded-md text-sm"
                                                                    onClick={() => {
                                                                        setSelectedCompanyId(company.id);
                                                                        setShowModal(true);
                                                                    }}
                                                                >
                                                                    View
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="6" className="text-center py-3">
                                                            No companies found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        {isLoadingMore && (
                                            <div className="text-center py-3">
                                                <FaSpinner className="animate-spin text-2xl text-gray-500 inline-block" />
                                            </div>
                                        )}
                                        {companies.length > 0 && hasMore && !isLoadingMore && (
                                            <div className="flex justify-center mt-4">
                                                <button
                                                    onClick={handleShowMore}
                                                    className="px-2 py-2 bg-[#222222] text-[#FFFFFF] rounded-md"
                                                >
                                                    Show more
                                                </button>
                                            </div>
                                        )}
                                        {!hasMore && companies.length > 0 && (
                                            <div className="text-center mt-4 text-gray-500">
                                                No more companies to load.
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </main>
                </div>
            </div>

            {showModal && selectedCompanyId && (
                <CompanyDetailsModal companyId={selectedCompanyId} onClose={() => setShowModal(false)} />
            )}
        </>
    );
};

export default CompanyListPage;