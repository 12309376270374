import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Components/header';
import Sidebar from '../Components/sidebar';
import { FaCheck, FaTimes, FaRegUser } from 'react-icons/fa';
import { fetchCompanyProfile, submitVerificationUpdate } from '../../controller/verification_controller'; // Import the controller functions
import LoaderSpinner from '../Components/loader_spinner';
import { FaSpinner } from 'react-icons/fa';

const Verification = () => {
    const { uid } = useParams(); // Extract the uid from the URL
    const navigate = useNavigate(); // Use navigate for routing
    const [companyProfile, setCompanyProfile] = useState(null); // Hold company profile data
    const [loading, setLoading] = useState(true); // For initial data loading
    const [buttonLoading, setButtonLoading] = useState(false); // State for button loading spinner
    const [remarks, setRemarks] = useState(''); // State for remarks

    // State for field statuses
    const [status, setStatus] = useState({
        name: null,
        manager: null,
        website: null,
        location: null,
        authorization_document: null,
        trading_register: null,
        trading_license: null,
        tenancy_contract: null,
    });

    // Load company profile data
    useEffect(() => {
        fetchCompanyProfile(uid, setCompanyProfile, setLoading); // Call controller function to fetch company profile
        console.log('Fetched company profile:', companyProfile); // Log companyProfile for debugging
    }, [uid]);

    // Handle status change for each field (true for verified, false for rejected)
    const handleStatusChange = (field, value) => {
        setStatus((prevStatus) => ({
            ...prevStatus,
            [field]: value,
        }));
    };

    // Function to submit the updated verification statuses
    const handleSubmit = () => {
        let verificationStatus;

        // If any value is false (rejected), set status to VERIFICATION_REJECTED
        if (Object.values(status).some(fieldStatus => fieldStatus === false)) {
            verificationStatus = "VERIFICATION_REJECTED";
        } else {
            verificationStatus = "VERIFICATION_COMPLETED";
        }

        // Prepare the payload
        const payload = {
            name: status.name === false,
            manager: status.manager === false,
            website: status.website === false,
            location: status.location === false,
            authorization_document: status.authorization_document === false,
            trading_register: status.trading_register === false,
            trading_license: status.trading_license === false,
            tenancy_contract: status.tenancy_contract === false,
            remarks: remarks,
            status: verificationStatus,
        };

        console.log('Payload:', payload);
        submitVerificationUpdate(uid, payload, remarks, navigate, setButtonLoading); // Call controller function to submit the update
    };

    // If still loading data, show the loading spinner
    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <FaSpinner className="animate-spin text-4xl text-gray-500" />
            </div>
        );
    }

    // Render the main content after loading completes
    return (
        <>
            <Header />
            <div className="flex h-[calc(100vh-3rem)] bg-[#F3F3F3]">
                <div className="fixed top-12 bottom-0 left-0 w-[256px]">
                    <Sidebar />
                </div>
                <div className="flex flex-col w-full pl-6 pr-6 pt-10 space-y-4 ml-[256px] overflow-y-auto h-full p-4">
                    {/* Heading and Admin View Button */}
                    <div className="flex justify-between items-center mb-6 ">
                        <h1 className="text-2xl font-semibold font-poppins text-[#545454] ml-2">Company profile</h1>
                        <button className="flex items-center bg-white text-black font-medium px-4 py-2 rounded-lg shadow">
                            <FaRegUser className="mr-2 text-red-500 " />
                            Admin View
                        </button>
                    </div>

                    {/* Company Info Section with Approved/Rejected Buttons */}
                    <div className="flex flex-col bg-white rounded-lg p-4">
                        <div className="flex justify-between items-center mb-6">
                            <div className="flex items-center">
                                <img src="/assets/profile.jpg" alt="Logo" className=" w-22 h-16 rounded-lg" />
                                <div className="ml-4 mt-4">
                                    <h1 className="text-xl font-medium text-black">
                                        {companyProfile?.name || 'Not Found'}
                                    </h1>
                                    <p className="text-black font-medium"> {companyProfile?.id || 'Not Found'}</p>
                                </div>
                            </div>
                            {/* Approved/Rejected buttons for Name */}
                            <div className="flex gap-2">
                                <button
                                    onClick={() => handleStatusChange('name', true)}
                                    className={`px-4 py-2 bg-gray-200 rounded-md flex items-center gap-2 ${
                                        status.name === true
                                            ? "border-green-500 text-green-500 border"
                                            : "border-gray-300 text-gray-500 border"
                                    }`}
                                >
                                    <FaCheck />
                                    Approved
                                </button>
                                <button
                                    onClick={() => handleStatusChange('name', false)}
                                    className={`px-4 py-2 bg-gray-200 rounded-md flex items-center gap-2 ${
                                        status.name === false
                                            ? "border-red-500 text-red-500 border"
                                            : "border-gray-300 text-gray-500 border"
                                    }`}
                                >
                                    <FaTimes />
                                    Rejected
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Verification Sections */}
                    <div className="grid grid-cols-3 bg-white p-3">
                        {/* Account Managed By */}
                        <div className="flex flex-col items-center border-[#E2E8F0] p-4">
                            <img src="/assets/account_managed_by.svg" alt="Account Managed By Icon" className="w-16 h-16" />
                            <span className="text-black font-medium font-poppins">Account managed by</span>
                            <span className="text-gray-900 font-poppins">
                                {companyProfile?.manager || 'Not Found'}
                            </span>
                            <div className="flex gap-2 mt-2">
                                <button
                                    onClick={() => handleStatusChange('manager', true)}
                                    className={`px-4 py-2 bg-gray-200 rounded-md flex items-center gap-2 ${
                                        status.manager === true
                                            ? "border-green-500 text-green-500 border"
                                            : "border-gray-300 text-gray-500 border"
                                    }`}
                                >
                                    <FaCheck />
                                    Approved
                                </button>
                                <button
                                    onClick={() => handleStatusChange('manager', false)}
                                    className={`px-4 py-2 bg-gray-200 rounded-md flex items-center gap-2 ${
                                        status.manager === false
                                            ? "border-red-500 text-red-500 border"
                                            : "border-gray-300 text-gray-500 border"
                                    }`}
                                >
                                    <FaTimes />
                                    Rejected
                                </button>
                            </div>
                        </div>

                        {/* Website URL */}
                        <div className="flex flex-col items-center border-l border-[#E2E8F0] p-4">
                            <img src="/assets/account_managed_by.svg" alt="Account Managed By Icon" className="w-16 h-16" />
                            <span className="text-black font-medium font-poppins">Website URL</span>
                            <span className="text-gray-900 font-poppins">
                                {companyProfile?.website || 'Not Found'}
                            </span>
                            <div className="flex gap-2 mt-2">
                                <button
                                    onClick={() => handleStatusChange('website', true)}
                                    className={`px-4 py-2 bg-gray-200 rounded-md flex items-center gap-2 ${
                                        status.website === true
                                            ? "border-green-500 text-green-500 border"
                                            : "border-gray-300 text-gray-500 border"
                                    }`}
                                >
                                    <FaCheck />
                                    Approved
                                </button>
                                <button
                                    onClick={() => handleStatusChange('website', false)}
                                    className={`px-4 py-2 bg-gray-200 rounded-md flex items-center gap-2 ${
                                        status.website === false
                                            ? "border-red-500 text-red-500 border"
                                            : "border-gray-300 text-gray-500 border"
                                    }`}
                                >
                                    <FaTimes />
                                    Rejected
                                </button>
                            </div>
                        </div>

                        {/* Office Location */}
                        <div className="flex flex-col items-center border-l border-[#E2E8F0] p-4">
                            <img src="/assets/account_managed_by.svg" alt="Account Managed By Icon" className="w-16 h-16" />
                            <span className="text-black font-medium font-poppins">Office Location</span>
                            <span className="text-gray-900 font-poppins">
                                {companyProfile?.address || 'Not Found'}
                            </span>
                            <div className="flex gap-2 mt-2">
                                <button
                                    onClick={() => handleStatusChange('location', true)}
                                    className={`px-4 py-2 bg-gray-200 rounded-md flex items-center gap-2 ${
                                        status.location === true
                                            ? "border-green-500 text-green-500 border"
                                            : "border-gray-300 text-gray-500 border"
                                    }`}
                                >
                                    <FaCheck />
                                    Approved
                                </button>
                                <button
                                    onClick={() => handleStatusChange('location', false)}
                                    className={`px-4 py-2 bg-gray-200 rounded-md flex items-center gap-2 ${
                                        status.location === false
                                            ? "border-red-500 text-red-500 border"
                                            : "border-gray-300 text-gray-500 border"
                                    }`}
                                >
                                    <FaTimes />
                                    Rejected
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Documents Section */}
                    <div className="bg-white rounded-lg p-6 items-start">
                        <h2 className="text-lg font-medium font-poppins mb-4">Documents</h2>
                        <div className="grid grid-cols-4 gap-12">
                            {['authorization_document', 'trading_license', 'trading_register', 'tenancy_contract'].map((docKey, index) => (
                                <div key={index} className="flex flex-col items-center">
                                    <span className="text-[#989898] font-medium mb-2">{docKey.replace('_', ' ').toLowerCase()}</span>

                                    <div className="w-72 h-32 bg-[#F2F2F2] flex justify-center items-center rounded-md mb-2">
                                        {/* Add a null check for companyProfile */}
                                        {companyProfile && companyProfile[docKey] ? (
                                            companyProfile[docKey] !== '' ? (
                                                <a href={companyProfile[docKey]} target="_blank" rel="noopener noreferrer">
                                                    <img
                                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgbYYh8q-lJnFL5XQjAegR-KdCTFmJz80MFQ&s"
                                                        alt="PDF Icon"
                                                        className="w-12 h-12"
                                                    />
                                                </a>
                                            ) : (
                                                <p>Not Available</p>
                                            )
                                        ) : (
                                            <p>Not Found</p>
                                        )}
                                    </div>

                                    <div className="flex gap-2 mt-2 w-full justify-center">
                                        <button
                                            onClick={() => handleStatusChange(docKey, true)}
                                            className={`px-6 py-2 bg-gray-200 rounded-md flex items-center gap-2 ${
                                                status[docKey] === true
                                                    ? "border-green-500 text-green-500 border"
                                                    : "border-gray-300 text-gray-500 border"
                                            }`}
                                        >
                                            <FaCheck />
                                            Approved
                                        </button>
                                        <button
                                            onClick={() => handleStatusChange(docKey, false)}
                                            className={`px-6 py-2 bg-gray-200 rounded-md flex items-center gap-2 ${
                                                status[docKey] === false
                                                    ? "border-red-500 text-red-500 border"
                                                    : "border-gray-300 text-gray-500 border"
                                            }`}
                                        >
                                            <FaTimes />
                                            Rejected
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Remarks Section */}
                    <div className="bg-white rounded-lg p-4 items-start mt-4">
                        <h2 className="text-lg font-medium font-poppins mb-2">Remarks</h2>
                        <textarea
                            className="w-full h-24 p-4 border rounded-md bg-[#F2F2F2] text-[#545454] font-poppins"
                            placeholder="Enter any remarks..."
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="flex justify-end mt-4 ">
                        <button
                            onClick={handleSubmit}
                            className="bg-[#DB0000] text-white px-8 py-2 rounded-lg shadow flex items-center"
                            disabled={buttonLoading} // Disable button while loading
                        >
                            {buttonLoading ? (
                                <LoaderSpinner visible={true} />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Verification;
