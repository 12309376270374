import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi"; // Import the left arrow icon
import { Helmet } from "react-helmet-async";
import Hamburger from "../Hamburger/Hamburger";
import { getBookmarks } from "../../controller/bookmarks";
const BookmarkedCompanies = () => {
  const [categories, setCategories] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const customerId = localStorage.getItem("customerUid");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookmarkedCompanies = async () => {
      if (!customerId) {
        console.error("Customer ID is missing");
        setIsLoading(false); // Stop loading
        return;
      }

      try {
        // Call the API instead of Firebase
        const response = await getBookmarks(customerId, 0, 10); // Pass parameters as needed
        const bookmarks = response.data;

        const bookmarksByCategory = {};

        bookmarks.forEach((bookmark) => {
          const category = bookmark.categoryType.trim();

          if (!bookmarksByCategory[category]) {
            bookmarksByCategory[category] = [];
          }
          bookmarksByCategory[category].push(bookmark);
        });

        setCategories(bookmarksByCategory);
      } catch (error) {
        console.error("Error fetching bookmarked companies:", error);
      } finally {
        setIsLoading(false); // Stop loading after data is fetched
      }
    };

    fetchBookmarkedCompanies();
  }, [customerId]);

  const handleNavigateToDetail = (companyId) => {
    navigate("/detailpage", { state: { companyId } });
  };

  // Render loading spinner if data is still being fetched
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="loader border-t-4 border-b-4 border-gray-500 rounded-full w-8 h-8 animate-spin"></div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Easy Access to Favorites</title>
        <meta name="description" content="Event Management Services" />
        <link rel="canonical" href="/savedpage" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 lg:w-[88%] flex flex-col items-center p-6 font-inter">
        {/* Header with Back Arrow & Centered Heading */}
        <div className="relative w-full max-w-5xl flex items-center justify-center mb-8">
          {/* Back Button (Left-Aligned) */}
          <div
            className="absolute left-0 cursor-pointer text-gray-700"
            onClick={() => navigate("/")}
          >
            <BiChevronLeft className="text-3xl md:text-4xl" />
          </div>

          {/* Centered Heading with Responsive Font Size */}
          <h1 className="text-xl md:text-2xl lg:text-2xl font-normal font-inter text-center">
            Bookmarks
          </h1>
        </div>

        <div className="w-full max-w-5xl space-y-8">
          {Object.keys(categories).length > 0 ? (
            Object.keys(categories).map((category) => (
              <div key={category} className="category-section">
                <h2 className="text-lg md:text-2xl lg:text-xl font-normal mb-4 capitalize font-inter">
                  {category}
                </h2>
                <div className="flex gap-4 overflow-x-auto">
                  {categories[category].map((company) => (
                    <div
                      key={company.companyDocId}
                      className="flex-shrink-0 w-60 p-4 bg-customGray rounded-lg shadow-md text-center cursor-pointer"
                      onClick={() => handleNavigateToDetail(company.companyDocId)}
                    >
                      <img
                        src={company.companyImageUrl || "https://via.placeholder.com/150"}
                        alt={company.companyName}
                        className="w-24 h-24 mx-auto rounded-full object-cover"
                      />
                  <h3 className="text-base md:text-lg font-medium mt-4 font-inter">
                        {company.companyName}
                      </h3>
                      <p className="text-xs md:text-sm text-gray-500 mt-1 font-inter">
                        {company.categoryType}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500 text-center font-inter">No bookmarks found.</p>
          )}
        </div>
        <Hamburger />
      </div>
    </>
  );
};

export default BookmarkedCompanies;
