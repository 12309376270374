// urls.js

const BASE_URL = "https://dreamin-backend-646690260914.us-central1.run.app/api/v1";

export const GET_COMPANY_DETAILS = `${BASE_URL}/company`;
export const GET_COMPANY_BY_ID = `${BASE_URL}/company/{id}`;
export const GET_COMPANY_BY_UID = `${BASE_URL}/company/{uid}`;
export const GET_ALL_COMPANIES = `${BASE_URL}/companies`;

export default BASE_URL;
