import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const LoadingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the order ID from the query parameters
  const getOrderIdFromParams = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('order_id');
  };

  const callApiAndNavigate = async (orderId) => {
    try {
      // Call the API to confirm the payment status
      const response = await axios.get(
        `https://dreamin-backend-646690260914.asia-south1.run.app/api/v1/billings/confirm?order_id=${orderId}`
      );

      if (response.status === 200) {
        setTimeout(() => navigate(`/success`), 2000); // Navigate to success after 2 seconds
      } else if (response.status === 400) {
        setTimeout(() => navigate(`/failure`), 2000); // Navigate to failure after 2 seconds
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error during API call:', error);
      setTimeout(() => navigate(`/failure`), 2000); // Navigate to failure after 2 seconds
    }
  };

  useEffect(() => {
    const orderId = getOrderIdFromParams();
    if (orderId) {
      callApiAndNavigate(orderId); // Trigger the API call
    } else {
      console.error('Order ID not found in query parameters');
      setTimeout(() => navigate('/checkout'), 2000); // Redirect to checkout after 2 seconds
    }
  }, [location]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen bg-gray-50">
      <div className="flex items-center justify-center">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
      </div>
    </div>
  );
};

export default LoadingPage;
