import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "../pop_up";
import { getFirestore, collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../firebaseConfig";
import { showToast } from "../toast";
import { BiChevronLeft } from "react-icons/bi";
import { FaTrash } from "react-icons/fa";
import { getAuth } from "firebase/auth";
import Hamburger from "../Hamburger/Hamburger";
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);  // Add this line


const Settings = () => {
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");
    const [popupAction, setPopupAction] = useState(null);

    const handleEditProfile = () => {
        navigate('/editprofile');
    };

    const handleBack = () => {
        navigate('/profile');  // Navigate back to Profile
    };

    const handleDeleteAccount = () => {
        setPopupMessage("Are you sure you want to delete your account?");
        setPopupAction(() => confirmDeleteAccount);
        setShowPopup(true);
    };

    const confirmDeleteAccount = async () => {
        try {
            const customerUid = localStorage.getItem("customerUid");
            if (!customerUid) {
                showToast("Customer UID not found. Please try again.", "error");
                setShowPopup(false);
                return;
            }
    
            const customerRef = collection(db, "customer");
            const q = query(customerRef, where("customerUid", "==", customerUid));
            const querySnapshot = await getDocs(q);
    
            if (!querySnapshot.empty) {
                const customerDoc = querySnapshot.docs[0];
                const customerId = customerDoc.id;
    
                // Sign out from Firebase Auth first
                await auth.signOut();
                
                // Update Firestore to mark as deleted
                const customerDocRef = doc(db, "customer", customerId);
                await updateDoc(customerDocRef, { isDeleted: true });
    
                // Clear localStorage
                localStorage.clear();
    
                showToast("Account successfully deleted.", "success");
    
                // Navigate with replace instead of push to prevent back navigation
                navigate("/", { replace: true });
            } else {
                showToast("Customer not found. Please try again.", "error");
            }
        } catch (error) {
            console.error("Error deleting account:", error);
            showToast("An error occurred. Please try again.", "error");
        } finally {
            setShowPopup(false);
        }
    };
    
    

    return (
        <div className="flex flex-col items-center min-h-screen lg:w-[88%] w-full bg-white font-inter px-6">
            {/* Back Button & Title */}
            <div className="relative w-full max-w-xs mx-auto mt-6">
                <button onClick={handleBack} className="absolute left-0 text-gray-700">
                    <BiChevronLeft className="text-3xl md:text-4xl" />
                </button>
                <h2 className="text-center text-2xl font-semibold text-gray-900">Settings</h2>
            </div>

            {/* Options */}
            <div className="w-full max-w-xs mx-auto mt-8 space-y-3">
                {/* Edit Profile */}
                <div
                    className="flex items-center justify-between bg-gray-200 px-4 py-4 rounded-xl cursor-pointer hover:bg-gray-300"
                    onClick={handleEditProfile}
                >
                    <span className="text-gray-800 text-sm font-medium">Edit Profile</span>
                </div>

                {/* Delete Account */}
                <div
                    className="flex items-center justify-between bg-gray-200 px-4 py-4 rounded-xl cursor-pointer hover:bg-gray-300"
                    onClick={handleDeleteAccount}
                >
                    <span className="text-gray-800 text-sm font-medium">Delete Account</span>
                    <FaTrash className="text-lg text-gray-700" />
                </div>
            </div>

            {/* Logout Popup */}
            {showPopup && (
                <Popup message={popupMessage} onConfirm={popupAction} onCancel={() => setShowPopup(false)} />
            )}
            <Hamburger/>
        </div>
    );
};

export default Settings;
