import React, { useState, useEffect, useRef, useCallback } from 'react';
import Header from '../Components/header';
import Sidebar from '../Components/sidebar';
import { FaSpinner } from 'react-icons/fa';
import { getBusinessHelpList } from '../../controller/dreamin_support';
import BusinessHelpModal from '../Components/buisness_supportview';

const Supportlist = () => {
    const [supports, setSupports] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [skip, setSkip] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const limit = 10;
    const mainContentRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBusinessId, setSelectedBusinessId] = useState(null);


    const fetchSupportsData = useCallback(async (loadMore = false) => {
        if (loadMore) {
            setIsLoadingMore(true);
        } else {
            setIsLoading(true);
        }

        try {
            const response = await getBusinessHelpList({ skip, limit });
            const data = response.data ? response.data : []; // Ensure correct response format

            console.log("Fetched Data:", data);

            if (data.length === 0) {
                setHasMore(false);
            } else {
                setSupports((prevSupports) => loadMore ? [...prevSupports, ...data] : data);
                setHasMore(data.length === limit);
            }
        } catch (error) {
            console.error('Error fetching supports:', error);
        } finally {
            setIsLoading(false);
            setIsLoadingMore(false);
        }
    }, [skip]);

    useEffect(() => {
        fetchSupportsData(skip > 0);
    }, [skip]);

    useEffect(() => {
        setSkip(0);
        setSupports([]);
        setHasMore(true);
        fetchSupportsData();
    }, []);

    const handleShowMore = () => {
        setSkip((prevSkip) => prevSkip + 10);
    };

    const handleViewClick = async (id) => {
        setIsModalOpen(true);
        setSelectedBusinessId(id);
    };
    

    return (
        <>
            <Header />
            <div className="flex h-screen bg-[#F3F3F3] font-poppins">
                <Sidebar />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <main ref={mainContentRef} className="flex-1 overflow-x-hidden overflow-y-auto p-6">
                        <div className="mt-6">
                            <h1 className="text-xl font-semibold mb-6 text-[#545454]">Support - Business Help</h1>
                            <div className="bg-white rounded-lg shadow-md p-4 mt-4">
                                {isLoading ? (
                                    <div className="flex justify-center items-center h-64">
                                        <FaSpinner className="animate-spin text-4xl text-gray-500" />
                                    </div>
                                ) : (
                                    <div className="overflow-y-auto">
                                        <table className="w-full text-sm">
                                            <thead>
                                                <tr className="text-center text-sm font-poppins">
                                                    <th className="pb-3 font-semibold">Sl. No</th>
                                                    <th className="pb-3 font-semibold">Company ID</th>
                                                    <th className="pb-3 font-semibold text-start">Company Name</th>
                                                    <th className="pb-3 font-semibold">Title</th>
                                                    <th className="pb-3 font-semibold hidden md:table-cell">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {supports.length > 0 ? (
                                                    supports.map((support, index) => (
                                                        <tr
                                                            key={index}
                                                            className="text-center border-t border-[#FFEFEF] cursor-pointer hover:bg-gray-100"
                                                        >
                                                            <td className="py-3">{index + 1}</td>
                                                            <td className="py-3">{support.company_id || 'N/A'}</td>
                                                            <td className="py-3 text-start">{support.company_name || 'N/A'}</td>
                                                            <td className="py-3">{support.title || 'N/A'}</td>
                                                            <td className="py-3 hidden md:table-cell">
                                                            <button
                                                                    className="px-3 py-1 bg-[#222222] rounded-md text-sm text-[#FFFFFF]"
                                                                    onClick={() => handleViewClick(support.id)} // Pass support.id when clicking
                                                                >
                                                                    View
                                                                </button>

                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5" className="text-center py-3">
                                                            No business help data found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        {isLoadingMore && (
                                            <div className="text-center py-3">
                                                <FaSpinner className="animate-spin text-2xl text-gray-500 inline-block" />
                                            </div>
                                        )}
                                        {supports.length > 0 && hasMore && !isLoadingMore && (
                                            <div className="flex justify-center mt-4">
                                                <button
                                                    onClick={handleShowMore}
                                                    className="px-2 py-2 bg-[#222222] text-[#FFFFFF] rounded-md"
                                                >
                                                    Show more
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}


                                            {isModalOpen && selectedBusinessId && (
                                                <BusinessHelpModal
                                                    isOpen={isModalOpen}
                                                    onClose={() => setIsModalOpen(false)}
                                                    businessId={selectedBusinessId} // Pass the selected business ID
                                                />
                                            )}

                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
};

export default Supportlist;
