import { api } from "./api";
import {
  GET_CAROUSEL_LIST,
  GET_CAROUSEL_BY_ID,
  ADD_CAROUSEL,
  EDIT_CAROUSEL_BY_ID,
  DELETE_CAROUSEL_BY_ID,
} from "./urls";

export const fetchCarousels = async ({ skip, limit }) => {
  try {
    const response = await api.get(GET_CAROUSEL_LIST, {
      params: { skip, limit },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching carousels:", error);
    throw error;
  }
};

export const fetchCarouselById = async (id) => {
  try {
    const response = await api.get(GET_CAROUSEL_BY_ID(id));
    return response.data;
  } catch (error) {
    console.error("Error fetching carousel by ID:", error);
    throw error;
  }
};

export const addCarousel = async (carouselData) => {
  try {
    const response = await api.post(ADD_CAROUSEL, carouselData);
    return {
      success: true,
      message: response.data.message || "Carousel Created successfully",
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "Failed to add carousel",
      error: error
    };
  }
};


export const editCarousel = async (id, carouselData) => {
  try {
    const response = await api.put(EDIT_CAROUSEL_BY_ID(id), carouselData);
    return response.data;
  } catch (error) {
    console.error("Error editing carousel:", error);
    throw error;
  }
};

export const deleteCarousel = async (id) => {
  try {
    const response = await api.delete(DELETE_CAROUSEL_BY_ID(id));
    return response.data;
  } catch (error) {
    console.error("Error deleting carousel:", error);
    throw error;
  }
};
