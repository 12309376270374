import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { getFirestore, collection, addDoc, query, where, getDocs, updateDoc } from "firebase/firestore";
import { FieldValue } from "firebase/firestore";
import { increment } from "firebase/firestore";
import { Toast } from "../toast";
import { showToast } from "../toast";
import RingSpinner from "../loaderspinner";
import moment from "moment";
import { Timestamp } from "firebase/firestore";
import { BiChevronLeft } from "react-icons/bi"; // Import left arrow icon
import Hamburger from "../Hamburger/Hamburger";
import { useLocation } from "react-router-dom";
const db = getFirestore();

const ReviewPage = () => {
  const { companyDocId } = useParams();
  const location = useLocation();
const companyId = location.state?.companyId || localStorage.getItem("companyId");

  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);


  // Review input states
  const [reviewText, setReviewText] = useState("");
  const [selectedRating, setSelectedRating] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const navigate =useNavigate()

  useEffect(() => {
    if (!companyId) {
        navigate("/"); // Redirect if companyId is missing (same as PackagePage)
        return;
    }
    localStorage.setItem("companyId", companyId);
}, [companyId, navigate]);


  useEffect(() => {
    const fetchReviews = async () => {
      try {
        if (!companyDocId) return;

        const reviewsRef = collection(db, "reviews");
        const q = query(reviewsRef, where("companyDocId", "==", companyDocId));
        const querySnapshot = await getDocs(q);

        let totalRating = 0;
        let reviewsList = [];

        querySnapshot.forEach((doc) => {
          const reviewData = doc.data();
        
          // ✅ Ensure timeOfReview is a valid date
          if (reviewData.timeOfReview && reviewData.timeOfReview.seconds) {
            reviewData.timeOfReview = new Date(reviewData.timeOfReview.seconds * 1000);
          }
        
          reviewsList.push(reviewData);
        
        
          totalRating += reviewData.starCount || 0;
        });

        setReviews(reviewsList);
        setAverageRating(reviewsList.length > 0 ? (totalRating / reviewsList.length).toFixed(1) : "0.0");
        setLoading(false);
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setLoading(false);
      }
    };

    fetchReviews();
  }, [companyDocId]);

  const fetchCompanyDetails = async () => {
    const companyRef = collection(db, "company");
    const companyQuery = query(companyRef, where("companyDocId", "==", companyDocId));
    const companySnapshot = await getDocs(companyQuery);

    if (!companySnapshot.empty) {
      const companyData = companySnapshot.docs[0].data();
      return {
        companyName: companyData.companyName || "Unknown",
        companyProfilePicture: companyData.companyProfilePicture || "",
      };
    }
    return null;
  };

  const fetchCustomerDetails = async () => {
    const customerUid = localStorage.getItem("customerUid");
    if (!customerUid) {
      showToast("User not logged in.", "error");
      return null;
    }

    const customerRef = collection(db, "customer");
    const customerQuery = query(customerRef, where("customerUid", "==", customerUid));
    const customerSnapshot = await getDocs(customerQuery);

    if (!customerSnapshot.empty) {
      const customerData = customerSnapshot.docs[0].data();
      return {
        customerId: customerData.customerId,
        reviewerName: customerData.name || "Anonymous",
        reviewerImageUrl: customerData.imageUrl || "",
      };
    }
    return null;
  };

  
  const handleSubmitReview = async () => {
    if (selectedRating === 0 || reviewText.trim() === "") {
      showToast("Please select a rating and enter a review.", "error");
      return;
    }
  
    setSubmitting(true);
  
    try {
      const companyDetails = await fetchCompanyDetails();
      if (!companyDetails) {
        showToast("Company details not found.", "error");
        setSubmitting(false);
        return;
      }
  
      const customerDetails = await fetchCustomerDetails();
      if (!customerDetails) {
        showToast("User details not found.", "error");
        setSubmitting(false);
        return;
      }
  
      const reviewData = {
        companyDocId,
        companyName: companyDetails.companyName,
        companyProfilePicture: companyDetails.companyProfilePicture,
        reviewerId: customerDetails.customerId,
        reviewerName: customerDetails.reviewerName,
        reviewerImageUrl: customerDetails.reviewerImageUrl,
        starCount: selectedRating,
        reviewText,
        isReplied: false,
        replyText: "",
        replyTime: null,
        timeOfReview: Timestamp.now(), // Store as Firestore Timestamp
      };
  
      // Add the review to the "reviews" collection
      await addDoc(collection(db, "reviews"), reviewData);
  
      // Fetch the company document to update counts
      const companyRef = collection(db, "company");
      const companyQuery = query(companyRef, where("companyDocId", "==", companyDocId));
      const companySnapshot = await getDocs(companyQuery);
  
      if (!companySnapshot.empty) {
        const companyDoc = companySnapshot.docs[0]; // Get the first matching document
  
        // Update totalReviewCount and totalStarCount using atomic increments
        await updateDoc(companyDoc.ref, {
          totalReviewCount: increment(1), // Increment totalReviewCount by 1
          totalStarCount: increment(selectedRating), // Add selectedRating to totalStarCount
        });
      }
  
      showToast("Review submitted successfully!", "success");
  
      // Update local state
      setReviews((prev) => [...prev, { ...reviewData, timeOfReview: new Date() }]);
      setAverageRating(
        ((reviews.reduce((acc, r) => acc + r.starCount, 0) + selectedRating) / (reviews.length + 1)).toFixed(1)
      );
  
      setShowModal(false);
      setReviewText("");
      setSelectedRating(0);
    } catch (error) {
      showToast("Failed to submit review.", "error");
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };
  

  const RatingStars = ({ rating, onSelect, size = "text-lg" }) => (
    <div className={`flex justify-center space-x-1 ${size}`}>
      {[...Array(5)].map((_, i) => (
        <FaStar
          key={i}
          className={`cursor-pointer transition-colors duration-300 ${
            i < rating ? "text-black" : "text-gray-300"
          }`}
          onClick={() => onSelect && onSelect(i + 1)}
        />
      ))}
    </div>
  );
  

  if (loading) return <div className="text-center mt-10 text-gray-600">Loading reviews...</div>;

  return (
    <div className=" min-h-screen w-[88%] px-6 py-6 flex flex-col items-center font-inter">
      <Toast />

      <div
        className="absolute top-6 left-6 md:left-10 lg:left-12 xl:left-16 text-gray-700 cursor-pointer"
        onClick={() => navigate("/detailpage", { state: { companyId } })}
        >
        <BiChevronLeft className="text-3xl md:text-4xl" />
      </div>


      {/* Header */}
      <h1 className="text-lg font-semibold  font-inter mb-4">Ratings and Reviews</h1>

      {/* Rating and Add Review Section */}
      <div className="w-full max-w-md flex justify-between items-center">
        <div>
        <div className="text-4xl font-normal font-inter ">{averageRating}</div>
        <RatingStars rating={Math.round(averageRating)} size="text-2xl" />
        </div>
        <div className="text-right">
          <p className="text-sm text-gray-500 font-inter">Based on {reviews.length} Reviews</p>
          <button
            className="bg-black text-white rounded-md py-2 px-4 mt-2 text-sm"
            onClick={() => setShowModal(true)}
          >
            Add review
          </button>
        </div>
      </div>

      {/* Reviews List */}
      <div className="mt-4 w-full max-w-md flex flex-col items-start">
  {reviews.map((review, index) => (
    <div key={index} className="bg-customGray p-4 rounded-lg mt-6 shadow-sm w-full">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <img
            src={review.reviewerImageUrl || "/api/placeholder/32/32"}
            alt="User"
            className="w-8 h-8 rounded-full bg-gray-200"
          />
          <span className="text-sm font-semibold">{review.reviewerName || "Anonymous"}</span>
        </div>
        <span className="text-xs text-gray-500">
          {moment(review.timeOfReview).fromNow()}
        </span>
      </div>
      {/* ⭐ Left-aligned Stars */}
      <div className="mt-2 flex justify-start">
        <RatingStars rating={review.starCount} size="text-sm" />
      </div>
      <p className="mt-2 text-sm">{review.reviewText}</p>
    </div>
  ))}
</div>


      {/* Review Modal */}
      {showModal && (
  <div 
    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 px-4"
    onClick={() => setShowModal(false)}  // ✅ Close when clicking outside
  >
    <div 
      className="bg-white w-full max-w-md rounded-lg p-6 shadow-lg"
      onClick={(e) => e.stopPropagation()}  // ✅ Prevent closing when clicking inside
    >
      <h2 className="text-lg font-semibold font-inter mb-4 text-center">Rate and Review</h2>
      <RatingStars rating={selectedRating} onSelect={setSelectedRating} />
      <textarea
        className="w-full p-2 bg-customGray border border-gray-300 rounded-md mt-3 text-sm"
        rows="4"
        placeholder="Please tell us your experience below"
        value={reviewText}
        onChange={(e) => setReviewText(e.target.value)}
      />
      <button
        className="bg-black text-white rounded-full py-2 w-full mt-4 text-sm"
        onClick={handleSubmitReview}
        disabled={submitting}
      >
        {submitting ? <RingSpinner size={20} color="white" /> : "Submit"}
      </button>
    </div>
  </div>
)}
<Hamburger/>

    </div>
  );
};

export default ReviewPage;
